import { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';

import { useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, Typography } from '@mui/material';

// hooks
import useAuth from '../hooks/useAuth';
import useIsMountedRef from '../hooks/useIsMountedRef';
// components
import { MIconButton } from './@material-extend';
import MyAvatar from './MyAvatar';
import MenuPopover from './MenuPopover';

// ----------------------------------------------------------------------

export default function AccountSettings() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        {' '}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="25" width="25">
          <g>
            <path
              d="M22.42,9.81a8.94,8.94,0,0,0-2.57-4.19,9.93,9.93,0,0,0-4.32-2.28.3.3,0,0,0-.36.21.29.29,0,0,0,.2.36,9.34,9.34,0,0,1,4,2.21A8.2,8.2,0,0,1,21.63,10a12,12,0,0,1,.16,4.36,9.25,9.25,0,0,1-1.55,4,9.81,9.81,0,0,1-7.68,4.5,10.08,10.08,0,0,1-8.18-3.51,10.07,10.07,0,0,1-2-8.61,9.12,9.12,0,0,1,1.93-4A8.75,8.75,0,0,1,7.88,4.26a.34.34,0,0,0,.2-.43.34.34,0,0,0-.43-.2,9.44,9.44,0,0,0-4,2.66A9.92,9.92,0,0,0,1.5,10.55a10.88,10.88,0,0,0-.06,4.95A11.08,11.08,0,0,0,3.57,20a11.09,11.09,0,0,0,9,4,10.84,10.84,0,0,0,8.48-5,10.15,10.15,0,0,0,1.62-4.46A12.54,12.54,0,0,0,22.42,9.81Z"
              fill="#ff4a8c"
              fill-rule="evenodd"
            ></path>
            <path
              d="M11.82,10.58a.41.41,0,0,0,.42-.38c.14-1,.34-2.18.45-3.4a14.46,14.46,0,0,0,.07-1.47c0-.5,0-1,0-1.47A26.85,26.85,0,0,0,12.34.4a.46.46,0,0,0-.5-.4.45.45,0,0,0-.39.5c0,.86-.08,2.08-.14,3.45,0,.75-.08,1.54-.07,2.32,0,1.39.16,2.75.21,3.9A.39.39,0,0,0,11.82,10.58Z"
              fill="#191919"
              fill-rule="evenodd"
            ></path>
          </g>
        </svg>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 150 }}>
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
