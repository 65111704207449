import { createContext } from 'react';
import PropTypes from 'prop-types';
import socketio from 'socket.io-client';
import Cookies from 'js-cookie';
import { REACT_APP_SERVER_URL } from '../constants/constant';

// ----------------------------------------------------------------------

const socketConnection = socketio.connect(REACT_APP_SERVER_URL, {
  auth: {
    cookie: Cookies.get('connect.sid')
  }
});

const socketChat = {
  // payload -> {roomId:val}
  joinRoom_emit: (payload) => socketConnection.emit('joinRoom', payload),
  // payload -> {roomId:val}
  leaveRoom_emit: (payload) => socketConnection.emit('leaveRoom', payload),
  // payload -> {sender:email, value:message, timeStamp:date.getTime(), type: text|image, roomId:val, lastMessage: }
  message_emit: (payload) => socketConnection.emit('message', payload),
  // payload -> {roomId:roomId, state: 'RESOLVED'}
  close_doubt_emit: (payload) => socketConnection.emit('closeDoubt', payload),
  // payload -> {roomId:roomId, state: 'ACTIVE'}
  reopen_doubt_emit: (payload) => socketConnection.emit('reopenDoubt', payload),
  // payload -> {botMsg: botMsg}
  escalate_emit: (payload) => socketConnection.emit('escalate', payload),
  // payload -> {roomId:val}
  online_emit: (payload) => socketConnection.emit('online', payload),
  // payload -> {email}
  notify_emit: (payload) => socketConnection.emit('notify', payload),

  joinRoom_on: () => {
    let funParams;
    socketConnection.on('joinRoom', (params) => {
      funParams = params;
    });
    return funParams;
  },
  message_on: (handleMessageToChat) => {
    socketConnection.on('message', (params) => {
      console.log('in socket message_on', params);
      handleMessageToChat(params);
    });
  },
  close_doubt_on: (handleCloseDoubtEvent) => {
    socketConnection.on('closeDoubt', (params) => {
      console.log('in socket close_doubt_on', params);
      handleCloseDoubtEvent(params);
    });
  },
  reopen_doubt_on: (handleReopenDoubtEvent) => {
    socketConnection.on('reopenDoubt', (params) => {
      console.log('in socket reopen_doubt_on', params);
      handleReopenDoubtEvent(params);
    });
  },
  escalate_on: (handleMessageToChat) => {
    socketConnection.on('escalate', (params) => {
      console.log('in socket escalate_on', params);
      handleMessageToChat(params);
    });
  },
  refresh_doubts_on: (handleMessageToDoubts) => {
    socketConnection.on('refresh_doubts', (params) => {
      console.log('in socket refresh doubts', params);
      handleMessageToDoubts(params);
    });
  },
  online_on: (handleOnlineEvent) => {
    socketConnection.on('online', (params) => {
      handleOnlineEvent(params);
    });
  },
  notify_on: (handleNotification) => {
    socketConnection.on('notify', (params) => {
      handleNotification(params);
    });
  },
  got_disconnected: (hanldeGotDisconnected) => {
    socketConnection.on('got_disconnected', () => {
      hanldeGotDisconnected();
    });
  }
};

const SocketContext = createContext({
  socketChat
});

SocketProvider.propTypes = {
  children: PropTypes.node
};

function SocketProvider({ children }) {
  return (
    <SocketContext.Provider
      value={{
        socketChat
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export { SocketContext, SocketProvider };
