import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function FileCheck(props) {
  const theme = useTheme();
  const { size = 24, color = 'white' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <g>
          <path
            d="M9.91,17.84a6.13,6.13,0,0,0,2.41.26,5.12,5.12,0,0,0,3.76-8.48,5.34,5.34,0,0,0-8.56.53c-1.51,2.23-.73,5.52.78,6.49a.27.27,0,0,0,.3-.45c-1.21-.84-1.75-3.74-.39-5.55a4.53,4.53,0,0,1,7.08-.37,4.29,4.29,0,0,1-3,7.14,5.26,5.26,0,0,1-2.16-.16A.31.31,0,0,0,9.91,17.84Z"
            fill="#0c0d0c"
          ></path>
          <path
            d="M13.15,10.57c-.43.52-2,2.91-2.4,3.54-.1.16,0,.38-.84-.51-.23-.23-.59.11-.43.35.36.54.64,1.1,1.25,1.13.87.05,1.86-2,2.25-2.77a6.13,6.13,0,0,0,.75-1.58A.32.32,0,0,0,13.15,10.57Z"
            fill="#0c0d0c"
          ></path>
          <g>
            <path
              d="M20.93,5.46C20.9,4,20.06,3.8,19,2.93S17.29,1.21,16.36.49A.31.31,0,0,0,16,1c.89.71,1.74,1.69,2.63,2.46s1.6,1,1.61,2c0,.66-.14,1.44-.15,1.88a58.31,58.31,0,0,1-.44,8.89,19.1,19.1,0,0,1-.71,3.16,2.42,2.42,0,0,1-.63.71,4,4,0,0,1-1.83.29c-.68,0-6.89-.07-9.06-.23-1.72-.12-3.41-.31-5.29-.5C4,14.76,3.68,4.71,4.18,1.73c.44-.37,7.57-1.14,8-1.16a4.58,4.58,0,0,1,1.6.17A.27.27,0,0,0,14,.23,5.2,5.2,0,0,0,12.16,0c-.73,0-7,.66-7.88.91-.52.14-.7.36-.79.61C2.92,3.05,3,16.11.91,19.86a.51.51,0,0,0,.39.77,92.65,92.65,0,0,0,10.09.71c2.21,0,6.48.35,7.5-.51a3.09,3.09,0,0,0,1.21-2.18c1-4.47.68-7.66.7-11.29C20.81,6.92,21,6.13,20.93,5.46Z"
              fill="#0c0d0c"
            ></path>
            <path
              d="M15,6.39a8.74,8.74,0,0,0,4.11-.51c1.08-.57.3-1.21,0-.79a.71.71,0,0,1-.28.18c-.45.17-3.43.38-3.61.07s.07-1.89.14-2.54c.11-1.18.13-1.19-.05-1.26-.36-.15-.5.64-.62,1.14C14.54,3.45,13.42,5.83,15,6.39Z"
              fill="#0c0d0c"
            ></path>
          </g>
        </g>
        <path
          d="M23.16,8.58c0-.74-.68-.72-.71-.07S22.32,9.6,22.31,10a59.59,59.59,0,0,1-.43,8.89A19.33,19.33,0,0,1,21.17,22a2.59,2.59,0,0,1-.63.7,3.86,3.86,0,0,1-1.84.3c-.68,0-6.89-.07-9.06-.23-1.47-.11-2.93-.27-4.5-.42-1.14-.18-1.63.71-.71,1a81.81,81.81,0,0,0,9.2.63c2.22,0,6.48.35,7.51-.51a3.13,3.13,0,0,0,1.2-2.18c1-4.48.69-7.67.71-11.29C23.05,9.63,23.13,9.1,23.16,8.58Z"
          fill="#27ae60"
        ></path>
      </g>
    </svg>
  );
}
