import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import GeneralForm from '../generalForm/GeneralForm';
import { createSection } from '../../services/admin/admin';

const sectionConfiguration = [
  {
    label: 'Section Name',
    value: 'sectionName',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      fullWidth: true,
      size: 'small'
    }
  },
  {
    label: 'Section Order',
    value: 'sectionId',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      fullWidth: true,
      size: 'small',
      type: 'number'
    }
  }
];

const AddSectionComponent = ({
  treeBlueprint,
  courseId,
  sectionState,
  setSectionState,
  eventData,
  setFetchBlueprintValue
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [disableSubmit, setdDisableSubmit] = useState(false);

  const onSubmit = () => {
    const sectionPayloadData = {
      ...sectionState,
      treeDataPayload: {
        data: treeBlueprint.data.treeData,
        event: eventData
      }
    };
    createSection(sectionPayloadData).then((res) => {
      enqueueSnackbar(res.data.message, {
        variant: res.data.message !== 'Section added successfully!' ? 'error' : 'success'
      });
      if (res.data.message === 'Section added successfully!') {
        setFetchBlueprintValue((prevState) => prevState + 1);
      }
      onReset();
    });
  };

  const onReset = () => {
    setSectionState({
      courseId: courseId,
      sectionName: '',
      sectionId: ''
    });
  };

  return (
    <GeneralForm
      formDetails={sectionState}
      setFormDetails={setSectionState}
      fieldConfigs={sectionConfiguration}
      formTitle={'Add Section Details'}
      onSubmitForm={onSubmit}
      onResetForm={onReset}
      disableSubmitButton={disableSubmit}
      gridSize={{
        sx: 12,
        md: 12,
        lg: 6
      }}
    />
  );
};

export default AddSectionComponent;
