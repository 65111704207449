import React from 'react';
import { useTheme } from '@mui/material/styles';
export default function LockCircle(props) {
  const theme = useTheme();
  const { size = 40, color = 'white' } = props;
  return (
    <svg height={size} width={size} style={{ fill: color }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <path
          d="M19.81,15.15a.34.34,0,0,0-.38-.3.34.34,0,0,0-.3.37c.5,5.32-2.31,7.55-5.57,7.77A9.6,9.6,0,0,1,7.4,21.15a4.83,4.83,0,0,1-2.12-3.41,8.37,8.37,0,0,1,1.35-5.81,6.36,6.36,0,0,1,5.26-2.49c4.85,0,6.35,2,6.73,3.75a.3.3,0,0,0,.36.23.3.3,0,0,0,.23-.35,4.65,4.65,0,0,0-1.73-2.86c0-1.27,0-2.58-.11-3.85-.07-1-.18-1.88-.33-2.75a4.1,4.1,0,0,0-2-2.7A6.09,6.09,0,0,0,11.92,0a4.39,4.39,0,0,0-4.7,4c-.07.63-.13,1.58-.17,2.66S7,9,6.93,10.1a7.19,7.19,0,0,0-1.17,1.17,9.43,9.43,0,0,0-1.63,6.56,5.92,5.92,0,0,0,2.59,4.22A10.49,10.49,0,0,0,13.64,24C17.3,23.63,20.52,21.12,19.81,15.15ZM7.86,6.69c.11-1.05.23-2,.33-2.58a3.41,3.41,0,0,1,3.71-3,5.32,5.32,0,0,1,2.58.72,3.14,3.14,0,0,1,1.59,1.94,29.27,29.27,0,0,1,.51,3.3c.09.87.14,1.74.18,2.61A10.88,10.88,0,0,0,11.9,8.56a7.09,7.09,0,0,0-4.12,1C7.86,8.54,7.77,7.62,7.86,6.69Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M12.83,17.57a2.39,2.39,0,0,1-1,.33.9.9,0,0,1-.8-.3.3.3,0,0,0-.48.37,1.52,1.52,0,0,0,1,.64,3,3,0,0,0,1.62-.22,2.28,2.28,0,0,0,1.44-1.59,2.88,2.88,0,0,0-.41-2.07,2,2,0,0,0-1.29-.89,3.17,3.17,0,0,0-2,.27,2,2,0,0,0-1,3,.33.33,0,0,0,.45.17.35.35,0,0,0,.17-.45,1.24,1.24,0,0,1,.37-1.59,2.16,2.16,0,0,1,1.59-.32.94.94,0,0,1,.72.42,2,2,0,0,1,.33,1.27A1.36,1.36,0,0,1,12.83,17.57Z"
          fill="#0a0104"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
