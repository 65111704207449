import { useState } from 'react';
import { useSnackbar } from 'notistack';
//material
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { MIconButton } from '../components/@material-extend';
import { Icon } from '@iconify/react';
//icon
import CloseIcon from '@mui/icons-material/Close';
import closeFill from '@iconify/icons-eva/close-fill';
//services
import { raiseDoubt } from '../services/student/student';
//hooks
import useRefreshToken from '../hooks/useRefreshToken';

//-----------------------------------------------------------------------

export default function DoubtModal({ coursePref, open, handleClose }) {
  const doubtPayload = { ...coursePref };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const { setDoubtsRefreshToken } = useRefreshToken();

  console.log('desc - title', description, title);

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleChangeDesc = (event) => {
    setDescription(event.target.value);
  };

  const handleRaiseDoubt = () => {
    doubtPayload.title = title;
    doubtPayload.description = description;
    raiseDoubt(doubtPayload).then((result) => {
      enqueueSnackbar(result.data.message, {
        variant: result.data.message.includes('success') ? 'success' : 'warning',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      console.log('in doubt modal', result);
      handleClose();
      if (result.data.message.includes('success')) setDoubtsRefreshToken((ref) => ref + 1);
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Doubt details
          {open ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can raise only one doubt at a time until the previous doubt is resolved or closed by you !!
          </DialogContentText>
          <Typography lineHeight={1} variant="caption">
            “If I had an hour to solve a problem and my life depended on the solution, I would spend the first 55
            minutes determining the proper question to ask… for once I know the proper question, I could solve the
            problem in less than five minutes.” - Albert Einstein
          </Typography>
          <TextField
            style={{ marginBottom: '3%' }}
            margin="dense"
            id="name"
            label="Doubt title"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChangeTitle}
          />
          <TextField
            id="standard-multiline-flexible"
            label="Explain yout doubt"
            multiline
            maxRows={4}
            style={{ width: '100%' }}
            // value={value}
            onChange={handleChangeDesc}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRaiseDoubt} variant="contained">
            Raise the doubt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
