import { sample } from 'lodash';

export const REACT_APP_SERVER_URL =
  process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_IS_DEV_ENV
    ? process.env.REACT_APP_SERVER_URL
    : 'http://localhost:5000';

export const LANDING_BASE_URL =
  process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_IS_DEV_ENV
    ? process.env.REACT_APP_LANDING_BASE_URL
    : 'http://localhost:7777';

export const fileUploadUrl = (data) => {
  var email = data.emailId.replace('@', '%40');
  var fileName = data.message.replace(' ', '+');

  return `https://${
    !process.env.REACT_APP_IS_DEV_ENV && process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_NODE_ENV
      : 'dev'
  }-shoshin-mentor.s3.ap-south-1.amazonaws.com/${email}/${data.roomId}_chatRoomId/${fileName}`;
};

export const COURSES_TABLE_HEAD = [
  { id: 'course_id', label: 'Course Id', alignRight: false },
  { id: 'course_name', label: 'Course Name', alignRight: false },
  { id: 'level', label: 'Level', alignRight: false },
  { id: 'price', label: 'Course Price', alignRight: false },
  { id: 'ratings', label: 'Course Rating', alignRight: false },
  { id: 'disable', label: 'Status', alignRight: false },
  { id: 'duration', label: 'Course Duration', alignRight: false },
  { id: 'created_on', label: 'Created On', alignRight: false },
  { id: 'program_name', label: 'Program Name', alignRight: false }
];

export const buildCoursesTableData = (courses) => {
  const tableData = courses.map((course) => {
    return {
      course_id: { value: course.courseId },
      course_name: { value: course.heading },
      level: { value: course.level },
      price: { value: 'Rs. ' + course.price },
      ratings: { value: course.rating },
      disable: {
        value: course.disable ? 'disabled' : 'enable',
        label: true,
        flag: !course.disable,
        successLabel: ['enable']
      },
      duration: { value: course.duration },
      created_on: { value: new Date(course.createdDate).toUTCString() },
      program_name: { value: course.program },
      moreButton: true
    };
  });
  return tableData;
};

export const lessonType = [
  {
    label: 'Code',
    value: 'code'
  },
  {
    label: 'Video',
    value: 'video'
  },
  {
    label: 'Content',
    value: 'content'
  },
  {
    label: 'MCQ',
    value: 'mcq'
  },
  {
    label: 'Drag and Drop',
    value: 'dragndrop'
  }
];

export const codeLanguageType = [
  {
    label: 'C++',
    value: 'CPP'
  },
  {
    label: 'Python',
    value: 'python'
  },
  {
    label: 'Java',
    value: 'java'
  }
];

export const codeQuestionParagraphType = [
  {
    label: 'Text',
    value: 'TEXT'
  },
  {
    label: 'Code',
    value: 'CODE'
  }
];

export const formCountForPage = {
  2: 2,
  3: 2,
  4: 2,
  5: 3
};

export const languageCodeId = {
  CPP: 52
};

export const LANGUAGE_ID = {
  52: 'C++'
};
