import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function EditContent(props) {
  const theme = useTheme();
  const { size = 24, color = theme.palette.primary.main } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M22.15,10a3.09,3.09,0,0,0-2.36-2.79c.2-.33.45-.65.62-1a4.18,4.18,0,0,0,.14-3.15C19.44.08,15-1.24,12.73,1.48c-.87,1-.29,1-.93.5C9.19.1,5.51,1.89,5.8,5.68c-3.06-.25-5.32,3-3,6.05,1,1.37,3.87,2.08,4.43,1.78A.29.29,0,0,0,7,13a5.41,5.41,0,0,1-3.61-1.66c-1.79-2.54,0-4.78,1.93-4.74A1.8,1.8,0,0,1,6,6.66c.28.1.54.45.92.18a.48.48,0,0,0,0-.75c-.2-.2-.15-.72-.14-1a2.74,2.74,0,0,1,4.38-2.19,2.09,2.09,0,0,1,.57,1,.56.56,0,0,0,1,0L13.09,3a3.77,3.77,0,0,1,1.15-1.38,3.75,3.75,0,0,1,4.17.36,3.26,3.26,0,0,1,1.31,3.44A7.87,7.87,0,0,1,18.86,7c-.09.1-.47.33-.42.44a.49.49,0,0,0-.19.39c0,.12.36.65.78.33,1.89-.43,3.26,2.42,1.53,3.6a8.45,8.45,0,0,1-4,1.52.33.33,0,0,0,.06.66A9.13,9.13,0,0,0,21,12.41,2.54,2.54,0,0,0,22.15,10Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M16.17,15.36a15,15,0,0,0-2.33-3.71c-.42-.48-.75-1.09-1.59-1-2.11.15-6.69,5.45-5.44,7,.59.72,3-.87,3.58-1.49.1,1,.68,7,.93,7.71a.25.25,0,0,0,.48-.15c-.27-.88-.63-7.58-.72-8.19s-.73-.55-1.12-.05a6.53,6.53,0,0,1-2.48,1.46,2.22,2.22,0,0,1,.19-.62c.62-1.45,3.24-4.58,4.65-4.69.69-.05,3,3.75,3.48,5.09l-.22-.1c-1-.49-2.11-1.77-2.56-1.77a.46.46,0,0,0-.37.49c.12,1.19.26-.83-.71,8.43a.29.29,0,0,0,.57,0c0,.07,1.13-5.86,1-7.71.67.56,1.55,1.47,2.59,1.5.44,0,.75-.32.55-1.06A10.21,10.21,0,0,0,16.17,15.36Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
