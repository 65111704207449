import axios from '../../utils/axios';
import { REACT_APP_SERVER_URL } from '../../constants/constant';

export const fetchNotifications = (email) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/fetchNotifications`, { userEmail: email }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });

export const readAllNotifications = (email) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/readAllNotifications`, { userEmail: email }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });
