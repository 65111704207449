import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, Link, Routes, useNavigate } from 'react-router-dom';
import GeneralForm from '../../components/generalForm/GeneralForm';
import styles from './AddCoursePage.module.css';
import { createCourse } from '../../services/admin/admin';
import { useSnackbar } from 'notistack';

const AddCourseConfiguration = [
  {
    label: 'Course Id',
    value: 'courseId',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      size: 'small'
    }
  },
  {
    label: 'Course Name',
    value: 'heading',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      size: 'small'
    }
  },
  {
    label: 'Course Level',
    value: 'level',
    type: 'multiselect',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      size: 'small'
    },
    options: [
      {
        value: 'BEGINNER',
        label: 'Benginner'
      },
      {
        value: 'INTERMEDIATE',
        label: 'Itermediate'
      },
      {
        value: 'ADVANCE',
        label: 'Advance'
      }
    ]
  },
  {
    label: 'Course Price',
    value: 'price',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      type: 'number',
      size: 'small'
    }
  },
  {
    label: 'Course Program Name',
    value: 'program',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      size: 'small'
    }
  },
  {
    label: 'Prerequisite',
    value: 'prerequisite',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      multiline: true,
      size: 'small'
    }
  },
  {
    label: 'Course Duration',
    value: 'duration',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      size: 'small'
    }
  },
  {
    label: 'Detailed Description',
    value: 'detailedDescription',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      multiline: true,
      size: 'small'
    }
  },
  {
    label: 'Video URL',
    value: 'videoUrl',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      size: 'small'
    }
  },
  {
    label: 'Banner Image',
    value: 'bannerImage',
    type: 'fileUpload'
  },
  {
    label: 'Practice questions',
    value: 'practiceQuestions',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      type: 'number',
      size: 'small'
    }
  },
  {
    label: 'Languages in this course, (comma seperated)',
    value: 'languages',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      size: 'small'
    }
  },
  {
    label: 'Add Skills, (comma seperated)',
    value: 'skills',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      size: 'small'
    }
  },
  {
    label: 'Faqs',
    value: 'faqs',
    type: 'multipleInput',
    multipleInputType: 'faqs',
    multipleInputHeading: 'Add FAQs'
  },
  {
    label: 'What Students will learn',
    value: 'whatToLearn',
    type: 'arrayText',
    multipleInputType: 'whatToLearn',
    multipleInputHeading: 'Add Points for What Students will learn'
  }
];

const AddCoursesPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [courseState, setCourseState] = useState({
    courseId: '',
    heading: '',
    level: '',
    price: '',
    program: '',
    prerequisite: '',
    duration: '',
    detailedDescription: '',
    videoUrl: '',
    bannerImage: '', //FIXME: file upload takes array
    practiceQuestions: '',
    languages: '',
    faqs: [],
    whatToLearn: [],
    skills: ''
  });
  const [disableSubmit, setdDisableSubmit] = useState(false);
  const goBack = () => {
    navigate(-1);
  };

  const onSubmitCourseForm = () => {
    // TODO: Validate course form data before submtting
    const payload = {
      ...courseState
    };
    if (payload.languages) {
      const array = payload.languages.split(',').map((element) => element.trim());
      payload.languages = array;
    }
    if (payload.skills) {
      const array = payload.skills.split(',').map((element) => element.trim());
      payload.skills = array;
    }
    createCourse(payload).then((data) => {
      if (data.status) {
        if (data.data.message === 'Course already exist') {
          enqueueSnackbar('Course already exist', { variant: 'warning' });
        } else {
          enqueueSnackbar('Course Created Successfully', { variant: 'success' });
          window.location.href = `/admin/add-treenode/${courseState.courseId}`;
        }
      } else {
        enqueueSnackbar(`${data.errors[0].code}: ${data.errors[0].errorMessage}`, { variant: 'error' });
      }
    });
  };

  const onResetCourseForm = () => {
    setCourseState({
      courseId: '',
      heading: '',
      level: '',
      price: '',
      program: '',
      prerequisite: '',
      duration: '',
      detailedDescription: '',
      videoUrl: '',
      bannerImage: '', //FIXME: file upload takes array
      practiceQuestions: '',
      languages: '',
      faqs: [],
      whatToLearn: [],
      skills: ''
    });
  };

  const checkDisableSubmitButton = (courseState) => {
    if (
      courseState.courseId === '' ||
      courseState.heading === '' ||
      courseState.level === '' ||
      courseState.price === '' ||
      courseState.program === '' ||
      courseState.prerequisite === '' ||
      courseState.duration === '' ||
      courseState.detailedDescription === '' ||
      courseState.syllabusUrl === '' ||
      courseState.videoUrl === '' ||
      courseState.practiceQuestions === '' ||
      //   courseState.bannerImage === '' ||    //TODO: After api change - configure the banner image
      courseState.faqs.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const shouldDisable = checkDisableSubmitButton(courseState);
    if (shouldDisable) {
      setdDisableSubmit(true);
    } else {
      setdDisableSubmit(false);
    }
  }, [courseState]);

  return (
    <div className={styles.container}>
      <div className={styles.headingBar}>
        <Typography variant="h5">Add Course Page Form</Typography>
        <Button onClick={() => goBack()}>Back</Button>
      </div>
      <div className={styles.content}>
        <GeneralForm
          formDetails={courseState}
          setFormDetails={setCourseState}
          fieldConfigs={AddCourseConfiguration}
          formTitle={'Add Courses Details'}
          onSubmitForm={onSubmitCourseForm}
          onResetForm={onResetCourseForm}
          disableSubmitButton={disableSubmit}
        />
      </div>
    </div>
  );
};

export default AddCoursesPage;
