import axios from '../../utils/axios';
import { REACT_APP_SERVER_URL } from '../../constants/constant';

export const getPlaygroundSidebar = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/student/getPlaygroundSidebar`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) return result.data;
      return Promise.reject(result);
    });

export const getModulesDashboard = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/student/getModulesDashboard`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) return result.data;
      return Promise.reject(result);
    });

export const uploadStudentCode = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/student/uploadCode`, reqData, { withCredentials: true }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });

export const getLessonDetails = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/student/getLessonDetails`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) return result.data;
      return Promise.reject(result);
    });

export const updateVideoNotes = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/student/updateVideoNotes`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) return result.data;
      return Promise.reject(result);
    });

export const getUserPreferences = (email) =>
  axios
    .get(`${REACT_APP_SERVER_URL}/api/student/getCourseDashboard/${email}`, { withCredentials: true })
    .then((result) => {
      if (result.data) return result.data;
      return Promise.reject(result);
    });

export const updateLessonScore = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/student/updateLessonScore`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) return result.data;
      return Promise.reject(result);
    });

export const viewSolution = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/student/viewSolution`, reqData, { withCredentials: true }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });

export const raiseDoubt = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/student/raiseDoubt`, reqData, { withCredentials: true }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });

export const updateDoubt = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/student/updateDoubt`, reqData, { withCredentials: true }).then((result) => {
    if (result.data) {
      return result.data;
    }
    return Promise.reject(result);
  });

export const updateDoubtChats = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/student/updateDoubtChats`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) {
        return result.data;
      }
      return Promise.reject(result);
    });

export const uploadDoubtFile = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/student/uploadDoubtFile`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) {
        return result.data;
      }
      return Promise.reject(result);
    });

export const updateOnboarding = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/student/updateOnboarding`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) {
        return result.data;
      }
      return Promise.reject(result);
    });
export const resetCode = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/student/resetCode`, reqData, { withCredentials: true }).then((result) => {
    if (result.data) {
      return result.data;
    }
    return Promise.reject(result);
  });

export const getProfileDetails = (email) =>
  axios.get(`${REACT_APP_SERVER_URL}/api/student/getProfile/${email}`, { withCredentials: true }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });

export const updateProfileDetails = (data) =>
  axios.put(`${REACT_APP_SERVER_URL}/api/student/updateProfile`, data, { withCredentials: true }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });

export const getFeaturedCourses = () =>
  axios.get(`${REACT_APP_SERVER_URL}/api/public/getCourses`, { withCredentials: true }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });
