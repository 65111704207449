//React
import { useCallback, useEffect, useState } from 'react';
//libraries
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { motion, AnimatePresence } from 'framer-motion';
import Promise from 'bluebird';
//icons
import { Icon } from '@iconify/react';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  FormControlLabel,
  Switch
} from '@mui/material';
import { MIconButton } from '../@material-extend';
// utils
import { fData } from '../../utils/formatNumber';
//components
import { UploadIllustration } from '../../assets';
//constants
import { otherFileBlob, textFileBlob } from './constant';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

UploadFiles.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  setAllAcceptedFiles: PropTypes.any,
  allAcceptedFiles: PropTypes.any,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object
};

export default function UploadFiles({
  error,
  showPreview = false,
  bucketName,
  region,
  courseId,
  moduleId,
  lessonId,
  handleUploadFiles,
  markDownFolder,
  setShowPreview,
  files,
  onRemove,
  onRemoveAll,
  setAllAcceptedFiles,
  allAcceptedFiles,
  setAllFiles,
  allFiles,
  sx,
  multiple,
  ...other
}) {
  const hasFile = files.length > 0;

  console.log('FILES->>>>', files);
  const parse = function (file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = function (event) {
        // function b64EncodeUnicode(str) {
        //   // first we use encodeURIComponent to get percent-encoded UTF-8,
        //   // then we convert the percent encodings into raw bytes which
        //   // can be fed into btoa.
        //   return btoa(
        //     encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        //       return String.fromCharCode('0x' + p1);
        //     })
        //   );
        // }

        // function b64DecodeUnicode(str) {
        //   // Going backwards: from bytestream, to percent-encoding, to original string.
        //   return decodeURIComponent(
        //     atob(str)
        //       .split('')
        //       .map(function (c) {
        //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        //       })
        //       .join('')
        //   );
        // }

        let encodedData = event.target.result;

        // if (markDownFolder && file.name.includes('markdown')) {
        //   const initials = event.target.result.substring(0, event.target.result.indexOf(',') + 1);
        //   let decoded = b64DecodeUnicode(event.target.result.substring(event.target.result.indexOf(',') + 1)).replace(
        //     encodeURIComponent(markDownFolder),
        //     `https://${bucketName}.s3.${region}.amazonaws.com/${courseId}/${moduleId}/${lessonId}`
        //   );

        //   decoded = decoded.replace('****', '**');

        //   encodedData = initials + b64EncodeUnicode(decoded);
        // }

        const payload = {
          data: encodedData,
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.name.match(/\.[0-9a-z]+$/i)[0]
        };
        resolve(payload);
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (allAcceptedFiles.length > 0) {
      Promise.map(allAcceptedFiles, (file) => parse(file)).then((res) => {
        console.log('allFiles', res);
        setAllFiles(res);
        setAllAcceptedFiles([]);
      });

      // Do something with the files
    }
  }, [allAcceptedFiles]);

  //   const onDrop = useCallback((acceptedFiles) => {
  //     console.log('acceptedFiles', acceptedFiles);
  //     setAllAcceptedFiles(acceptedFiles);
  //   }, []);

  console.log('acceptedFiles state', allFiles);

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
    multiple: multiple,
    accept: ['.pdf', 'image/*', '.html', '.txt', '.md']
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ width: '100%', ...sx, p: 2 }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            Drop or Select file
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Drop file here or click&nbsp;
            <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
              browse
            </Typography>
            &nbsp;through your machine
          </Typography>
        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}
      {/* below is preview button */}
      {files && files[0] && (
        <FormControlLabel
          control={<Switch onClick={() => setShowPreview(!showPreview)} checked={showPreview} />}
          label="Show Preview"
          sx={{ mb: 2 }}
        />
      )}

      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {files.map((file) => {
            const { name, size, preview, type } = file;
            const key = isString(file) ? file : name;

            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={motion.div}
                  //   {...varFadeInRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 80,
                    height: 80,
                    borderRadius: 1.5,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'inline-flex'
                  }}
                >
                  <Paper
                    variant="outlined"
                    component="img"
                    src={
                      type.includes('text')
                        ? textFileBlob
                        : !(type.includes('png') || type.includes('jpg') || type.includes('jpeg'))
                        ? otherFileBlob
                        : isString(file)
                        ? file
                        : preview
                    }
                    sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                  />
                  <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
                    <MIconButton
                      size="small"
                      onClick={() => onRemove(file)}
                      sx={{
                        p: '2px',
                        color: 'common.white',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        '&:hover': {
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                        }
                      }}
                    >
                      <Icon icon={closeFill} />
                    </MIconButton>
                  </Box>
                </ListItem>
              );
            }

            return (
              <ListItem
                key={key}
                component={motion.div}
                // {...varFadeInRight}
                sx={{
                  my: 1,
                  py: 0.75,
                  px: 2,
                  borderRadius: 1,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                  bgcolor: 'background.paper'
                }}
              >
                <ListItemIcon>
                  <Icon icon={fileFill} width={28} height={28} />
                </ListItemIcon>
                <ListItemText
                  primary={isString(file) ? file : name}
                  secondary={isString(file) ? '' : fData(size)}
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
                <ListItemSecondaryAction>
                  <MIconButton edge="end" size="small" onClick={() => onRemove(file)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </AnimatePresence>
      </List>

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={onRemoveAll} sx={{ mr: 1.5 }}>
            Remove
          </Button>
          <Button onClick={handleUploadFiles} variant="contained">
            Upload file
          </Button>
        </Stack>
      )}
    </Box>
  );
}
