import React from 'react';
import { useTheme } from '@mui/material/styles';
export default function LockCircle(props) {
  const theme = useTheme();
  const { size = 40 } = props;
  return (
    <svg height={size} width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <path
          d="M21.06,15.14a.34.34,0,0,0-.37-.3.35.35,0,0,0-.31.38c.5,5.32-2.31,7.55-5.57,7.77a9.63,9.63,0,0,1-6.17-1.84,4.88,4.88,0,0,1-2.12-3.41,8.43,8.43,0,0,1,1.35-5.82,6.4,6.4,0,0,1,5.26-2.49c4.86,0,6.36,2,6.74,3.75a.3.3,0,0,0,.36.23.29.29,0,0,0,.23-.35,4.6,4.6,0,0,0-1.73-2.85c0-1.28,0-2.59-.09-3.87-.07-1-.17-1.88-.32-2.75a4,4,0,0,0-1.45-2.32A6,6,0,0,0,13.17,0,4.29,4.29,0,0,0,8.59,4L8.44,6.09a.31.31,0,0,0,.28.33A.3.3,0,0,0,9,6.14l.28-2.06a3.46,3.46,0,0,1,3.83-2.95,5.18,5.18,0,0,1,3,1A2.87,2.87,0,0,1,17.28,3.8a27.09,27.09,0,0,1,.53,3.3c.09.87.15,1.74.2,2.61a10.94,10.94,0,0,0-4.86-1.16A7.31,7.31,0,0,0,7,11.26a9.38,9.38,0,0,0-1.64,6.56A5.9,5.9,0,0,0,8,22.05,10.48,10.48,0,0,0,14.88,24C18.55,23.63,21.77,21.12,21.06,15.14Z"
          fill="#19686b"
          fill-rule="evenodd"
        ></path>
        <path
          d="M14.08,17.57a2.38,2.38,0,0,1-1,.33.88.88,0,0,1-.79-.3.3.3,0,0,0-.42-.06.29.29,0,0,0-.06.42,1.5,1.5,0,0,0,1,.65,3.15,3.15,0,0,0,1.62-.23,2.3,2.3,0,0,0,1.44-1.59,2.9,2.9,0,0,0-.41-2.06,1.92,1.92,0,0,0-1.29-.89,3.1,3.1,0,0,0-2,.27,2,2,0,0,0-1,3,.34.34,0,1,0,.62-.28,1.28,1.28,0,0,1,.37-1.6,2.12,2.12,0,0,1,1.59-.31,1,1,0,0,1,.73.41,1.9,1.9,0,0,1,.32,1.28A1.35,1.35,0,0,1,14.08,17.57Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M6.06,10a2.37,2.37,0,0,0,.3-.23c.24-.2.43-.41.64-.61a.32.32,0,0,0,.13-.46.34.34,0,0,0-.46-.13,6.26,6.26,0,0,0-.87.28A2.26,2.26,0,0,0,5.45,9a2.1,2.1,0,0,0-.3.22c-.23.2-.39.43-.62.66a.32.32,0,0,0-.11.42.31.31,0,0,0,.42.1,7,7,0,0,0,.88-.24A1.83,1.83,0,0,0,6.06,10Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M5.44,4a2.09,2.09,0,0,0,.24.28A2.15,2.15,0,0,0,6,4.53,6.86,6.86,0,0,0,6.72,5,.34.34,0,0,0,7.2,5a.36.36,0,0,0,0-.49C7,4.25,6.9,4,6.72,3.77a3,3,0,0,0-.22-.28,2.91,2.91,0,0,0-.27-.24,6.55,6.55,0,0,0-.78-.42.3.3,0,0,0-.43,0,.3.3,0,0,0,0,.42C5.18,3.52,5.27,3.77,5.44,4Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M5.49,6.61a.34.34,0,0,0-.3-.38C5,6.12,4.75,6,4.52,5.93a1.63,1.63,0,0,0-.3-.07,1,1,0,0,0-.3,0c-1.06.07-1.06.28-1.08.37a.3.3,0,0,0,.25.35,4,4,0,0,0,.67.37,1.63,1.63,0,0,0,.3.07h.31c.25,0,.47-.07.73-.11A.34.34,0,0,0,5.49,6.61Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
