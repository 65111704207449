import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton, Button } from '@mui/material';
// components
import MenuPopover from './MenuPopover';
import CustomIcon from './CustomIcon';
//Analytics
import { analytics, analyticsPlatform } from '../analytics/index';

// ----------------------------------------------------------------------

/*  FORMAT OF THE PROP FOR OPTIONS

const optionFormat = [
  {
    value: 'name of the option',
    label: 'label of the option',
    icon: 'icon name in string'
  }
];

*/

// ----------------------------------------------------------------------

export default function DropdownMenu(props) {
  const { isIconLabel, options, selected, onOptionClick } = props;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selected);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (option) => {
    if (option && option.label !== selectedOption.label) {
      analytics.trackEvent({
        type: analyticsPlatform.mixpanel,
        eventName: 'Switching to admin/student',
        eventProperties: {}
      });
      setSelectedOption(option);
      onOptionClick();
    }
    handleClose();
  };

  return (
    <>
      {isIconLabel ? (
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
            })
          }}
        >
          <CustomIcon name={selectedOption.icon} />
        </IconButton>
      ) : (
        <Button variant="text">{selectedOption.value}</Button>
      )}
      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedOption.value}
              onClick={() => onChange(option)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box>
                  <CustomIcon name={option.icon} />
                </Box>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}

// TODO: Add prototypes
