import React, { useEffect, useState } from 'react';
import GeneralForm from './GeneralForm';
import { IconButton, Stack, Typography } from '@mui/material';
import AddSquare from '../../icons/AddSquare';

const ArrayElement = (props) => {
  const {
    configuration,
    showActionButtons,
    accessValue,
    parentAccessValue,
    heading,
    setFormDetails,
    index,
    globalOptions,
    setGlobalOptions
  } = props;
  const [state, setState] = useState({});

  useEffect(() => {
    setFormDetails((prev) => {
      let arr = [];
      if (prev[accessValue]) {
        arr = prev[accessValue];
        if (arr[index]) {
          arr[index] = state;
        } else {
          arr.push(state);
        }
      } else {
        arr.push(state);
      }
      return {
        ...prev,
        [accessValue]: arr
      };
    });
  }, [state]);

  return (
    <GeneralForm
      formDetails={state}
      setFormDetails={setState}
      fieldConfigs={configuration}
      formTitle={heading}
      onSubmitForm={() => {}}
      onResetForm={() => {}}
      showActionButtons={showActionButtons}
      gridSize={{
        sx: 12,
        md: 12,
        lg: 6
      }}
      removeBorder={true}
      globalOptions={globalOptions}
      setGlobalOptions={setGlobalOptions}
    />
  );
};

const ArrayForm = (props) => {
  const {
    configuration,
    showActionButtons,
    accessValue,
    parentAccessValue,
    heading,
    setFormDetails,
    globalOptions,
    setGlobalOptions
  } = props;
  const [array, setArray] = useState([configuration]);

  useState(() => {
    console.log('use configuration', configuration);
    setArray((prev) => {
      return prev.map((pre) => configuration);
    });
  }, [configuration]);

  const onClickAdd = () => {
    setArray((prev) => {
      return [...prev, configuration];
    });
  };

  return (
    <>
      <Stack direction={'row-reverse'} alignItems={'center'}>
        <IconButton onClick={onClickAdd}>
          <AddSquare />
        </IconButton>
        <Typography variant="caption">Add</Typography>
      </Stack>
      {array.map((arr, index) => {
        return (
          <ArrayElement
            key={index}
            index={index}
            configuration={arr}
            showActionButtons={showActionButtons}
            accessValue={accessValue}
            parentAccessValue={parentAccessValue}
            heading={heading}
            setFormDetails={setFormDetails}
            globalOptions={globalOptions}
            setGlobalOptions={setGlobalOptions}
          />
        );
      })}
    </>
  );
};

export default ArrayForm;
