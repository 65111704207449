import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';
import Column from './column';
import Modal from './modal';
import style from './style.module.css';
import { MIconButton } from '../../@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { updateLessonScore } from '../../../services/student/student';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { Typography, Stack, Button } from '@mui/material';

const Container = styled.div`
  display: column;
  justify-content: center;
  width: 90%;
  margin-bottom: 1.4rem;
  font-family: Montserrat;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
`;

export default function DragDropAssessment({ studentLessonDetails, preview = true, coursePref, type }) {
  let initialData = studentLessonDetails.dragnDrop;
  let userDragDropData = !preview
    ? {
        ...studentLessonDetails.userData.data,
        userSubmitted: studentLessonDetails.userData.userSubmitted
      }
    : {};
  const [dropState, setDropState] = useState('');
  const [dragged, setDragged] = useState(false);
  const [open, setOpen] = useState(false);
  const [correct, setCorrect] = useState(true);
  const [hints, setHints] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isScored, setIsScored] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (!preview && studentLessonDetails.lessonScore === studentLessonDetails.studentScore) {
      setIsScored(true);
    }
  }, []);

  useEffect(() => {
    const check =
      dropState &&
      dropState.columnOrder &&
      dropState.columnOrder.some(
        (columnId) =>
          columnId !== 'question' &&
          dropState.columns[columnId].taskIds &&
          dropState.columns[columnId].taskIds.length === 0
      );

    setIsEmpty(check);
  }, [dropState]);

  useEffect(() => {
    let copyInitialData = initialData;
    if (userDragDropData.userSubmitted) {
      const columns = initialData.columnOrder;
      const result = columns.forEach((column) => {
        copyInitialData.columns[column].taskIds = userDragDropData[column];
      });
      console.log('update', result);
    }
    setDropState(copyInitialData);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    let submitAns = {
      userSubmitted: true
    };
    initialData.columnOrder.forEach((column) => {
      submitAns[column] = dropState.columns[column].taskIds;
    });
    console.log('user ans ', submitAns);
    let hint = [];
    console.log('hint ', hint);

    dropState.columnOrder.forEach((columnId) => {
      if (columnId === 'question') {
        return;
      }
      const column = dropState.columns[columnId];
      const tasks = column.taskIds.map((taskId) => dropState.tasks[taskId].id);
      const answers = column.ansTaskIds.map((ansId) => dropState.tasks[ansId].id);
      const validAns = tasks.every((task) => answers.includes(task)) && answers.length === tasks.length;
      if (validAns) {
      } else {
        hint.push({ id: column.id, ansHint: column.ansHint });
        console.log('hint ', hint);
        setCorrect(false);
      }
    });
    console.log('hint ', hint);
    setHints(hint);

    updateLessonScore({
      emailId: coursePref.emailId,
      moduleId: coursePref.moduleId,
      lessonId: coursePref.lessonId,
      courseId: coursePref.courseId,
      lessonType: 'dragndrop',
      submission: {
        dragndrop: {
          userSubmission: submitAns,
          passed: hint.length ? false : true
        }
      }
    }).then((response) => {
      handleClickOpen();
      if (response.data === 'Score updated successfully') {
        setIsScored(true);
      }
      enqueueSnackbar(response.data, {
        variant: response.data === 'Score updated successfully' ? 'success' : 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    });
  };
  console.log('hintsss', hints);
  console.log('is dragged', dragged);
  const onDragStart = () => {
    setDragged(true);
  };
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    setDragged(false);
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const start = dropState.columns[source.droppableId];
    const finish = dropState.columns[destination.droppableId];
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...start,
        taskIds: newTaskIds
      };
      const newState = {
        ...dropState,
        columns: {
          ...dropState.columns,
          [newColumn.id]: newColumn
        }
      };
      setDropState(newState);
      return;
    }
    // Moving from one list to another
    const destLength = dropState.columns[destination.droppableId].taskLength;
    const curdestLength = dropState.columns[destination.droppableId].taskIds.length;
    if (curdestLength !== destLength) {
      const startTaskIds = Array.from(start.taskIds);
      startTaskIds.splice(source.index, 1);
      const newStart = {
        ...start,
        taskIds: startTaskIds
      };
      const finishTaskIds = Array.from(finish.taskIds);
      finishTaskIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds
      };
      const newState = {
        ...dropState,
        columns: {
          ...dropState.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish
        }
      };
      setDropState(newState);
    }
  };
  const questionColumn = dropState && dropState.columns['question'];
  const questionTasks = questionColumn && questionColumn.taskIds.map((taskId) => dropState.tasks[taskId]);
  return (
    <div style={{ padding: '24px', margin: '16px' }}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Container>
          <div className={style.questionBox}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ color: `${theme.palette.grey[600]}` }} variant="overline" display="block" gutterBottom>
                QUIZ QUESTION
              </Typography>
              {!preview && isScored && <CheckCircleOutlineIcon style={{ color: '#609B7E' }} size={30} />}
            </Stack>

            <Typography sx={{ pt: 1, pb: 1 }} variant="body1" gutterBottom>
              {questionColumn.title}
            </Typography>
          </div>
          <div className={style.optionsList}>
            <Column key="question" column={questionColumn} tasks={questionTasks} isQuestion={true} />
          </div>
          <div className={style.columnBox}>
            {/* <div className={style.columnBoxChild}>CONCEPT</div>
            <div className={style.columnBoxChild}>ITEM</div> */}
            <Typography variant="subtitle1" className={style.columnBoxChild}>
              Concept
            </Typography>
            <Typography variant="subtitle1" className={style.columnBoxChild}>
              Item
            </Typography>
          </div>

          <div className={style.dropBox}>
            <div
              style={{
                width: '50%',
                borderRight: '1px solid lightgray'
              }}
            >
              {dropState &&
                dropState.columnOrder.map((columnId) => {
                  if (columnId === 'question') {
                    return null;
                  }
                  const column = dropState.columns[columnId];
                  return (
                    <div className={style.dropBoxFirst}>
                      <Typography variant="subtitle2">{column.title}</Typography>
                    </div>
                  );
                })}
            </div>
            <div
              style={{
                outline: dragged ? '4px dashed #19686b' : 'none',
                width: '50%'
              }}
            >
              {dropState &&
                dropState.columnOrder.map((columnId) => {
                  if (columnId === 'question') {
                    return null;
                  }
                  const column = dropState.columns[columnId];
                  const tasks = column.taskIds && column.taskIds.map((taskId) => dropState.tasks[taskId]);
                  return (
                    <div className={style.dropBoxSecond}>
                      <Column key={column.id} column={column} tasks={tasks} />
                    </div>
                  );
                })}
            </div>
          </div>
        </Container>

        {!preview && type === 'student' && (
          <div className={style.buttonWrap}>
            <Button disabled={isEmpty} onClick={handleSubmit} variant="contained" size="small">
              Submit
            </Button>
          </div>
        )}

        <Modal open={open} setOpen={setOpen} isCorrect={correct} hints={hints} />
      </DragDropContext>
    </div>
  );
}
