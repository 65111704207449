import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function OpenEye(props) {
  const theme = useTheme();
  const { size = 24, color = 'white' } = props;
  return (
    <svg height={size} width={size} style={{ fill: color }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
      <g transform="matrix(10.416666666666666,0,0,10.416666666666666,0,0)">
        <g>
          <path d="M18.39,8.57a17.67,17.67,0,0,0-5.68-1.7,10.89,10.89,0,0,0-2.9.06A17.34,17.34,0,0,0,7,7.56,17.92,17.92,0,0,0,2.6,9.7,15.34,15.34,0,0,0,.29,11.55a1.61,1.61,0,0,0-.23.27.32.32,0,0,0,0,.34.3.3,0,0,0,.4.12A12.65,12.65,0,0,1,4.34,9.5,16.15,16.15,0,0,1,7.26,8.35a15.86,15.86,0,0,1,2.66-.53,10.28,10.28,0,0,1,2.66,0,17.17,17.17,0,0,1,5.31,1.73,14,14,0,0,1,4.37,3.27,1,1,0,0,1,0,.95,3.59,3.59,0,0,1-.79,1.1,19,19,0,0,1-3,2.49A14.81,14.81,0,0,1,15,19.09a8.11,8.11,0,0,1-2.31.36,27.94,27.94,0,0,1-3.68-.16,9.15,9.15,0,0,1-4.2-1.77,12.31,12.31,0,0,1-3.2-3.4A.34.34,0,0,0,1.18,14a.35.35,0,0,0-.11.48,13.08,13.08,0,0,0,3.32,3.66,9.92,9.92,0,0,0,4.57,2,27,27,0,0,0,3.79.25,9.06,9.06,0,0,0,2.61-.35,15.7,15.7,0,0,0,3.74-1.76,19.76,19.76,0,0,0,3.18-2.61,4.35,4.35,0,0,0,1.17-1.83,2,2,0,0,0-.23-1.69A14.69,14.69,0,0,0,18.39,8.57Z"></path>
          <path d="M15.46,11.41a.3.3,0,0,0,.54-.28A4,4,0,0,0,13.51,9a6.79,6.79,0,0,0-3.27-.09A3.84,3.84,0,0,0,7.59,11.3a4.68,4.68,0,0,0-.06,3.56,4.74,4.74,0,0,0,2.62,2.41,5.53,5.53,0,0,0,3.48.19,4.15,4.15,0,0,0,2.43-1.87,4.93,4.93,0,0,0,.73-2.94.36.36,0,0,0-.39-.29.34.34,0,0,0-.29.39,4.11,4.11,0,0,1-.73,2.4,3.27,3.27,0,0,1-2,1.39,4.56,4.56,0,0,1-2.82-.29,3.63,3.63,0,0,1-2-1.85,3.56,3.56,0,0,1,.05-2.68,2.86,2.86,0,0,1,1.82-1.86,5.83,5.83,0,0,1,2.84-.11A3.38,3.38,0,0,1,15.46,11.41Z"></path>
          <path d="M11.28,12.11a2,2,0,0,1,.39-.32.31.31,0,0,0,.22-.37.31.31,0,0,0-.38-.21,2.83,2.83,0,0,0-1.32.75,1.87,1.87,0,0,0-.42.89,1.84,1.84,0,0,0,.4,1.43,2.12,2.12,0,0,0,2.09.76.34.34,0,0,0,.3-.37.34.34,0,0,0-.37-.31,1.39,1.39,0,0,1-1.12-.72,1.08,1.08,0,0,1-.16-.66A1.2,1.2,0,0,1,11,12.5,1.44,1.44,0,0,1,11.28,12.11Z"></path>
          <path d="M23.88,9.14c-.59-.51-1-1.05-1.55-1.56a6.4,6.4,0,0,0-1.27-.91A28.26,28.26,0,0,0,15.29,4.1a15.4,15.4,0,0,0-6.4-.36c-3.07.51-3.95.69-7.22,3.33A14.39,14.39,0,0,0,.55,8a.52.52,0,0,0-.18.35.29.29,0,0,0,.3.29c.22,0,.06-.23,2.73-2C5.35,5.44,6,5.2,9.05,4.85A14.82,14.82,0,0,1,15,5.19a29.55,29.55,0,0,1,5.67,2.24,5.57,5.57,0,0,1,1.15.74c.58.49,1,1,1.62,1.5a.35.35,0,0,0,.48,0A.36.36,0,0,0,23.88,9.14Z"></path>
        </g>
      </g>
    </svg>
  );
}
