import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';

export default function Breadcrum(props) {
  const { BreadcrumArray } = props;

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href={BreadcrumArray[0].link}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {BreadcrumArray[0].title}
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href={BreadcrumArray[1].link}
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {BreadcrumArray[1].title}
        </Link>
        <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {BreadcrumArray[2].title}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
