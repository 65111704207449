import { useRef, useState } from 'react';
import MenuVertical from '../../icons/MenuVertical';
import Dustbinopen from '../../icons/DustbinOpen';
import EditContent from '../../icons/EditContent';
import CloudUpload from '../../icons/CloudUpload';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

export default function CustomTableMoreMenu(props) {
  const { userData, setEditRowId, setDeleteRowId, setBucketRowId, handleEdit } = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleEditClick = (e) => {
    e.preventDefault();
    if (handleEdit) {
      handleEdit(userData);
      return;
    }
    setEditRowId(userData);
  };
  const handleDeleteClick = (e) => {
    e.preventDefault();
    setDeleteRowId(userData);
  };

  const handleUploadToS3 = (e) => {
    e.preventDefault();
    setBucketRowId && setBucketRowId(userData, e);
    console.log('clicked s3');
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MenuVertical />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {setDeleteRowId && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDeleteClick}>
            <ListItemIcon>
              <Dustbinopen />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {setEditRowId && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={handleEditClick}>
            <ListItemIcon>
              <EditContent />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {setBucketRowId && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={handleUploadToS3}>
            <ListItemIcon>
              <CloudUpload />
            </ListItemIcon>
            <ListItemText primary="Upload to S3" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
