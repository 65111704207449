import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function Check(props) {
  const theme = useTheme();
  const { size = 24, color = 'white' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <g>
        <path
          d="M23.56,0c-.25,0-.2.09-.88,1.05-1.07,1.53-3.47,4.72-5.74,7.82-1.62,2.21-3.18,4.38-4.25,5.78a13.34,13.34,0,0,1-1.17,1.45.29.29,0,0,1-.26,0,2.71,2.71,0,0,1-.82-.45,18.69,18.69,0,0,1-2.9-3c-.35-.43-.45-.71-.68-.52-.18,0-.36.16-.21.39,0,0,.11.16.22.3a24.6,24.6,0,0,0,2.35,2.88A5.44,5.44,0,0,0,10.88,17a1.21,1.21,0,0,0,1.23-.08,18.08,18.08,0,0,0,2.2-2.51c.84-1.07,1.85-2.42,2.87-3.86,2.39-3.35,4.9-7.17,6.05-8.95A6.26,6.26,0,0,0,23.91.35.35.35,0,0,0,23.56,0Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M18,14.28a.35.35,0,0,0-.49,0,.34.34,0,0,0,0,.49,2.61,2.61,0,0,1,.07.83,23.82,23.82,0,0,1-.27,4.15c-.22,1.35-.51,2.58-1,2.93a4.62,4.62,0,0,1-1.33.2c-1.5.12-3.86.15-6.22.09a45.14,45.14,0,0,1-6.16-.46,8,8,0,0,1-1-.17,50.75,50.75,0,0,1-.15-9.11,28.67,28.67,0,0,1,.54-4.85c.1-.42.16-.73.31-.82a6.22,6.22,0,0,1,2.14-.24c3.45-.09,9.27.33,9.74.09A.31.31,0,0,0,14.23,7a.3.3,0,0,0-.41-.14C13.35,7,7.7,6.4,4.33,6.38a7,7,0,0,0-2.61.31A2.33,2.33,0,0,0,.93,8.07a28.29,28.29,0,0,0-.7,5c-.27,4-.14,8.64.16,9.56a1.09,1.09,0,0,0,.7.64,17.43,17.43,0,0,0,4.38.6,69.68,69.68,0,0,0,10-.22,3.76,3.76,0,0,0,1.26-.32c.64-.45,1.12-1.9,1.35-3.56A21.78,21.78,0,0,0,18.18,15,1.33,1.33,0,0,0,18,14.28ZM1.41,22.17s.07.06.09.12S1.37,22.22,1.41,22.17Z"
          fill="#fe4a8c"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
