export const blobToBase64 = (url) => {
  return new Promise(async (resolve, _) => {
    // do a request to the blob uri
    const response = await fetch(url);

    // response has a method called .blob() to get the blob file
    const blob = await response.blob();

    // instantiate a file reader
    const fileReader = new FileReader();

    // read the file
    fileReader.readAsDataURL(blob);

    fileReader.onloadend = function () {
      resolve(fileReader.result); // Here is the base64 string
    };
  });
};

export function base64ToBlob(base64String) {
  // get the data part of the string and decode it from Base64
  const decodedData = atob(base64String.split(',')[1]);

  // determine the MIME type of the data
  const type = base64String.split(';')[0].split(':')[1];

  // create a Uint8Array from the decoded data
  const uint8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
  }

  // create a Blob object from the Uint8Array and return it
  const blob = new Blob([uint8Array], { type });
  console.log('blob ', blob);
  return blob;
}

export const parse = function (file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = function (event) {
      // function b64EncodeUnicode(str) {
      //   // first we use encodeURIComponent to get percent-encoded UTF-8,
      //   // then we convert the percent encodings into raw bytes which
      //   // can be fed into btoa.
      //   return btoa(
      //     encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      //       return String.fromCharCode('0x' + p1);
      //     })
      //   );
      // }

      // function b64DecodeUnicode(str) {
      //   // Going backwards: from bytestream, to percent-encoding, to original string.
      //   return decodeURIComponent(
      //     atob(str)
      //       .split('')
      //       .map(function (c) {
      //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      //       })
      //       .join('')
      //   );
      // }

      let encodedData = event.target.result;

      // if (markDownFolder && file.name.includes('markdown')) {
      //   const initials = event.target.result.substring(0, event.target.result.indexOf(',') + 1);
      //   let decoded = b64DecodeUnicode(event.target.result.substring(event.target.result.indexOf(',') + 1)).replace(
      //     encodeURIComponent(markDownFolder),
      //     `https://${bucketName}.s3.${region}.amazonaws.com/${courseId}/${moduleId}/${lessonId}`
      //   );

      //   decoded = decoded.replace('****', '**');

      //   encodedData = initials + b64EncodeUnicode(decoded);
      // }

      const payload = {
        data: encodedData,
        name: file.name,
        size: file.size,
        type: file.type,
        extension: file.name.match(/\.[0-9a-z]+$/i)[0]
      };
      resolve(payload);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};
