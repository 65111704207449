import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function Student(props) {
  const theme = useTheme();
  const { size = 25, color = 'white' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size}>
      <g>
        <path
          d="M10.62,11.91a.33.33,0,0,0,.47,0,.32.32,0,0,0,.05-.46,2,2,0,0,0-1.16-1,1.13,1.13,0,0,0-.41,0,1.58,1.58,0,0,0-.6.13,2,2,0,0,0-.91.92.29.29,0,0,0,.45.38,1.68,1.68,0,0,1,.8-.28,1.27,1.27,0,0,1,.28,0A1.27,1.27,0,0,1,10.62,11.91Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M13.15,12.27A2,2,0,0,1,14,12a1.92,1.92,0,0,1,.48-.07,1.23,1.23,0,0,1,.84.19.33.33,0,1,0,.48-.46,1.85,1.85,0,0,0-1.26-.83,1.34,1.34,0,0,0-.4,0,1.65,1.65,0,0,0-.57.18,2.11,2.11,0,0,0-.85.94.3.3,0,0,0,.05.42A.29.29,0,0,0,13.15,12.27Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M11.69,14.93a2,2,0,0,0,.69,0,2.84,2.84,0,0,0,1.35-.74.33.33,0,0,0,.08-.46.34.34,0,0,0-.47-.08,2.36,2.36,0,0,1-1.22.22,4.33,4.33,0,0,1-.71-.1,4,4,0,0,1-1.14-.39.29.29,0,0,0-.38.45,3.21,3.21,0,0,0,1.14.95A2,2,0,0,0,11.69,14.93Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <g>
          <path
            d="M2.91,9.15a.34.34,0,0,0,.33.34.34.34,0,0,0,.34-.33c.07-.6.15-1.17.19-1.77,0-.25,0-.51,0-.76a6.45,6.45,0,0,0,0-.76c-.06-.56-.18-1.09-.29-1.64,1.37.37,2.08.6,2.44.72,0,1.08-.06,2.6-.07,4.13.28-.08,8.63.41,12.38,0a18.13,18.13,0,0,0-.34-2.95.36.36,0,0,0-.39-.27.34.34,0,0,0-.27.39,15.24,15.24,0,0,1,.21,2.09c-.95,0-1.9,0-2.84,0-.7,0-1.4,0-2.11-.05s-1.39-.1-2.09-.15S9,8.09,8.25,8.05C7.7,8,7.15,8,6.59,8c0-2.22,0-2.9,0-2.84.74.24,1.47.48,2.2.74a24.52,24.52,0,0,0,2.41.76,8.36,8.36,0,0,0,1.75.24,4.7,4.7,0,0,0,1-.1c.44-.1.89-.24,1.28-.35a18.11,18.11,0,0,0,2.38-.85c1.14-.49,2.23-1.06,3.36-1.6a.34.34,0,0,0,.2-.31.33.33,0,0,0,.23-.23.32.32,0,0,0-.26-.39c-.87-.21-1.72-.48-2.56-.77s-1.68-.63-2.49-1S14.7.7,14,.43A8.32,8.32,0,0,0,12.52,0a2.5,2.5,0,0,0-.86.08c-.5.14-1.1.39-1.42.48-1.18.35-2.4.75-3.58,1.21C3.43,3.05,2.82,3.65,2.85,4.15S2.72,5.31,2.69,5.9c0,.25,0,.5,0,.75s0,.5,0,.75C2.77,8,2.84,8.56,2.91,9.15ZM6.25,2.82c1.4-.47,2.86-.86,4.27-1.18.29-.07.78-.27,1.24-.4a1.43,1.43,0,0,1,.59-.11,7.21,7.21,0,0,1,1.29.34c.68.24,1.34.53,2,.77.85.3,1.72.59,2.6.84.64.18,1.29.34,1.94.48-1,.41-2,.83-3,1.2a17.56,17.56,0,0,1-2.31.67c-.38.08-.81.2-1.24.27a3.14,3.14,0,0,1-.74.07,7.54,7.54,0,0,1-1.52-.22c-.82-.18-1.64-.43-2.35-.64-1-.26-1.9-.5-2.85-.73C5.47,4,4.74,3.86,4,3.72,4.73,3.39,5.47,3.09,6.25,2.82Z"
            fill="#ff4a8c"
            fill-rule="evenodd"
          ></path>
          <path
            d="M23.37,23a7.57,7.57,0,0,0-.94-2.31A6.76,6.76,0,0,0,20.59,19a15.58,15.58,0,0,0-5.7-2.2,6.22,6.22,0,0,0,2.33-2.41,9.48,9.48,0,0,0,1-3.35c0-.42.06-.83.07-1.24-.2-.6-.78-.37-.82,0,0,.11,0,.22,0,.32a8.76,8.76,0,0,1-1.06,3.8,5.2,5.2,0,0,1-3.93,2.58h-.27c-.31,0-.61,0-.92,0a4.73,4.73,0,0,1-3.16-1.61,6.49,6.49,0,0,1-1.29-3.5c-.07-.53-.1-1.07-.13-1.61,0-.7-.88-.56-.8,0A18.23,18.23,0,0,0,6,12.53a6.42,6.42,0,0,0,1.22,3.06,6.06,6.06,0,0,0,1.34,1.15,13.87,13.87,0,0,0-5,1.85A7.24,7.24,0,0,0,1.7,20.26,5.37,5.37,0,0,0,.62,22.54a.3.3,0,0,0,.22.35.28.28,0,0,0,.35-.22,4.76,4.76,0,0,1,1.05-2A6.52,6.52,0,0,1,4,19.31a11.18,11.18,0,0,1,2.4-1c-.09.55-.19,1.1-.24,1.67a8,8,0,0,0,0,.85c0,.28,0,.56,0,.84.07.67.17,1.32.28,2a.34.34,0,0,0,.67,0c0-.65.08-1.3.11-1.95,0-.28,0-.55,0-.83s0-.55,0-.83c0-.63-.13-1.23-.18-1.85l.31-.09s0,.06,0,.08c.36.44.72.89,1.07,1.35.52.69,1,1.39,1.49,2.13l.66.93,0,0a1.83,1.83,0,0,0,.24.34.56.56,0,0,0,.3.13.59.59,0,0,0,.42-.09A7,7,0,0,0,13,21.58c.69-.83,1.24-1.79,1.87-2.7,0,.43,0,.87.06,1.3s.12.9.17,1.34.15,1,.25,1.53a5.84,5.84,0,0,0,.18.69.32.32,0,0,0,.39.25.33.33,0,0,0,.26-.4,4.22,4.22,0,0,1,0-.62c0-.52,0-1,0-1.55A13.07,13.07,0,0,0,16,20c-.14-.66-.36-1.29-.56-1.94A.22.22,0,0,0,15.5,18a13.9,13.9,0,0,1,4.62,1.74,5.53,5.53,0,0,1,1.68,1.45,6.69,6.69,0,0,1,.93,2.06.33.33,0,1,0,.64-.19ZM12.21,20.87a7.88,7.88,0,0,1-.85.79L10.84,21c-.48-.57-1-1.12-1.46-1.66S8.49,18.39,8,18l.73-.14a22.07,22.07,0,0,1,2.46-.18,6.61,6.61,0,0,0,1.09,0,18.74,18.74,0,0,1,2.48.23C13.86,18.81,13.1,19.93,12.21,20.87Z"
            fill="#191919"
            fill-rule="evenodd"
          ></path>
        </g>
        <path
          d="M10.62,11.91a.33.33,0,0,0,.47,0,.32.32,0,0,0,.05-.46,2,2,0,0,0-1.16-1,1.13,1.13,0,0,0-.41,0,1.58,1.58,0,0,0-.6.13,2,2,0,0,0-.91.92.29.29,0,0,0,.45.38,1.68,1.68,0,0,1,.8-.28,1.27,1.27,0,0,1,.28,0A1.27,1.27,0,0,1,10.62,11.91Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M13.15,12.27A2,2,0,0,1,14,12a1.92,1.92,0,0,1,.48-.07,1.23,1.23,0,0,1,.84.19.33.33,0,1,0,.48-.46,1.85,1.85,0,0,0-1.26-.83,1.34,1.34,0,0,0-.4,0,1.65,1.65,0,0,0-.57.18,2.11,2.11,0,0,0-.85.94.3.3,0,0,0,.05.42A.29.29,0,0,0,13.15,12.27Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M11.69,14.93a2,2,0,0,0,.69,0,2.84,2.84,0,0,0,1.35-.74.33.33,0,0,0,.08-.46.34.34,0,0,0-.47-.08,2.36,2.36,0,0,1-1.22.22,4.33,4.33,0,0,1-.71-.1,4,4,0,0,1-1.14-.39.29.29,0,0,0-.38.45,3.21,3.21,0,0,0,1.14.95A2,2,0,0,0,11.69,14.93Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <g>
          <path
            d="M2.91,9.15a.34.34,0,0,0,.33.34.34.34,0,0,0,.34-.33c.07-.6.15-1.17.19-1.77,0-.25,0-.51,0-.76a6.45,6.45,0,0,0,0-.76c-.06-.56-.18-1.09-.29-1.64,1.37.37,2.08.6,2.44.72,0,1.08-.06,2.6-.07,4.13.28-.08,8.63.41,12.38,0a18.13,18.13,0,0,0-.34-2.95.36.36,0,0,0-.39-.27.34.34,0,0,0-.27.39,15.24,15.24,0,0,1,.21,2.09c-.95,0-1.9,0-2.84,0-.7,0-1.4,0-2.11-.05s-1.39-.1-2.09-.15S9,8.09,8.25,8.05C7.7,8,7.15,8,6.59,8c0-2.22,0-2.9,0-2.84.74.24,1.47.48,2.2.74a24.52,24.52,0,0,0,2.41.76,8.36,8.36,0,0,0,1.75.24,4.7,4.7,0,0,0,1-.1c.44-.1.89-.24,1.28-.35a18.11,18.11,0,0,0,2.38-.85c1.14-.49,2.23-1.06,3.36-1.6a.34.34,0,0,0,.2-.31.33.33,0,0,0,.23-.23.32.32,0,0,0-.26-.39c-.87-.21-1.72-.48-2.56-.77s-1.68-.63-2.49-1S14.7.7,14,.43A8.32,8.32,0,0,0,12.52,0a2.5,2.5,0,0,0-.86.08c-.5.14-1.1.39-1.42.48-1.18.35-2.4.75-3.58,1.21C3.43,3.05,2.82,3.65,2.85,4.15S2.72,5.31,2.69,5.9c0,.25,0,.5,0,.75s0,.5,0,.75C2.77,8,2.84,8.56,2.91,9.15ZM6.25,2.82c1.4-.47,2.86-.86,4.27-1.18.29-.07.78-.27,1.24-.4a1.43,1.43,0,0,1,.59-.11,7.21,7.21,0,0,1,1.29.34c.68.24,1.34.53,2,.77.85.3,1.72.59,2.6.84.64.18,1.29.34,1.94.48-1,.41-2,.83-3,1.2a17.56,17.56,0,0,1-2.31.67c-.38.08-.81.2-1.24.27a3.14,3.14,0,0,1-.74.07,7.54,7.54,0,0,1-1.52-.22c-.82-.18-1.64-.43-2.35-.64-1-.26-1.9-.5-2.85-.73C5.47,4,4.74,3.86,4,3.72,4.73,3.39,5.47,3.09,6.25,2.82Z"
            fill="#ff4a8c"
            fill-rule="evenodd"
          ></path>
          <path
            d="M23.37,23a7.57,7.57,0,0,0-.94-2.31A6.76,6.76,0,0,0,20.59,19a15.58,15.58,0,0,0-5.7-2.2,6.22,6.22,0,0,0,2.33-2.41,9.48,9.48,0,0,0,1-3.35c0-.42.06-.83.07-1.24-.2-.6-.78-.37-.82,0,0,.11,0,.22,0,.32a8.76,8.76,0,0,1-1.06,3.8,5.2,5.2,0,0,1-3.93,2.58h-.27c-.31,0-.61,0-.92,0a4.73,4.73,0,0,1-3.16-1.61,6.49,6.49,0,0,1-1.29-3.5c-.07-.53-.1-1.07-.13-1.61,0-.7-.88-.56-.8,0A18.23,18.23,0,0,0,6,12.53a6.42,6.42,0,0,0,1.22,3.06,6.06,6.06,0,0,0,1.34,1.15,13.87,13.87,0,0,0-5,1.85A7.24,7.24,0,0,0,1.7,20.26,5.37,5.37,0,0,0,.62,22.54a.3.3,0,0,0,.22.35.28.28,0,0,0,.35-.22,4.76,4.76,0,0,1,1.05-2A6.52,6.52,0,0,1,4,19.31a11.18,11.18,0,0,1,2.4-1c-.09.55-.19,1.1-.24,1.67a8,8,0,0,0,0,.85c0,.28,0,.56,0,.84.07.67.17,1.32.28,2a.34.34,0,0,0,.67,0c0-.65.08-1.3.11-1.95,0-.28,0-.55,0-.83s0-.55,0-.83c0-.63-.13-1.23-.18-1.85l.31-.09s0,.06,0,.08c.36.44.72.89,1.07,1.35.52.69,1,1.39,1.49,2.13l.66.93,0,0a1.83,1.83,0,0,0,.24.34.56.56,0,0,0,.3.13.59.59,0,0,0,.42-.09A7,7,0,0,0,13,21.58c.69-.83,1.24-1.79,1.87-2.7,0,.43,0,.87.06,1.3s.12.9.17,1.34.15,1,.25,1.53a5.84,5.84,0,0,0,.18.69.32.32,0,0,0,.39.25.33.33,0,0,0,.26-.4,4.22,4.22,0,0,1,0-.62c0-.52,0-1,0-1.55A13.07,13.07,0,0,0,16,20c-.14-.66-.36-1.29-.56-1.94A.22.22,0,0,0,15.5,18a13.9,13.9,0,0,1,4.62,1.74,5.53,5.53,0,0,1,1.68,1.45,6.69,6.69,0,0,1,.93,2.06.33.33,0,1,0,.64-.19ZM12.21,20.87a7.88,7.88,0,0,1-.85.79L10.84,21c-.48-.57-1-1.12-1.46-1.66S8.49,18.39,8,18l.73-.14a22.07,22.07,0,0,1,2.46-.18,6.61,6.61,0,0,0,1.09,0,18.74,18.74,0,0,1,2.48.23C13.86,18.81,13.1,19.93,12.21,20.87Z"
            fill="#191919"
            fill-rule="evenodd"
          ></path>
        </g>
      </g>
    </svg>
  );
}
