import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Link, Container, Typography } from '@mui/material';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import ResetPasswordForm from '../../components/authentication/resetPassword/ResetPasswordForm';
import VerifyCodeForm from '../../components/authentication/verifyCode/VerifyCodeForm';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { MIconButton } from '../../components/@material-extend';
//
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOtpAlreadySent, setIsOtpAlreadySent] = useState(false);

  const handleResendRequest = () => {
    // forgotPassword({ email }).then((res) => {
    //   console.log(res);
    //   enqueueSnackbar(res.message, {
    //     variant: 'success',
    //     action: (key) => (
    //       <MIconButton size="small">
    //         <Icon
    //           icon={closeFill}
    //           onClick={() => {
    //             closeSnackbar(key);
    //           }}
    //         />
    //       </MIconButton>
    //     )
    //   });
    // });
  };

  return (
    <RootStyle title="Reset Password">
      <LogoOnlyLayout />

      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          {!sent ? (
            <>
              <Typography variant="h6" paragraph textAlign="center">
                Forgot your password?
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 3, fontSize: 12, textAlign: 'center' }}>
                Enter your Email Address and we will send you the OTP to log back in.
              </Typography>

              <ResetPasswordForm
                setIsOtpAlreadySent={setIsOtpAlreadySent}
                setSent={setSent}
                onGetEmail={(value) => setEmail(value)}
              />

              <Button size="medium" sx={{ width: '50%', mt: 1, ml: 15 }} component={RouterLink} to={PATH_AUTH.login}>
                Back
              </Button>
            </>
          ) : (
            <Box sx={{ maxWidth: 480, mx: 'auto' }}>
              {!isOtpAlreadySent && (
                <>
                  <Typography variant="h6" paragraph textAlign="center">
                    Enter 4 digit Code sent to your Email Address!
                  </Typography>
                </>
              )}

              <Box sx={{ mt: 5, mb: 3 }}>
                <VerifyCodeForm
                  email={email}
                  setIsOtpAlreadySent={setIsOtpAlreadySent}
                  isOtpAlreadySent={isOtpAlreadySent}
                />
              </Box>

              <Typography variant="body2" align="center">
                Don’t have a code? &nbsp;
                <Link
                  variant="subtitle2"
                  underline="none"
                  onClick={() => {
                    handleResendRequest();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Resend OTP
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </RootStyle>
  );
}
