import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function SectionDuoTone(props) {
  const theme = useTheme();
  const { size = 20, color = theme.palette.primary.main } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M10.2,1.9a1.09,1.09,0,0,0-.69-.53A17.76,17.76,0,0,0,5.27.86C3.13.82,1,1.12.56,1.8l0,0C.2,2.23,0,4.59,0,6.66A15.78,15.78,0,0,0,.26,9.58a.92.92,0,0,0,.34.58,4.73,4.73,0,0,0,1.49.32,41,41,0,0,0,5.22.16A8,8,0,0,0,10,10.19a.94.94,0,0,0,.5-.54,18.74,18.74,0,0,0,.12-3.9A21.72,21.72,0,0,0,10.2,1.9ZM9.38,9.07c-.12,0-.33.16-.56.2a26.8,26.8,0,0,1-4.29.23c-1.14,0-2.21-.05-2.88-.12a1.74,1.74,0,0,1-.38-.1,3,3,0,0,1-.16-.5,30.94,30.94,0,0,1-.23-3.7,8.93,8.93,0,0,1,.24-2.9l0,0a1.32,1.32,0,0,1,.78-.34,10.26,10.26,0,0,1,2.47-.16,21.15,21.15,0,0,1,4.27.51c.82.2.79-.66.84,4.36A22.4,22.4,0,0,1,9.38,9.07Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M10.17,14.63a1.12,1.12,0,0,0-.7-.53,17.28,17.28,0,0,0-4.24-.5c-2.14,0-4.26.25-4.71.93l0,0C.16,15,0,17.33,0,19.39a17,17,0,0,0,.22,2.93,1,1,0,0,0,.34.58,5,5,0,0,0,1.49.31,41.15,41.15,0,0,0,5.22.16,8.11,8.11,0,0,0,2.7-.44,1,1,0,0,0,.49-.54,17.46,17.46,0,0,0,.12-3.9A22.1,22.1,0,0,0,10.17,14.63ZM9.34,21.8c-.12,0-.33.17-.55.21a27.07,27.07,0,0,1-4.29.22,27.66,27.66,0,0,1-2.89-.12A1.93,1.93,0,0,1,1.23,22a3.1,3.1,0,0,1-.16-.51,31.13,31.13,0,0,1-.22-3.69,9,9,0,0,1,.23-2.9l0,0a1.29,1.29,0,0,1,.78-.34,11.35,11.35,0,0,1,2.47-.16,22.7,22.7,0,0,1,4.27.51c.81.2.79-.66.84,4.36A19.82,19.82,0,0,1,9.34,21.8Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M14,10.1a12.65,12.65,0,0,0,3.34.32A33.92,33.92,0,0,0,22,10.18a.33.33,0,0,0,.27-.36A.32.32,0,0,0,22,9.54a27.6,27.6,0,0,1-3.83.07,19.56,19.56,0,0,1-3.55-.33c0-.13,0-.36,0-.57A32.2,32.2,0,0,1,14.45,5a20.07,20.07,0,0,1,.13-2.13,2.3,2.3,0,0,1,.12-.64c.09-.13.32-.17.61-.24a10.42,10.42,0,0,1,2.41-.22A22.67,22.67,0,0,1,22,2.13c.31.06.57.26.69.24A34.56,34.56,0,0,1,23,6.46,13.51,13.51,0,0,1,23,9.3a.35.35,0,0,0,.23.45.37.37,0,0,0,.46-.23,18.71,18.71,0,0,0,.19-3.82,23.11,23.11,0,0,0-.27-3.85,1.22,1.22,0,0,0-.77-.57A20,20,0,0,0,17.72.6,10.15,10.15,0,0,0,14.61,1a1.82,1.82,0,0,0-.91.62,2.54,2.54,0,0,0-.31,1,22,22,0,0,0-.13,3.23,20.11,20.11,0,0,0,.34,3.63A1.1,1.1,0,0,0,14,10.1Zm.66-.73s-.06,0-.07-.09S14.69,9.35,14.64,9.37Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M24,18.43a22.1,22.1,0,0,0-.41-3.86,1.12,1.12,0,0,0-.7-.53,17.28,17.28,0,0,0-4.24-.5c-2.14,0-4.26.25-4.71.93l0,0c-.33.45-.51,2.82-.49,4.88a18,18,0,0,0,.22,2.93,1,1,0,0,0,.34.58,5,5,0,0,0,1.49.31,41.15,41.15,0,0,0,5.22.16,8.11,8.11,0,0,0,2.7-.44,1,1,0,0,0,.49-.55A17.33,17.33,0,0,0,24,18.43Zm-1.24,3.31c-.12,0-.33.17-.55.21a27.07,27.07,0,0,1-4.29.22A27.66,27.66,0,0,1,15,22.05a1.93,1.93,0,0,1-.38-.09,2.41,2.41,0,0,1-.15-.51,28.93,28.93,0,0,1-.23-3.69,9,9,0,0,1,.23-2.9l0,0a1.29,1.29,0,0,1,.78-.34,11.35,11.35,0,0,1,2.47-.16,22.7,22.7,0,0,1,4.27.51c.81.2.79-.66.84,4.36A19.82,19.82,0,0,1,22.72,21.74Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
