import Mixpanel from 'mixpanel-browser';

class MixpanelLayer {
  mixpanel;
  constructor() {
    Mixpanel.init('cf8d2efef0606d0c0049ba7bdccbffdf', { debug: true });
  }

  eventTrack(eventName, properties) {
    Mixpanel.track(eventName, properties);
  }

  setUser(distinctKey, userName, emailId) {
    Mixpanel.identify(distinctKey);
    Mixpanel.people.set('$name', userName);
    Mixpanel.people.set('$email', emailId);
  }
}

export const mixPanelLayer = new MixpanelLayer();
