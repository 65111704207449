import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function GreenCheckBox(props) {
  const theme = useTheme();
  const { size = 24, color = 'white' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M24,11.9c-.1-4.37-.43-8.68-.76-9.35a.64.64,0,0,0-.34-.3A5.11,5.11,0,0,0,21.74,2c-1.88-.2-5.95-.3-10-.29a93.81,93.81,0,0,0-10,.41,4.1,4.1,0,0,0-1.22.38,1.85,1.85,0,0,0-.28.91C.06,4.68,0,7.31,0,10.19,0,14,.11,18.3.26,20.28A4.2,4.2,0,0,0,.5,21.61a.68.68,0,0,0,.28.22,4.1,4.1,0,0,0,1.13.22c1.9.2,6,.24,10.1.17s8.18-.25,10.08-.41a10.22,10.22,0,0,0,1.08-.14.65.65,0,0,0,.52-.47A58.72,58.72,0,0,0,24,11.9Zm-1.32,8.71-1.75.15c-4.33.29-13.88.61-18,.41A9.14,9.14,0,0,1,1.26,21c0-.16-.08-.5-.11-.81-.19-2-.37-6.22-.44-10,0-2.85,0-5.45.1-6.67,0-.28,0-.53.1-.57s.32,0,.68-.08a68.74,68.74,0,0,1,7.92-.31c4.54,0,9.59.16,11.91.42.38,0,.77.11.95.15a63.18,63.18,0,0,1,.49,8.79A73.55,73.55,0,0,1,22.64,20.61Z"
          fill="#27ae60"
        ></path>
        <path
          d="M18.16,6.05C18,6,17.87,6,17.77,5.92l-.87-.34L16,5.3l-.5-.1-.33,0a1.09,1.09,0,0,0-.8.09,1.57,1.57,0,0,0-.44.56l-.44.76L13,7.45,11.75,9.83,10.68,12s-.42.9-.55,1.08l-.48-.67-1-1.66a2.52,2.52,0,0,0-.31-.52A.59.59,0,0,0,7.84,10a1.16,1.16,0,0,0-.49.15,2.79,2.79,0,0,0-.5.36,8.5,8.5,0,0,0-.73.74,7.14,7.14,0,0,0-.63.82,2.6,2.6,0,0,0-.39.7.86.86,0,0,0,0,.5,1.49,1.49,0,0,0,.24.51,5.37,5.37,0,0,0,.68.73,11.35,11.35,0,0,0,1.18,1c.61.45,1.25.85,1.9,1.25a5.69,5.69,0,0,0,1.1.64,1.37,1.37,0,0,0,.82.05,1.59,1.59,0,0,0,.58-.33,3.35,3.35,0,0,0,.5-.58l1.1-1.67,2.29-3.65,2.41-3.45.48-.75a1.33,1.33,0,0,0,.17-.43.55.55,0,0,0-.17-.45A.7.7,0,0,0,18.16,6.05ZM17,7.22l-2.47,3.43c-.41.6-.81,1.22-1.2,1.83L12.2,14.34l-1,1.6c-.09.11-.19.25-.3.36a.47.47,0,0,1-.21.16.63.63,0,0,1-.39-.12c-.25-.13-.5-.31-.69-.41-.63-.37-1.26-.73-1.86-1.15a10.57,10.57,0,0,1-1.12-.88c-.15-.14-.37-.32-.54-.51a.75.75,0,0,1-.17-.28.24.24,0,0,1,0-.18c.07-.14.16-.26.22-.37a8.15,8.15,0,0,1,.55-.76,7,7,0,0,1,.63-.69,2.48,2.48,0,0,1,.3-.25l.13-.08c0,.12.21.26.26.34l1.1,1.67.39.53a1.37,1.37,0,0,0,.2.23.29.29,0,0,0,.29,0,.28.28,0,0,0,.18-.17.3.3,0,0,0,.27.12.33.33,0,0,0,.29-.17c.08-.13.18-.34.18-.34l.36-.69,1.14-2.16,1.27-2.31L14.23,7l.47-.76a1.57,1.57,0,0,1,.12-.19s.11,0,.19,0l.27.06.47.11.81.27.72.3Z"
          fill="#191919"
        ></path>
      </g>
    </svg>
  );
}
