import axios from '../../utils/axios';
import { REACT_APP_SERVER_URL } from '../../constants/constant';

export const toggleRedirectAdmin = () =>
  axios.get(`${REACT_APP_SERVER_URL}/api/student/toggleRedirectAdmin`, { withCredentials: true }).then((result) => {
    if (result) return result.data;
    return Promise.reject(result);
  });

export const getAllCourses = () =>
  axios.get(`${REACT_APP_SERVER_URL}/api/admin/getCourses`, { withCredentials: true }).then((result) => {
    if (result) return result.data;
    return Promise.reject(result);
  });

export const createCourse = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/admin/createCourse`, reqData, { withCredentials: true }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });

export const getCourseBlueprint = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/admin/getCourseBlueprint`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) return result.data;
      return Promise.reject(result);
    });

export const createSection = (reqData) =>
  axios
    .post(`${REACT_APP_SERVER_URL}/api/admin/createNewSection`, reqData, { withCredentials: true })
    .then((result) => {
      if (result.data) return result.data;
      return Promise.reject(result);
    });

export const createModule = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/admin/createModule`, reqData, { withCredentials: true }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });

export const createLesson = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/api/admin/createLesson`, reqData, { withCredentials: true }).then((result) => {
    if (result.data) return result.data;
    return Promise.reject(result);
  });
