import React from 'react';
//material
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import style from './style.module.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import WrongAns from '../../../images/wrongAns.png';
import CorrectAns from '../../../images/correctAns.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Card = (props) => {
  const { handleClose, hints } = props;
  return (
    <div className={style.card1}>
      <div className={style.imageBox}>
        <img src={hints.length === 0 ? CorrectAns : WrongAns} width="300px" />
      </div>
      <div className={style.title1}>
        <p> {hints.length === 0 ? 'Congratulations! ' : 'Try Again!'}</p>
      </div>
      <hr className={style.line1} />
      <div className={style.subTitle}>
        {hints.length === 0 ? (
          <p>Thanks for Completing!</p>
        ) : (
          <ul className={style.hintsList}>
            {hints.map((hint) => {
              return (
                <li>
                  <p>{hint.ansHint}</p>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <div className={style.buttonBox}>
        <button onClick={handleClose} className={style.button1}>
          Continue
        </button>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  paper: { maxWidth: '800px' }
}));

export default function AlertDialogSlide(props) {
  const { open, setOpen, hints, isCorrect } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <DialogContent style={{ minWidth: '600px' }}>
          <Card handleClose={handleClose} isCorrect={isCorrect} hints={hints} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
