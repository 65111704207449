import React from 'react';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import AddSquare from '../../icons/AddSquare';
import Dustbinopen from '../../icons/DustbinOpen';

const InputRow = (props) => {
  const { formDetails, setFormDetails, rowNumber, inputRowData, hasIcon, configs } = props;

  const onChangeTitle = (e, key) => {
    const inputRowArr = [...formDetails[configs.value]];
    inputRowArr[rowNumber][key] = e.target.value;
    const multiInputObj = {};
    multiInputObj[configs.value] = inputRowArr;
    setFormDetails((prev) => {
      return {
        ...prev,
        ...multiInputObj
      };
    });
  };

  const handleDeleteProspect = () => {
    const inputRowArr = [...formDetails[configs.value]];
    inputRowArr.splice(rowNumber, 1);
    const multiInputObj = {};
    multiInputObj[configs.value] = inputRowArr;
    setFormDetails((prev) => {
      return {
        ...prev,
        ...multiInputObj
      };
    });
  };

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} mb={3}>
        <TextField
          variant="outlined"
          value={inputRowData.title}
          label="title"
          onChange={(e) => onChangeTitle(e, 'title')}
        />
        <TextField
          variant="outlined"
          value={inputRowData.description}
          label="description"
          onChange={(e) => onChangeTitle(e, 'description')}
        />
        {hasIcon ? (
          <TextField
            variant="outlined"
            value={inputRowData.icon}
            label="icon"
            onChange={(e) => onChangeTitle(e, 'icon')}
          />
        ) : (
          ''
        )}
        <IconButton onClick={handleDeleteProspect}>
          <Dustbinopen />
        </IconButton>
      </Stack>
    </>
  );
};

const MultipleInputForm = (props) => {
  const { prospectHeading, prospectData, addProspectRow, setFormDetails, formDetails, hasIcon, configs } = props;

  const onClickAdd = () => {
    addProspectRow();
  };
  return (
    <>
      <Stack direction={'row'} alignItems="center" mb={3}>
        <Typography variant="overline">{prospectHeading}</Typography>
        <IconButton onClick={onClickAdd}>
          <AddSquare />
        </IconButton>
      </Stack>
      {prospectData.map((inputRowData, index) => {
        return (
          <InputRow
            key={`inputRowData-${index}`}
            setFormDetails={setFormDetails}
            formDetails={formDetails}
            rowNumber={index}
            inputRowData={inputRowData}
            hasIcon={hasIcon}
            configs={configs}
          />
        );
      })}
    </>
  );
};

const MultipleInput = (props) => {
  const { multipleInputType, formDetails, configs, setFormDetails, multipleInputHeading, hasIcon } = props;

  const addProspectRow = () => {
    const key = configs.value;
    const multiInputObj = {};
    multiInputObj[key] = formDetails[key];
    let obj = {};
    if (hasIcon) {
      obj = {
        title: '',
        description: '',
        icon: ''
      };
    } else {
      obj = {
        title: '',
        description: ''
      };
    }
    setFormDetails((prev) => {
      multiInputObj[key] = [...prev[key], obj];
      return {
        ...prev,
        ...multiInputObj
      };
    });
  };

  return (
    <MultipleInputForm
      prospectData={formDetails[configs.value]}
      configs={configs}
      setFormDetails={setFormDetails}
      formDetails={formDetails}
      prospectHeading={multipleInputHeading}
      addProspectRow={addProspectRow}
      hasIcon={hasIcon}
    />
  );
};

export default MultipleInput;
