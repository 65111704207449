import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function Dustbinopen(props) {
  const theme = useTheme();
  const { size = 24, color = theme.palette.primary.main } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M21.23,7.19A4,4,0,0,1,20.3,7c-.22-.06-.45-.13-.67-.21.2-.21.14-1.12.1-1.3a1.14,1.14,0,0,0-.53-.78,13.6,13.6,0,0,0-2.58-1.23c0-.57.21-.31-.23-1.5A3.67,3.67,0,0,0,15.64.73,2.45,2.45,0,0,0,14.21,0a4.48,4.48,0,0,0-1.46,0,2.85,2.85,0,0,0-1.38.68,6.87,6.87,0,0,0-.94,1.5l-.8,0a14.19,14.19,0,0,0-3,.3,1.49,1.49,0,0,0-.68.33,2.46,2.46,0,0,0-.63.79A5.65,5.65,0,0,0,5,4.25v0l-1.6-.14c-1,0-.72,0-.79,0s-.36.52.16.59c.15,0,.54,0,.63,0L4.69,5l4.51.75c1.19.15,2.41.34,3.62.53.81.13,1.61.24,2.41.38S17,7,17.81,7.14c.67.14,1.54.43,2.31.6a4.68,4.68,0,0,0,1.11.13.34.34,0,0,0,0-.68ZM11.94,1.48a2.06,2.06,0,0,1,.78-.26,4.09,4.09,0,0,1,1.35,0,1.38,1.38,0,0,1,.86.32,2.65,2.65,0,0,1,.66.84c0,.07.12.53.21.88a28.37,28.37,0,0,0-3.15-.74l-1.29-.16C11.6,2,11.85,1.54,11.94,1.48Zm1.76,3.8c-1.46-.26-2.93-.44-4.37-.6L5.7,4.36A3.18,3.18,0,0,0,5.85,4a2.05,2.05,0,0,1,.4-.46.88.88,0,0,1,.47-.21A14.63,14.63,0,0,1,9,3.23a23,23,0,0,1,3.45.41,28.35,28.35,0,0,1,3.9.82,13,13,0,0,1,2.44,1A.48.48,0,0,1,19,5.8a3.09,3.09,0,0,1,0,.74.13.13,0,0,0,0,.06c-.37-.12-.72-.25-1-.33C16.62,5.9,15.17,5.55,13.7,5.28Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M19.56,9.59A2.94,2.94,0,0,0,18.15,9a36.31,36.31,0,0,0-4.92-.43C10.11,8.48,6.62,8.49,5,8.5H4.13a.2.2,0,0,0-.13,0s-.21.59.23.6l1.37,0c2.55.06,7.72.21,10.89.62a13.65,13.65,0,0,1,1.82.34,3.21,3.21,0,0,1,.36.11c0,.12.06.41,0,.69-.05.94-.24,2.41-.47,3.92-.29,1.89-.63,3.85-.89,4.84-.13.46-.21.93-.36,1.37a2.63,2.63,0,0,1-.34.72,2.58,2.58,0,0,1-1.44.87,8.2,8.2,0,0,1-2.91.23c-1.14-.11-2.38,0-3.56-.09a4.88,4.88,0,0,1-2.06-.53,1.73,1.73,0,0,1-.6-.79,7.47,7.47,0,0,1-.55-1.75c-.18-1-.39-2.33-.59-3.69-.34-2.21-.66-4.5-.83-5.57a.34.34,0,0,0-.39-.29.36.36,0,0,0-.29.39l1.22,9.31a8.7,8.7,0,0,0,.57,2,2.74,2.74,0,0,0,1,1.24A5.47,5.47,0,0,0,8,23.71c1.35.23,2.82.08,4.16.23a9.21,9.21,0,0,0,3.34-.22,3.66,3.66,0,0,0,2.06-1.31,3.8,3.8,0,0,0,.5-1c.16-.47.25-1,.38-1.45a61.41,61.41,0,0,0,1.15-7,19.29,19.29,0,0,0,.15-2.79A1.1,1.1,0,0,0,19.56,9.59Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M8.79,16.69c.14.78.31,1.46.41,2,.06.31.11.54.13.65.06.3.31.26.5.17s.19-.24.16-.92c0-.51,0-1.21-.09-2,0-.42-.09-.87-.15-1.32,0-.22-.06-.44-.1-.65-.2-1-.45-2-.59-2.6a.3.3,0,0,0-.34-.26.3.3,0,0,0-.26.34c0,.63,0,1.61.08,2.66,0,.22,0,.44.07.66C8.66,15.82,8.72,16.27,8.79,16.69Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M14,19.32a.35.35,0,0,0,.34-.35c.05-.56.21-1.42.32-2.36.06-.52.12-1.07.14-1.59,0-1.25,0-2.33,0-2.69a.3.3,0,0,0-.29-.32.29.29,0,0,0-.32.28c-.06.32-.28,1.22-.44,2.3-.05.33-.08.66-.11,1s0,.68,0,1c0,.94,0,1.81.09,2.37A.35.35,0,0,0,14,19.32Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
