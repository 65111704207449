import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/customTable/CustomTable';
import { getAllCourses } from '../../services/admin/admin';
import { COURSES_TABLE_HEAD, buildCoursesTableData } from '../../constants/constant';
import { Typography, Button, Stack } from '@mui/material';
import styles from './AdminCoursesPage.module.css';
import AddSquare from '../../icons/AddSquare';
import { useNavigate } from 'react-router-dom';

const AdminCoursesPage = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [courseTableRowData, setCourseTableRowData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllCourses()
      .then((data) => {
        setCoursesData(data.data.courses);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setCourseTableRowData(buildCoursesTableData(coursesData));
  }, [coursesData]);

  return (
    <div className={styles.container}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 3, marginTop: 8 }}>
        <Typography variant="h5"> Courses List </Typography>
        <Button onClick={() => (window.location.href = '/admin/add-course')}>
          {' '}
          <AddSquare /> <div style={{ marginLeft: 12 }}>Add New Course</div>
        </Button>
      </Stack>
      <CustomTable
        tableHead={COURSES_TABLE_HEAD}
        tableBody={courseTableRowData}
        searchRow={COURSES_TABLE_HEAD[1].id}
        handleEdit={(data) => {
          navigate(`/admin/add-treenode/${data.course_id?.value}`);
        }}
      />
    </div>
  );
};

export default AdminCoursesPage;
