import axios from '../../utils/axios';
import { REACT_APP_SERVER_URL } from '../../constants/constant';

export const userLoginViaOTP = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/auth/loginViaOtp`, reqData, { withCredentials: true }).then((result) => {
    if (result) return result.data;
    return Promise.reject(result);
  });

export const verifyOtp = (reqData) =>
  axios.post(`${REACT_APP_SERVER_URL}/auth/verifyOtp`, reqData, { withCredentials: true }).then((result) => {
    if (result) return result.data;
    return Promise.reject(result);
  });
