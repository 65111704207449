import React, { useEffect, useState } from 'react';
import UploadMultiFile from '../UploadMultiFile/index';
import { Button, Typography } from '@mui/material';
import FileCheck from '../../icons/FileCheck';
import { isArray } from 'lodash';

const GeneralFormFileUpload = ({ buttonNode, onFileAdd, multiple, configs }) => {
  const [files, setFiles] = useState(multiple ? [] : {});

  useEffect(() => {
    onFileAdd(files);
  }, [files]);

  const filesAddedNode = (
    <Button size="small">
      <FileCheck />
      <Typography ml={2} variant="title"></Typography>
      {` successfully Uploaded ${configs.label}`}
    </Button>
  );

  return (
    <UploadMultiFile
      multiple={multiple}
      buttonType={(isArray(files) ? files.length > 0 : Object.keys(files) > 0) ? filesAddedNode : buttonNode}
      setAllFiles={setFiles}
      allFiles={files}
      handleFileUpload={() => {}}
    />
  );
};

export default GeneralFormFileUpload;
