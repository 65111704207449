import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function MultipleSelectCheckmarks({
  options,
  formState,
  setFormState,
  configs,
  globalOptions,
  setGlobalOptions
}) {
  const [multiState, setMultiState] = React.useState([]);
  const selectOptions = configs.useGlobalOptions ? globalOptions : options;

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    const stateValue = typeof value === 'string' ? value.split(',') : value;
    setMultiState(
      // On autofill we get a stringified value.
      stateValue
    );

    setFormState((prev) => {
      return {
        ...prev,
        [configs.value]: stateValue
      };
    });
  };

  console.log('person name ', options);

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel sx={{ top: '-7px' }} id="demo-multiple-checkbox-label">
          {configs.label}
        </InputLabel>
        <Select
          // labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={multiState}
          onChange={handleChange}
          input={<OutlinedInput label={configs.label} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          size={'small'}
          //   label={configs.label}
          sx={{
            width: '100%',
            // '& .MuiInputLabel-root': {
            //   bottom: '25px',
            //   top: '-7px'
            // },
            '& .MuiSelect-select': {
              padding: '9px 14px'
            }
          }}
        >
          {selectOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={multiState.indexOf(option) > -1} />
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
