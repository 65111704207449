import React, { useState } from 'react';
import GeneralForm from '../generalForm/GeneralForm';
import { createModule } from '../../services/admin/admin';
import { useSnackbar } from 'notistack';

const moduleConfiguration = [
  {
    label: 'Module Name',
    value: 'name',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      fullWidth: true,
      size: 'small'
    }
  },
  {
    label: 'Sequence Id',
    value: 'sequenceId',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      fullWidth: true,
      size: 'small',
      type: 'number'
    }
  },
  {
    label: 'Release Week',
    value: 'releaseWeek',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      fullWidth: true,
      size: 'small',
      type: 'number'
    }
  },
  {
    label: 'Week Duration',
    value: 'weekDuration',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      fullWidth: true,
      size: 'small',
      type: 'number'
    }
  },
  {
    label: 'Weightage',
    value: 'weightage',
    type: 'textField',
    fieldProps: {
      defaultValue: '',
      variant: 'outlined',
      fullWidth: true,
      size: 'small',
      type: 'number'
    }
  },
  {
    label: 'Icon Image',
    value: 'iconImage',
    type: 'fileUpload'
  }
];

const AddModuleComponent = ({
  treeBlueprint,
  courseId,
  moduleState,
  setModuleState,
  eventData,
  setFetchBlueprintValue
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [disableSubmit, setdDisableSubmit] = useState(false);

  const onSubmit = () => {
    const modulePayload = {
      ...moduleState,
      courseId: courseId,
      treeDataPayload: {
        data: treeBlueprint.data.treeData,
        event: eventData
      }
    };
    createModule(modulePayload).then((res) => {
      enqueueSnackbar(res.data.message, {
        variant: res.data.message !== 'Module created successfully!' ? 'error' : 'success'
      });
      if (res.data.message === 'Module created successfully!') {
        setFetchBlueprintValue((prevState) => prevState + 1);
      }
      onReset();
    });
  };

  const onReset = () => {
    setModuleState({
      name: '',
      sequenceId: '',
      releaseWeek: '',
      weekDuration: '',
      weightage: ''
      //   iconImage: ''
    });
  };

  return (
    <GeneralForm
      formDetails={moduleState}
      setFormDetails={setModuleState}
      fieldConfigs={moduleConfiguration}
      formTitle={'Add Module Details'}
      onSubmitForm={onSubmit}
      onResetForm={onReset}
      disableSubmitButton={disableSubmit}
      gridSize={{
        sx: 12,
        md: 12,
        lg: 6
      }}
    />
  );
};

export default AddModuleComponent;
