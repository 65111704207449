import React, { useState } from 'react';
import { Stack, Typography, IconButton, TextField } from '@mui/material';
import AddSquare from '../../icons/AddSquare';
import Dustbinopen from '../../icons/DustbinOpen';

const ArrayText = (props) => {
  const { setFormDetails, formDetails, value, label } = props;
  const [textForm, setTextForm] = useState([]);

  const onClickAdd = () => {
    setTextForm((prev) => {
      return [
        ...prev,
        {
          text: ''
        }
      ];
    });
  };

  const onChange = (e, index) => {
    e.preventDefault();
    const textArray = [...textForm];
    textArray[index].text = e.target.value;
    setTextForm(textArray);
    const newObj = {};
    newObj[value] = textArray;
    setFormDetails((prev) => {
      return {
        ...prev,
        ...newObj
      };
    });
  };

  const handleDelete = (e, index) => {
    e.preventDefault();
    const textArray = [...textForm];
    textArray.splice(index, 1);
    setTextForm(textArray);
    const newObj = {};
    newObj[value] = textArray;
    setFormDetails((prev) => {
      return {
        ...prev,
        ...newObj
      };
    });
  };

  return (
    <>
      <Stack direction={'row'} alignItems="center" mb={3}>
        <Typography variant="overline">{label}</Typography>
        <IconButton onClick={onClickAdd}>
          <AddSquare />
        </IconButton>
      </Stack>
      {textForm.map((text, index) => {
        return (
          <Stack m={2} direction={'row'} key={`text-${index}`} spacing={2}>
            <TextField
              variant="outlined"
              value={textForm[index].text}
              label="text"
              fullWidth={true}
              onChange={(e) => onChange(e, index)}
            />
            <IconButton onClick={(e) => handleDelete(e, index)}>
              <Dustbinopen />
            </IconButton>
          </Stack>
        );
      })}
    </>
  );
};

export default ArrayText;
