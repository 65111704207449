import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const DoubtTriggerRefreshContext = createContext({});

DoubtTriggerRefreshProvider.propTypes = {
  children: PropTypes.node
};

function DoubtTriggerRefreshProvider({ children }) {
  const [doubtsRefreshToken, setDoubtsRefreshToken] = useState(0);
  return (
    <DoubtTriggerRefreshContext.Provider
      value={{
        doubtsRefreshToken,
        setDoubtsRefreshToken
      }}
    >
      {children}
    </DoubtTriggerRefreshContext.Provider>
  );
}

export { DoubtTriggerRefreshContext, DoubtTriggerRefreshProvider };
