import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { sample } from 'lodash';

// material
import {
  Card,
  Table,
  Stack,
  Grid,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import CustomTableHead from './CustomTableHead';
import CustomTableToolbar from './CustomTableToolbar';
import CustomTableMoreMenu from './CustomTableMoreMenu';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

const TABLE_BODY = [
  {
    id: '123',
    name: { value: 'temp1' },
    moreButton: true,
    company: { value: 'comp1' },
    role: { value: 'rolw1' },
    isVerified: { value: 'true' },
    status: {
      value: sample(['active', 'banned']),
      label: true,
      successLabel: ['active']
    }
  },
  {
    id: '12w',
    name: { value: 'temp1' },
    moreButton: true,
    company: { value: 'comp21' },
    role: { value: 'role2' },
    isVerified: { value: 'true' },
    status: {
      value: sample(['active', 'banned']),
      label: true,
      successLabel: ['active']
    }
  },
  {
    id: '12r',
    name: { value: 'temp1' },
    moreButton: true,
    company: { value: 'comp3' },
    role: { value: 'role4' },
    isVerified: { value: 'true' },
    status: {
      value: sample(['active', 'banned']),
      label: true,
      successLabel: ['active']
    }
  },
  {
    id: '121',
    name: { value: 'temp1' },
    moreButton: true,
    company: { value: 'comp3' },
    role: { value: 'role7' },
    isVerified: { value: 'true' },
    status: {
      value: sample(['active', 'banned']),
      label: true,
      successLabel: ['active']
    }
  }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] && a[orderBy] && b[orderBy].value < a[orderBy].value) {
    return -1;
  }
  if (b[orderBy] && a[orderBy] && b[orderBy].value > a[orderBy].value) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, searchRow) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user[searchRow].value.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomTable(props) {
  const {
    tableHead = TABLE_HEAD,
    searchRow = TABLE_HEAD[0].id,
    tableBody = TABLE_BODY,
    rowsPerPageOptions = [5, 10, 15],
    setEditRowId = () => {
      console.log('edit');
    },
    setDeleteRowId = () => {
      console.log('edit');
    },
    setBucketRowId = () => {
      console.log('edit');
    },
    selection = false,
    handleEdit
  } = props;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableBody.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableBody.length) : 0;

  const filteredUsers = applySortFilter(tableBody, getComparator(order, orderBy), filterName, searchRow);

  const isUserNotFound = filteredUsers.length === 0;

  const fontStyle = {
    fontSize: '12px'
  };

  return (
    <Card
      sx={{
        border: '2px solid #F2F3F5'
      }}
    >
      <CustomTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

      <Scrollbar>
        <TableContainer>
          <Table size="small">
            <CustomTableHead
              order={order}
              orderBy={orderBy}
              selection={selection}
              headLabel={tableHead}
              rowCount={tableBody.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id } = row;
                const isItemSelected = selected.indexOf(id) !== -1;

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    {selection && (
                      <TableCell sx={fontStyle} padding="checkbox">
                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                      </TableCell>
                    )}

                    {tableHead.map((head) => (
                      <>
                        {row[head.id] && row[head.id].image ? (
                          <TableCell sx={fontStyle} component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={row[head.id].value} src={row[head.id].image} />
                              <Typography variant="subtitle2" noWrap>
                                {row[head.id].value}
                              </Typography>
                            </Stack>
                          </TableCell>
                        ) : row[head.id] && row[head.id].label ? (
                          <TableCell sx={fontStyle} align="left">
                            <Label variant="ghost" color={row[head.id].flag ? 'success' : 'error'}>
                              {sentenceCase(row[head.id].value)}
                            </Label>
                          </TableCell>
                        ) : (
                          <TableCell sx={fontStyle} align="left">
                            {row[head.id] && row[head.id].value}
                          </TableCell>
                        )}
                      </>
                    ))}

                    {row.moreButton && (
                      <TableCell sx={fontStyle} align="right">
                        <CustomTableMoreMenu
                          setEditRowId={setEditRowId}
                          setDeleteRowId={setDeleteRowId}
                          setBucketRowId={setBucketRowId}
                          userData={row}
                          handleEdit={handleEdit}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell sx={fontStyle} colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={tableBody.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
