import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Editor from '@monaco-editor/react';
import AddSquare from '../../icons/AddSquare';
import GreenCheckBox from '../../icons/GreenCheckBox';

const AddCodeModal = ({ setFormState, formState, config }) => {
  const [openModal, setOpenModal] = useState(false);
  const [codeState, setCodeState] = useState();
  const [isSaved, setIsSaved] = useState(false);

  const handleSaveCode = () => {
    console.log('codeState ', codeState);
    if (config.parentValue) {
      setFormState((prev) => {
        return {
          ...prev,
          [config.parentValue]: {
            ...prev[config.parentValue],
            [config.value]: codeState
          }
        };
      });
    } else {
      setFormState((prev) => {
        return {
          ...prev,
          [config.value]: codeState
        };
      });
    }
    setIsSaved(true);
    setOpenModal(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleEditorChange = (value, event) => {
    setCodeState(value);
  };
  return (
    <>
      <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
        <IconButton onClick={() => setOpenModal(true)}>
          {isSaved && codeState ? <GreenCheckBox /> : <AddSquare />}
        </IconButton>
        <Typography variant="caption">{config.label}</Typography>
      </Stack>

      <Dialog open={openModal} onClose={handleModalClose} fullWidth={true}>
        <DialogTitle>
          Add Code
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Editor
            height="40vh"
            defaultLanguage="cpp"
            defaultValue={codeState}
            options={{ fontSize: 13 }}
            theme={'xcode-default'}
            loading={<div>shoshin school</div>}
            onChange={handleEditorChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveCode} variant="contained">
            Save the code
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCodeModal;
