import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
  font-size: 1rem;
  //   margin-bottom: 8px;
  margin: 0.3rem;
  width: auto;
  color: white;
  cursor: grab;
  transition: background-color 0.2s;
  background-color: ${(props) =>
    props.isDragDisabled ? 'lightgrey' : props.isDragging ? 'rgba(25, 104, 107, 0.6)' : '#19686B'};
`;

const Task = (props) => {
  const { task, index, setDragged } = props;
  const isDragDisabled = task.id === 'task-1';

  return (
    <Draggable draggableId={task.id} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
          isDragging={snapshot.isDragging}
          isDragDisabled={isDragDisabled}
        >
          {task.content}
        </Container>
      )}
    </Draggable>
  );
};

export default Task;
