//React
import { useCallback, useState } from 'react';
//Material
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
//Components
import UploadFiles from './uploadFiles';
//services
import { uploadDoubtFile } from '../../services/student/student';
//hooks
import useAuth from '../../hooks/useAuth';
import useSocket from '../../hooks/useSocket';

//------------------------------------------------------------

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

// export interface DialogTitleProps {
//   id: string;
//   children?: React.ReactNode;
//   onClose: () => void;
// }

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function UploadMultiFile({
  buttonType,
  setAllFiles,
  allFiles,
  bucketName,
  region,
  courseId,
  moduleId,
  lessonId,
  handleFileUpload,
  markDownFolder,
  multiple
}) {
  const [allAcceptedFiles, setAllAcceptedFiles] = useState([]);
  const [values, setFieldValue] = useState([]);
  const [showPreview, setShowPreview] = useState(true);
  const [open, setOpen] = useState(false);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setAllAcceptedFiles(acceptedFiles);
      setFieldValue(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    [setFieldValue]
  );

  const handleRemoveAll = () => {
    setFieldValue([]);
    setAllFiles([]);
  };

  const handleRemove = (file) => {
    const filteredItems = values && values.filter((_file) => _file !== file);
    setFieldValue(filteredItems);

    const filteredAcceptedFiles = allFiles && allFiles.filter((_file) => _file.name !== file.name);
    setAllFiles(filteredAcceptedFiles);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadFiles = () => {
    handleFileUpload();
    handleClose();
  };

  return (
    <>
      <div onClick={handleClickOpen}>{buttonType}</div>
      <BootstrapDialog
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add File
        </BootstrapDialogTitle>
        <UploadFiles
          bucketName={bucketName}
          region={region}
          courseId={courseId}
          moduleId={moduleId}
          lessonId={lessonId}
          handleUploadFiles={handleUploadFiles}
          markDownFolder={markDownFolder}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          setAllFiles={setAllFiles}
          allFiles={allFiles}
          setAllAcceptedFiles={setAllAcceptedFiles}
          allAcceptedFiles={allAcceptedFiles}
          maxSize={3145728}
          accept="image/*"
          files={values}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          error={false}
          multiple={multiple}
        />
      </BootstrapDialog>
    </>
  );
}
