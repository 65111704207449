import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { Box, Grid, Card, Stack, TextField, Typography, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// utils
import { fData } from '../../utils/formatNumber';
//
import countries from './countries';
import UploadAvatar from '../upload/UploadAvatar';
import { updateProfileDetails } from '../../services/student/student';
import { parse } from '../../utils/utils';

// ----------------------------------------------------------------------

export default function AccountGeneral({ profileDetails, getUserDetails }) {
  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required')
  });

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayName: profileDetails.name || '',
      email: profileDetails.email || '',
      photoURL: {
        preview: profileDetails.thumbnail
      },
      phoneNumber: profileDetails.phoneNumber || '',
      country: profileDetails.country || '',
      address: profileDetails.address || '',
      state: profileDetails.state || '',
      city: profileDetails.city || '',
      zipCode: profileDetails.zipCode || '',
      about: profileDetails.about || ''
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateProfileDetails({
          emailId: values.email,
          profileData: {
            name: values.displayName,
            thumbnail: values.photoURL.preview,
            phoneNumber: values.phoneNumber,
            ...values
          }
        });
        enqueueSnackbar('Updated Successfully', { variant: 'success' });
        getUserDetails();
        setSubmitting(false);
      } catch (error) {
        setErrors(error);
        setSubmitting(false);
        enqueueSnackbar('Error in saving the profile', { variant: 'error' });
      }
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        let fileData = await parse(file);
        setFieldValue('photoURL', {
          ...file,
          preview: fileData.data
        });
      }
    },
    [setFieldValue]
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                py: 10,
                px: 3,
                textAlign: 'center',
                height: '100%',
                boxShadow: '0px 4px 20px rgba(0,0,0,0.12)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <UploadAvatar
                accept="image/*"
                file={values.photoURL}
                maxSize={3145728}
                onDrop={handleDrop}
                error={Boolean(touched.photoURL && errors.photoURL)}
                sx={{ margin: '0 auto 0 auto' }}
                caption={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />

              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {touched.photoURL && errors.photoURL}
              </FormHelperText>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ pt: 3, pb: 3, pr: 5, pl: 5, boxShadow: '0px 4px 20px rgba(0,0,0,0.12)' }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField size="small" fullWidth label="Name" {...getFieldProps('displayName')} />
                  <TextField size="small" fullWidth disabled label="Email Address" {...getFieldProps('email')} />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField size="small" fullWidth label="Phone Number" {...getFieldProps('phoneNumber')} />
                  <TextField size="small" fullWidth label="Address" {...getFieldProps('address')} />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Country"
                    placeholder="Country"
                    {...getFieldProps('country')}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                  >
                    <option value="" />
                    {countries.map((option) => (
                      <option key={option.code} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextField size="small" fullWidth label="State/Region" {...getFieldProps('state')} />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField size="small" fullWidth label="City" {...getFieldProps('city')} />
                  <TextField size="small" fullWidth label="Zip/Code" {...getFieldProps('zipCode')} />
                </Stack>

                <TextField
                  size="small"
                  {...getFieldProps('about')}
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={4}
                  label="About"
                />
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
