import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const RadioComponent = (props) => {
  const { radioOptions, label, setState, state } = props;
  const [radioState, setRadioState] = useState('');
  const handleChange = (event) => {
    console.log(event.target.value);
    setRadioState(event.target.value);
    setState((prev) => {
      return {
        ...prev,
        [label]: event.target.value
      };
    });
  };

  console.log(state);

  return (
    <>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={state.questions ? state.questions[label] : radioState}
          onChange={handleChange}
        >
          {radioOptions.map((option) => {
            return <FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.label} />;
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default RadioComponent;
