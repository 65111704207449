import React from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const GeneralDatePicker = (props) => {
  const { setDateState, configs } = props;
  const [value, setValue] = React.useState(null);

  return (
    <div style={{ width: '90%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={configs.label}
          size="small"
          value={value}
          onChange={(newValue) => {
            setDateState(newValue);
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
};

export default GeneralDatePicker;
