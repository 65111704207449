import React from 'react';
import { Card, Grid, Typography, Link, Divider, Tooltip, IconButton } from '@mui/material';

import { Stack } from '@mui/system';
import IIcon from '../../icons/IIcon';
import dayjs from 'dayjs';

const PaymentHistoryCard = ({ paymentDetails }) => {
  return (
    <Card
      sx={{
        width: '100%',
        padding: '25px 35px',
        display: 'flex',
        marginBottom: '20px',
        boxShadow: '0px 4px 20px rgba(0,0,0,0.12)'
      }}
    >
      <Grid sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} item>
        <Typography variant="subtitle2">{paymentDetails.courseHeading}</Typography>
      </Grid>
      <Divider sx={{ borderWidth: 'thin', marginLeft: '10px' }} />

      <Grid item sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="subtitle2">{`Rs. ${paymentDetails.coursePrice}`}</Typography>
      </Grid>
      <Divider sx={{ borderWidth: 'thin', marginLeft: '10px' }} />

      <Grid item sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Stack>
          <Typography variant="subtitle2">{dayjs(paymentDetails.enrolledDate).format('ddd, MMMM D YYYY')}</Typography>
          <Link fontSize={'13px'} href={paymentDetails.invoice} underline="hover">
            {'Download Receipt'}
          </Link>
        </Stack>
      </Grid>
      <Divider sx={{ borderWidth: 'thin', marginLeft: '10px' }} />
      <Grid item sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Stack>
          <Typography variant="subtitle2">Paid</Typography>
          <Stack direction={'row'} alignItems="center">
            <Typography variant="caption">Refund Window </Typography>{' '}
            <Tooltip
              title={
                <div
                  style={{
                    padding: '10px'
                  }}
                >
                  <Typography variant="caption">{`Your last date to refund the amount is ${dayjs(
                    paymentDetails.refundWindowDate
                  ).format('ddd, MMMM D YYYY')}`}</Typography>
                </div>
              }
              arrow
            >
              <IconButton
                sx={{
                  ':hover': {
                    backgroundColor: 'white'
                  }
                }}
                size="small"
              >
                <IIcon size={15} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Grid>
      {/* </Grid> */}
    </Card>
  );
};

export default PaymentHistoryCard;
