import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
//material
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
//hooks
import useAuth from '../../hooks/useAuth';
//components
import ModuleCard from '../../components/_dashboard/module/ModuleCard';
import LoadingScreen from '../../components/LoadingScreen';
//
import { getModulesDashboard } from '../../services/student/student';

const useStyles = makeStyles({
  level: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#747474',
    margin: '1% 1% 0% 0%'
  },
  module: {
    margin: '1% 4% 2% 1%'
  },
  part: {
    padding: '2%'
  }
});

export default function ModulePanel() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();
  const { user } = useAuth();

  const [moduleDashboard, setModuleDashboard] = useState();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (!refresh) {
      setRefresh(true);
      getModulesDashboard({ courseId: params.courseId, emailId: user.email })
        .then((result) => {
          if (!result.status) {
            setRefresh(false);
            navigate('/dashboard/app');
          } else {
            setModuleDashboard(result.data);
            setRefresh(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setRefresh(false);
          navigate('/dashboard/app');
        });
    }
  }, [params, location]);

  return (
    <>
      {refresh ? (
        <LoadingScreen />
      ) : (
        <div>
          {moduleDashboard &&
            Object.keys(moduleDashboard).map((section) => (
              <>
                <Typography sx={{ pl: 2, mt: 8 }} gutterBottom variant="h6">
                  {moduleDashboard[section].sectionName}
                </Typography>
                <Grid className={classes.part} container direction="row" pt={5} pl={3} spacing={5}>
                  {moduleDashboard[section].modules.map((module) => (
                    <Grid item>
                      <ModuleCard courseId={params.courseId} module={module} />
                    </Grid>
                  ))}
                </Grid>
              </>
            ))}
        </div>
      )}
    </>
  );
}
