import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// // slices
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
// import blogReducer from './slices/blog';
// import userReducer from './slices/user';
// import productReducer from './slices/product';
// import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};
const number = (state = 0, action) => {
  switch (action.type) {
    case 'INCREASE':
      return state + 1;
    case 'DECREASE':
      return state - 1;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  number
  //   chat: chatReducer,
  //   blog: blogReducer,
  //   user: userReducer,
  //   calendar: calendarReducer,
  //   kanban: kanbanReducer,
  //   product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
