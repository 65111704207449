import { mixPanelLayer } from './mixpanel';

export const analyticsPlatform = {
  mixpanel: 'mixpanel'
};

class Analytics {
  setUser = (props) => {
    switch (props.type) {
      case analyticsPlatform.mixpanel:
        mixPanelLayer.setUser(props.distinctKey, props.userName, props.emailId);
        break;
      default:
        console.log('please provide analytics vendor type');
    }
  };

  trackEvent = (props) => {
    switch (props.type) {
      case analyticsPlatform.mixpanel:
        mixPanelLayer.eventTrack(props.eventName, props.eventProperties);
        break;
      default:
        console.log('please provide analytics vendor type');
    }
  };
}

export const analytics = new Analytics();
