import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function IIcon(props) {
  const theme = useTheme();
  const { size = 24, color = 'white' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M23.91,10.35A9.88,9.88,0,0,0,22,5.56,13,13,0,0,0,12.87.31,11.13,11.13,0,0,0,3.12,4.22,12.92,12.92,0,0,0,.69,16.65c1.38,3.7,4.82,6.59,10.29,7a16.38,16.38,0,0,0,2.89,0,12.35,12.35,0,0,0,2.84-.61.37.37,0,0,0,.22-.45.35.35,0,0,0-.44-.22,11.25,11.25,0,0,1-2.69.53,14.92,14.92,0,0,1-2.75,0c-5-.5-8.19-3.17-9.41-6.6A11.87,11.87,0,0,1,4,5a10,10,0,0,1,8.72-3.48,12.12,12.12,0,0,1,8.42,4.7A8.93,8.93,0,0,1,23,10.47a12.19,12.19,0,0,1-.3,4.77,12.38,12.38,0,0,1-1.42,3.4,9.41,9.41,0,0,1-2.51,2.66.29.29,0,0,0-.08.42.31.31,0,0,0,.43.09A9.89,9.89,0,0,0,21.9,19a13,13,0,0,0,1.58-3.57A13,13,0,0,0,23.91,10.35Z"
          fill="#ff4a8c"
        ></path>
        <path
          d="M11.6,4a.31.31,0,0,0-.12-.6c-1.45.16-1.85,1-1.8,1.68a1.88,1.88,0,0,0,.68,1.25,2.33,2.33,0,0,0,1.24.54,2,2,0,0,0,2.05-1.09,1.72,1.72,0,0,0-.47-2.17.37.37,0,0,0-.5,0,.36.36,0,0,0,0,.5.88.88,0,0,1,.08,1.15,1,1,0,0,1-1.05.4,1.33,1.33,0,0,1-.89-.48c-.12-.16-.25-.31-.21-.49S11,4.16,11.6,4Z"
          fill="#191919"
        ></path>
        <path
          d="M10.58,17.93a.77.77,0,0,0,.2.43,3.87,3.87,0,0,0,2,.55,8.84,8.84,0,0,0,2.8-.18.87.87,0,0,0,.43-.49,2.83,2.83,0,0,0,.13-.7l.11-.67a1,1,0,0,0-.4-1,2.93,2.93,0,0,0-1.28-.4c-.19,0-.39,0-.55-.09a1.1,1.1,0,0,1,0-.18c0-1.05,0-3.41-.07-4.9a5.32,5.32,0,0,0-.2-1.34.57.57,0,0,0-.29-.33,5.72,5.72,0,0,0-1.92-.49,7.65,7.65,0,0,0-1.93.05.36.36,0,0,0-.31.39.35.35,0,0,0,.39.3,8.11,8.11,0,0,1,1.77,0c2,.27,1.57.53,1.59,1.31,0,1.41-.08,3.71-.11,4.85,0,.32,0,.55,0,.66a.59.59,0,0,0,.17.41,1.51,1.51,0,0,0,.75.36c.26.06.57.1.85.16s.29.16.38.17l-.1.6c0,.07,0,.22,0,.32a9,9,0,0,1-1.67.05,8.51,8.51,0,0,1-1.36-.14l-.22,0a2.35,2.35,0,0,0,0-.26c-.09-1.26-.09-4.17-.1-5.49,0-.31,0-.53,0-.64a.5.5,0,0,0-.14-.34,1.64,1.64,0,0,0-.88-.44c-.5-.09-1,.07-1.66-.08,0-.06-.09-.18-.1-.29a3.57,3.57,0,0,1,0-.86l0,0,0,0s0-.05,0-.05,0,0,0,0a.25.25,0,0,0,0-.19.3.3,0,0,0-.33-.26.31.31,0,0,0-.23.12,1.23,1.23,0,0,0-.13.48,3.63,3.63,0,0,0,.09,1.28.67.67,0,0,0,.38.42,11.24,11.24,0,0,0,1.8.2,1,1,0,0,1,.3.13,3.45,3.45,0,0,1,0,.38c0,1.06-.17,3.42-.18,4.93A9.72,9.72,0,0,0,10.58,17.93Z"
          fill="#191919"
        ></path>
      </g>
    </svg>
  );
}
