import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { nanoid } from 'nanoid';
import Stack from '@mui/material/Stack';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { getCourseBlueprint } from '../../services/admin/admin';
import ModuleDuoTone from '../../icons/ModuleDuoTone';
import SectionDuoTone from '../../icons/SectionDuoTone';
import LessonFlipDuoTone from '../../icons/LessonFlipDuoTone';
import EMonitorPencil from '../../icons/EClasroom';
import { IconButton } from '@mui/material';
import EditContent from '../../icons/EditContent';
import Dustbinopen from '../../icons/DustbinOpen';
import OpenEye from '../../icons/openEye';

const ListWrapper = ({ children }) => (
  <List
    sx={{
      width: '100%',
      pt: 0,
      maxWidth: 650,
      bgcolor: 'background.paper',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-22px',
        height: '100%',
        width: '2px',
        backgroundColor: '#E0E3E7'
      }
    }}
    component="nav"
    aria-labelledby="nested-list-subheader"
  >
    {children}
  </List>
);

const ListItemWrapper = ({ children, fun, selected = false }) => (
  <ListItemButton
    selected={selected}
    sx={{
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',

        width: '36px',
        height: '2px',

        top: '50%',
        left: '-22px',
        backgroundColor: '#E0E3E7'
      },
      py: 0,
      marginBottom: 1,
      border: '1px solid black',
      minHeight: 72,
      borderRadius: '6px'
    }}
    onClick={() => fun()}
  >
    {children}
  </ListItemButton>
);

const AddButton = ({ fun }) => (
  <AddCircleOutlineIcon
    onClick={() => fun()}
    sx={{
      position: 'absolute',
      cursor: 'pointer',
      left: '16px',
      bottom: '6px',
      fontSize: 15
    }}
  />
);

const ListContent = ({ editNode, removeNode, open, id, children, addNode, data, preview }) => {
  return (
    <>
      <ListItemIcon>
        {data.level === 1 ? (
          <SectionDuoTone />
        ) : data.level === 2 ? (
          <ModuleDuoTone />
        ) : data.level === 3 ? (
          <LessonFlipDuoTone />
        ) : (
          <EMonitorPencil />
        )}
      </ListItemIcon>
      <ListItemText
        pr={3}
        primary={data ? data.name + ' ' + (data.order + 1) : 'inbox'}
        secondary={
          <Tooltip placement="right" TransitionComponent={Zoom} title="Add" arrow>
            <span>{data.subName}</span>
          </Tooltip>
        }
      />
      <Stack direction="row" spacing={0.5} sx={{ pr: data.level > 2 ? 1 : 2 }}>
        <IconButton onClick={() => preview(id, data.level)}>
          <OpenEye color="" size={15} />
        </IconButton>
        <IconButton onClick={() => editNode(id, data.level)}>
          <EditContent size={15} />
        </IconButton>
        <IconButton onClick={() => removeNode(id)}>
          <Dustbinopen size={15} />
        </IconButton>
      </Stack>
      {data.level > 2 ? (
        ''
      ) : children.length ? (
        <>
          {open ? (
            <ExpandLess
              sx={{
                color: '#8c8c8c',
                fontSize: 20,
                '&:hover': {
                  color: 'black',
                  borderRadius: 2
                }
              }}
            />
          ) : (
            <ExpandMore
              sx={{
                color: '#8c8c8c',
                fontSize: 20,
                '&:hover': {
                  color: 'black',
                  borderRadius: 2
                }
              }}
            />
          )}
        </>
      ) : (
        <AddIcon
          sx={{
            color: '#8c8c8c',
            fontSize: 20,
            '&:hover': {
              color: 'black',
              borderRadius: 2
            }
          }}
          onClick={() => addNode(id, false, data.level)}
        />
      )}
    </>
  );
};

const Child = ({ id, open, selectedId, children, updateSelected, addNode, data, removeNode, editNode, preview }) => {
  return (
    <>
      <ListWrapper>
        <ListItemWrapper selected={id === selectedId} fun={() => updateSelected(id)}>
          <ListContent
            preview={preview}
            editNode={editNode}
            removeNode={removeNode}
            addNode={addNode}
            children={children}
            data={data}
            id={id}
            open={open}
          />
        </ListItemWrapper>

        <Collapse sx={{ pl: 5.5 }} in={open} timeout="auto" unmountOnExit>
          {(children ?? []).map((ch, index) => (
            <Child
              key={index}
              preview={preview}
              editNode={editNode}
              removeNode={removeNode}
              data={ch.data}
              addNode={addNode}
              selectedId={selectedId}
              updateSelected={updateSelected}
              {...ch}
            />
          ))}
        </Collapse>
      </ListWrapper>
      <AddButton fun={() => addNode(id, true, data.level)} />
    </>
  );
};

export default function NestedList({ treeData, addNodeFunc }) {
  //Main state object
  const [dataD, setDataD] = useState({
    id: nanoid(6),
    open: false,
    data: {
      name: 'Course',
      level: 0,
      order: 0
    },
    children: []
  });

  useEffect(() => {
    if (treeData) setDataD(treeData);
  }, [treeData]);

  //modal state
  const [open, setOpen] = React.useState(false);
  //selected list state
  const [selectedId, setselectedId] = useState();

  //state to edit particular list
  const [editId, setEditId] = useState();
  //level state to create or edit list
  const [editCreateLevel, setEditCreateLevel] = useState();
  // to check status for editing pr creating a list
  const [isEditing, setIsEditing] = useState(false);
  // misc data to add a new node
  const [addNodeData, setAddNodeData] = useState({ id: null, isParent: false });

  //preview node
  const [preview, setPreview] = useState(false);
  const [previewId, setPreviewId] = useState();
  const [previewLevel, setPreviewLevel] = useState();

  //close modal and reset all states
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsEditing(false);
    setEditCreateLevel();
    setEditId();
    setOpen(false);
    setAddNodeData({
      id: null,
      isParent: false
    });
    setPreview(false);
    setPreviewId();
    setPreviewLevel();
  };

  const updateSelectedHelper = (obj, id) => {
    if (obj.id === id) {
      if (obj.open) obj.open = false;
      else obj.open = true;
      return;
    }
    if (obj.children.size === 0) {
      return;
    }

    obj.children.forEach((ob) => updateSelectedHelper(ob, id));
  };

  const updateSelected = (id) => {
    setselectedId(id);
    setDataD((prev) => {
      const temp = { ...prev };
      updateSelectedHelper(temp, id);
      return temp;
    });
  };

  const helperRemoveNode = (obj, id) => {
    if (obj.children.length && obj.children.filter((item) => item.id === id).length) {
      obj.children = obj.children.filter((item) => item.id !== id);
      return;
    }
    if (obj.children.length === 0) {
      return;
    }

    obj.children.forEach((ob) => helperRemoveNode(ob, id));
  };

  const removeNode = (id) => {
    setDataD((prev) => {
      const temp = { ...prev };
      helperRemoveNode(temp, id);
      return temp;
    });
  };

  const addNode = (id, isParent, level) => {
    setAddNodeData({
      id: id,
      isParent: isParent
    });

    const levelValue = parseInt(level);

    const entityLevel = isParent ? levelValue : levelValue + 1;

    setEditCreateLevel(isParent ? levelValue : levelValue + 1);
    const tabValue =
      entityLevel === 0 ? 'course' : entityLevel === 1 ? 'section' : entityLevel === 2 ? 'module' : 'lesson';
    addNodeFunc(id, isParent, tabValue);
  };

  const helperAddNode = (obj, id, isParent, newNode, inc) => {
    if (isParent && obj.children.length && obj.children.filter((item) => item.id === id).length) {
      const tempArr = [...obj.children];
      tempArr.push({
        id: newNode,
        open: false,
        data: {
          name: inc === 1 ? 'Section' : inc === 2 ? 'Module' : 'Lesson',
          level: inc,
          order: tempArr.length ? tempArr[tempArr.length - 1].data.order + 1 : 0
        },
        children: []
      });

      obj.children = tempArr;
      return;
    } else if (!isParent && obj.id === id) {
      const tempArr = [...obj.children];
      tempArr.push({
        id: newNode,
        open: true,
        data: {
          name: inc === 1 ? 'Section' : inc === 2 ? 'Module' : 'Lesson',
          level: inc,
          order: tempArr.length ? tempArr[tempArr.length - 1].data.order + 1 : 0
        },
        children: []
      });

      obj.children = tempArr;
      obj.open = true;
      return;
    } else if (Object.keys(obj).length === 0) {
      obj = {
        id: nanoid(6),
        open: false,
        data: {
          name: 'Course',
          level: 0,
          order: 0
        },
        children: []
      };
      return obj;
    }
    if (obj.children.length === 0) {
      return;
    }

    obj.children.map((ob) => helperAddNode(ob, id, isParent, newNode, inc + 1));
  };

  //Modal function handlers - Create/Edit

  const handleAddNode = () => {
    //do API call create and get _id and create node
    const newNode = nanoid(6);
    setDataD((prev) => {
      const temp = { ...prev };
      helperAddNode(temp, addNodeData.id, addNodeData.isParent, newNode, 1);
      return temp;
    });

    //do API call to store tree status

    setselectedId(newNode);
    handleClose();
  };

  //Opens modal to edit a particular node
  const handleEditNode = (id, level) => {
    setIsEditing(true);
    setEditCreateLevel(level);
    setEditId(id);
    setOpen(true);
  };

  //Opens modal to preview a particular node, to user
  const previewNode = (id, level) => {
    setPreview(true);
    setPreviewId(id);
    setPreviewLevel(level);
    setOpen(true);
  };

  React.useEffect(() => {
    console.log('dataD', dataD);
  }, [dataD]);

  return (
    <>
      <List
        sx={{ width: '100%', maxWidth: 550, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton
          sx={{
            py: 0,
            border: '1px solid black',
            marginBottom: 1,
            minHeight: 72,
            borderRadius: '6px'
          }}
          selected={dataD.id === selectedId}
          onClick={() => {
            if (dataD.children.length) updateSelected(dataD.id);
          }}
        >
          <ListContent
            addNode={addNode}
            preview={previewNode}
            editNode={handleEditNode}
            removeNode={removeNode}
            data={dataD.data}
            children={dataD.children}
            id={dataD.id}
            open={dataD.open}
          />
        </ListItemButton>
        <Collapse sx={{ pl: 5.5, pt: 0 }} in={dataD.open} timeout="auto" unmountOnExit>
          {(dataD.children ?? []).map((ch, index) => (
            <Child
              key={index}
              editNode={handleEditNode}
              preview={previewNode}
              removeNode={removeNode}
              data={ch.data}
              addNode={addNode}
              selectedId={selectedId}
              updateSelected={updateSelected}
              {...ch}
            />
          ))}
        </Collapse>
      </List>
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <DialogTitle>
          {preview ? 'Preview' : isEditing ? 'Edit' : 'Create'}{' '}
          {(editCreateLevel || previewLevel) === 0
            ? 'Course'
            : (editCreateLevel || previewLevel) === 1
            ? 'Section'
            : (editCreateLevel || previewLevel) === 2
            ? 'Module'
            : 'Lesson'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isEditing || preview ? (
              <>
                {' '}
                Id - {editId || previewId} <br />
                Level - {editCreateLevel || previewLevel}
              </>
            ) : (
              'Create'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {!preview && (
            <Button
              onClick={() => {
                !isEditing ? handleAddNode() : handleClose();
              }}
            >
              {isEditing ? 'Update' : 'Create'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
