import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { createLesson } from '../../services/admin/admin';
import { lessonType, codeLanguageType, codeQuestionParagraphType } from '../../constants/constant';
import GeneralForm from '../generalForm/GeneralForm';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragDropAssessment from '../playground/dragdrop';

const fieldProps = {
  defaultValue: '',
  variant: 'outlined',
  fullWidth: true,
  size: 'small'
};

const lessonConfiguration = [
  {
    label: 'Lesson Name',
    value: 'lessonName',
    type: 'textField',
    fieldProps: fieldProps
  },
  {
    label: 'Sequence Id',
    value: 'sequenceId',
    type: 'textField',
    fieldProps: {
      ...fieldProps,
      type: 'number'
    }
  },
  {
    label: 'Weightage',
    value: 'weightage',
    type: 'textField',
    fieldProps: {
      ...fieldProps,
      type: 'number'
    }
  },
  {
    label: 'Lesson Type',
    value: 'lessonType',
    type: 'multiselect',
    fieldProps: fieldProps,
    options: lessonType
  }
];

const AddLessonComponent = ({ treeBlueprint, courseId, eventData, setFetchBlueprintValue }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [lessonState, setLessonState] = useState({
    lessonName: '',
    sequenceId: '',
    weightage: '',
    lessonType: ''
  });
  const [disableSubmit, setdDisableSubmit] = useState(false);
  const [taskOptions, setTaskOptions] = useState([]);
  const [openDragndropModal, setOpenDragndropModal] = useState(false);

  const mcqOptionsConfigs = [
    {
      label: 'Option Index',
      value: 'optionIndex',
      type: 'textField',
      fieldProps: {
        ...fieldProps,
        type: 'number'
      }
    },
    {
      label: 'Option Value',
      value: 'option',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Option Code',
      value: 'code',
      type: 'codemodal',
      parentValue: 'optionCode',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Option Code language',
      value: 'language',
      type: 'multiselect',
      parentValue: 'optionCode',
      fieldProps: {
        ...fieldProps
      },
      options: codeLanguageType
    },
    {
      label: 'Show line number',
      value: 'showLineNumber',
      type: 'textField',
      parentValue: 'optionCode',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Line highlight',
      value: 'lineHighlight',
      type: 'textField',
      parentValue: 'optionCode',
      fieldProps: {
        ...fieldProps
      }
    }
  ];

  const mcqSolutionsConfigs = [
    {
      label: 'MCQ Solution',
      value: 'solution',
      type: 'textField',
      fieldProps: {
        ...fieldProps,
        type: 'number'
      }
    }
  ];

  const dragnDropTaskConfigs = [
    {
      label: 'Tasks Id',
      value: 'id',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Tasks Content',
      value: 'content',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    }
  ];

  const CodeQuestionParagraphConfigs = [
    {
      label: 'Paragraph Type',
      value: 'type',
      type: 'multiselect',
      fieldProps: {
        ...fieldProps
      },
      options: codeQuestionParagraphType
    },
    {
      label: 'Theme',
      value: 'theme',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Content Text',
      value: 'content',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Content Code',
      value: 'content',
      type: 'codemodal',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Show line number',
      value: 'showLineNumber',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Line highlight',
      value: 'lineHighlight',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    }
  ];

  const CodeQuestionTestcasesConfigs = [
    {
      label: 'input file upload',
      value: 'inputFile',
      type: 'fileUpload',
      multiple: false,
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Output file upload',
      value: 'outputFile',
      type: 'fileUpload',
      multiple: false,
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Testcase sequence',
      value: 'testcase',
      type: 'textField',
      fieldProps: {
        ...fieldProps,
        type: 'number'
      }
    },
    {
      label: 'CPU limit',
      value: 'cpuTimeLimit',
      type: 'textField',
      fieldProps: {
        ...fieldProps,
        type: 'number'
      }
    },
    {
      label: 'Memory limit',
      value: 'memoryLimit',
      type: 'textField',
      fieldProps: {
        ...fieldProps,
        type: 'number'
      }
    },
    {
      label: 'Stack limit',
      value: 'stackLimit',
      type: 'textField',
      fieldProps: {
        ...fieldProps,
        type: 'number'
      }
    },
    {
      label: 'Time Alloted',
      value: 'timeAllotted',
      type: 'textField',
      fieldProps: {
        ...fieldProps,
        type: 'number'
      }
    }
  ];

  const dragnDropColumnConfigs = [
    {
      label: 'Column Id',
      value: 'id',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Column Title',
      value: 'title',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Tasks Ids',
      value: 'taskIds',
      parentValue: 'columns',
      isParentArray: true,
      type: 'multiSelectCheck',
      useGlobalOptions: true,
      options: [],
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Ans Tasks Ids',
      value: 'ansTaskIds',
      parentValue: 'columns',
      isParentArray: true,
      type: 'multiSelectCheck',
      useGlobalOptions: true,
      options: [],
      fieldProps: {
        ...fieldProps
      }
    },
    {
      label: 'Ans Hint',
      value: 'ansHint',
      type: 'textField',
      fieldProps: {
        ...fieldProps
      }
    }
  ];

  useEffect(() => {
    if (lessonState.tasks) {
      setTaskOptions(lessonState.tasks.map((task) => task.id));
    }
  }, [lessonState]);

  const configuration = useMemo(() => {
    return [
      ...lessonConfiguration,
      {
        label: 'Video URL',
        value: 'url',
        parentValue: 'video',
        type: 'textField',
        isHidden: lessonState.lessonType !== 'video',
        fieldProps: fieldProps
      },
      {
        label: 'Notion Database ID',
        value: 'notionDatabaseId',
        type: 'textField',
        isHidden: lessonState.lessonType !== 'content',
        parentValue: 'content',
        fieldProps: fieldProps
      },
      {
        label: 'Notion URL',
        value: 'notionUrl',
        parentValue: 'content',
        type: 'textField',
        isHidden: lessonState.lessonType !== 'content',
        fieldProps: fieldProps
      },
      {
        label: 'Question',
        value: 'question',
        parentValue: 'mcq',
        type: 'textField',
        isHidden: lessonState.lessonType !== 'mcq',
        fieldProps: fieldProps
      },
      {
        label: 'Code Block',
        value: 'code',
        parentValue: 'mcqCode',
        type: 'codemodal',
        isHidden: lessonState.lessonType !== 'mcq',
        fieldProps: fieldProps
      },
      {
        label: 'Code language',
        value: 'language',
        type: 'multiselect',
        parentValue: 'mcqCode',
        fieldProps: {
          ...fieldProps
        },
        options: codeLanguageType,
        isHidden: lessonState.lessonType !== 'mcq'
      },
      {
        label: 'Show line number',
        value: 'showLineNumber',
        type: 'textField',
        parentValue: 'mcqCode',
        fieldProps: {
          ...fieldProps
        },
        isHidden: lessonState.lessonType !== 'mcq'
      },
      {
        label: 'Line highlight',
        value: 'lineHighlight',
        type: 'textField',
        parentValue: 'mcqCode',
        fieldProps: {
          ...fieldProps
        },
        isHidden: lessonState.lessonType !== 'mcq'
      },
      {
        label: 'MCQ Options',
        value: 'options',
        parentValue: 'mcqCode',
        type: 'nestedArray',
        arrayConfigs: mcqOptionsConfigs,
        isHidden: lessonState.lessonType !== 'mcq',
        heading: 'Add MCQ Options'
      },
      {
        label: 'MCQ Solutions',
        value: 'solutions',
        parentValue: 'mcq',
        type: 'nestedArray',
        arrayConfigs: mcqSolutionsConfigs,
        isHidden: lessonState.lessonType !== 'mcq',
        heading: 'Add MCQ Solutions'
      },
      {
        label: 'Drag and Drop Tasks',
        value: 'tasks',
        parentValue: 'dragnDrop',
        type: 'nestedArray',
        arrayConfigs: dragnDropTaskConfigs,
        isHidden: lessonState.lessonType !== 'dragndrop',
        heading: 'Add Tasks for drag and drop'
      },
      {
        label: 'Drag and Drop Columns',
        value: 'columns',
        parentValue: 'dragnDrop',
        type: 'nestedArray',
        arrayConfigs: dragnDropColumnConfigs,
        isHidden: lessonState.lessonType !== 'dragndrop',
        heading: 'Add Columns for drag and drop'
      },
      {
        label: 'Columns order',
        value: 'columnOrder',
        type: 'textField',
        parentValue: 'dragnDrop',
        fieldProps: {
          ...fieldProps
        },
        isHidden: lessonState.lessonType !== 'dragndrop'
      },
      {
        label: 'Preview',
        value: '',
        type: 'Button',
        parentValue: 'dragnDrop',
        onClickHandler: () => {
          setOpenDragndropModal(true);
        },
        fieldProps: {
          ...fieldProps
        },
        isHidden: lessonState.lessonType !== 'dragndrop'
      },
      {
        label: 'Code language',
        value: 'language',
        type: 'multiselect',
        parentValue: 'code',
        fieldProps: {
          ...fieldProps
        },
        options: codeLanguageType,
        isHidden: lessonState.lessonType !== 'code'
      },
      {
        label: 'Starter Code',
        value: 'starterCode',
        parentValue: 'code',
        type: 'codemodal',
        isHidden: lessonState.lessonType !== 'code',
        fieldProps: fieldProps
      },
      {
        label: 'Solution Code',
        value: 'solutionCode',
        parentValue: 'code',
        type: 'codemodal',
        isHidden: lessonState.lessonType !== 'code',
        fieldProps: fieldProps
      },
      {
        label: 'Starter Helper Code',
        value: 'starterHelperCode',
        parentValue: 'code',
        type: 'codemodal',
        isHidden: lessonState.lessonType !== 'code',
        fieldProps: fieldProps
      },
      {
        label: 'Hint Url',
        value: 'hintUrl',
        parentValue: 'code',
        type: 'textField',
        isHidden: lessonState.lessonType !== 'code',
        fieldProps: fieldProps
      },
      {
        label: 'Question Title',
        value: 'title',
        parentValue: 'question',
        type: 'textField',
        isHidden: lessonState.lessonType !== 'code',
        fieldProps: fieldProps
      },
      {
        label: 'Question Heading',
        value: 'heading',
        parentValue: 'question',
        type: 'textField',
        isHidden: lessonState.lessonType !== 'code',
        fieldProps: fieldProps
      },
      {
        label: 'Question Paragraph',
        value: 'paragraphs',
        parentValue: 'question',
        type: 'nestedArray',
        arrayConfigs: CodeQuestionParagraphConfigs,
        isHidden: lessonState.lessonType !== 'code',
        heading: 'Add Paragraphs for Code question'
      },
      {
        label: 'Question Testcases',
        value: 'testCases',
        parentValue: 'code',
        type: 'nestedArray',
        arrayConfigs: CodeQuestionTestcasesConfigs,
        isHidden: lessonState.lessonType !== 'code',
        heading: 'Add Paragraphs for Code question'
      }
    ];
  }, [lessonState.lessonType]);

  const deletMcqKeys = () => {
    delete lessonState.mcq;
    delete lessonState.mcqCode;
    delete lessonState.options;
    delete lessonState.solutions;
  };

  const deleteDragnDropKeys = () => {
    delete lessonState.dragnDrop;
    delete lessonState.columns;
    delete lessonState.tasks;
  };

  const deleteContentKeys = () => {
    delete lessonState.content;
  };

  const deleteCodeKeys = () => {
    delete lessonState.code;
    delete lessonState.question;
    delete lessonState.paragraph;
    delete lessonState.testCases;
  };

  const deleteVideoKeys = () => {
    delete lessonState.video;
  };

  const deleteStateKeys = () => {
    if (lessonState.lessonType === 'video') {
      deletMcqKeys();
      deleteContentKeys();
      deleteDragnDropKeys();
      deleteCodeKeys();
    } else if (lessonState.lessonType === 'content') {
      deletMcqKeys();
      deleteVideoKeys();
      deleteDragnDropKeys();
      deleteCodeKeys();
    } else if (lessonState.lessonType === 'mcq') {
      deleteContentKeys();
      deleteVideoKeys();
      deleteDragnDropKeys();
      deleteCodeKeys();
    } else if (lessonType === 'dragndrop') {
      deleteContentKeys();
      deleteVideoKeys();
      deletMcqKeys();
      deleteCodeKeys();
    } else if (lessonType === 'code') {
      deletMcqKeys();
      deleteContentKeys();
      deleteDragnDropKeys();
      deleteVideoKeys();
    }
  };

  const formDragnDropData = (lessonData) => {
    const tasksObj = {};
    lessonData.tasks &&
      lessonData.tasks.forEach((task) => {
        tasksObj[task.id] = task;
      });
    const columnObj = {};
    lessonData.columns &&
      lessonData.columns.forEach((column) => {
        columnObj[column.id] = column.taskIds ? column : { ...column, taskIds: [] };
      });
    const dragDropObj = {
      tasks: tasksObj,
      columns: columnObj,
      columnOrder: lessonData.dragnDrop
        ? lessonData.dragnDrop.columnOrder.split(',').map((str) => str.trim()) || []
        : []
    };

    return dragDropObj;
  };

  const onSubmit = () => {
    deleteStateKeys();
    setLessonState(lessonState);
    let lessonStateMcq;
    let lessonPayload;
    if (lessonState.lessonType === 'mcq') {
      lessonStateMcq = {
        ...lessonState,
        mcq: {
          ...lessonState.mcq,
          mcqCode: lessonState.mcqCode,
          solutions: lessonState.solutions.map((sol) => sol.solution),
          options: lessonState.options
        }
      };
      delete lessonStateMcq.mcqCode;
      delete lessonStateMcq.options;
      delete lessonStateMcq.solutions;
      lessonPayload = {
        ...lessonStateMcq,
        courseId: courseId,
        treeDataPayload: {
          data: treeBlueprint.data.treeData,
          event: eventData
        }
      };
    } else if (lessonState.lessonType === 'dragndrop') {
      const dragnDropData = formDragnDropData(lessonState);
      lessonPayload = {
        ...lessonState,
        dragnDrop: dragnDropData,
        courseId: courseId,
        treeDataPayload: {
          data: treeBlueprint.data.treeData,
          event: eventData
        }
      };
      delete lessonPayload.tasks;
      delete lessonPayload.columns;
    } else if (lessonState.lessonType === 'code') {
      const codeObj = Object.assign({}, lessonState.code);
      delete lessonState.code;
      const lessonStateCode = {
        ...lessonState,
        code: {
          [codeObj.language]: {
            ...codeObj,
            testCases: lessonState.testCases,
            question: {
              ...lessonState.question,
              paragraphs: lessonState.paragraphs
            }
          }
        }
      };

      delete lessonStateCode.testCases;
      delete lessonStateCode.question;
      delete lessonStateCode.paragraphs;

      lessonPayload = {
        ...lessonStateCode,
        courseId: courseId,
        treeDataPayload: {
          data: treeBlueprint.data.treeData,
          event: eventData
        }
      };
    } else {
      lessonPayload = {
        ...lessonState,
        courseId: courseId,
        treeDataPayload: {
          data: treeBlueprint.data.treeData,
          event: eventData
        }
      };
    }

    createLesson(lessonPayload).then((res) => {
      enqueueSnackbar(res.data.message, {
        variant: res.data.message !== 'Lesson created successfully!' ? 'error' : 'success'
      });
      if (res.data.message === 'Lesson created successfully!') {
        setFetchBlueprintValue((prevState) => prevState + 1);
      }
      onReset();
    });
  };

  const onReset = () => {
    setLessonState({ lessonName: '', sequenceId: '', weightage: '', lessonType: '' });
  };

  return (
    <>
      <GeneralForm
        formDetails={lessonState}
        setFormDetails={setLessonState}
        fieldConfigs={configuration}
        formTitle={'Add Lesson Details'}
        onSubmitForm={onSubmit}
        onResetForm={onReset}
        disableSubmitButton={disableSubmit}
        gridSize={{
          sx: 12,
          md: 12,
          lg: 6
        }}
        globalOptions={taskOptions}
        setGlobalOptions={setTaskOptions}
      />
      <Dialog open={openDragndropModal} onClose={() => setOpenDragndropModal(false)} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>
          Add Code
          <IconButton
            aria-label="close"
            onClick={() => setOpenDragndropModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DragDropAssessment
            studentLessonDetails={{
              dragnDrop: formDragnDropData(lessonState)
            }}
            preview={true}
            coursePref={{}}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddLessonComponent;
