import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function StudentCoding(props) {
  const theme = useTheme();
  const { size = 24, color = theme.palette.primary.main } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M14.31,19.62a3.65,3.65,0,0,1-2.38.5,4.9,4.9,0,0,1-1-.21,4.05,4.05,0,0,1-.92-.42,2.84,2.84,0,0,1-1.43-1.85h0c0-.29-.08-.56-.08-.86a10.55,10.55,0,0,0-.1-2.27,2.14,2.14,0,0,0-.6-1.11.82.82,0,0,0-.51-.2,4.14,4.14,0,0,0-.61.08l-1.94,0H3.92s0,0,0,0a6.49,6.49,0,0,1-.45-.87,3.49,3.49,0,0,1-.22-.9,2.82,2.82,0,0,1,0-.71,2.76,2.76,0,0,1,.19-.68A2.9,2.9,0,0,1,4.7,8.53a3.23,3.23,0,0,1,2.92,0c.51.23,3.45,1.79,1.49,4.56a.34.34,0,0,0,.1.47,5.52,5.52,0,0,1,.89,1,5.75,5.75,0,0,1,.62,1.17A8.85,8.85,0,0,1,11.1,17a2.85,2.85,0,0,0,.51,1,1.76,1.76,0,0,0,1.87.54,3.88,3.88,0,0,0,.45-.14l.4-.19a.38.38,0,0,0-.32-.7l-.65.13a.81.81,0,0,1-.81-.34,2.66,2.66,0,0,1-.28-.85,5.34,5.34,0,0,0-.41-1.16,6.21,6.21,0,0,0-1-1.26,5.4,5.4,0,0,0-.92-.67s.08,0,.11-.07c1.62-2.3.21-4.74-1.88-5.79a4.41,4.41,0,0,0-3.45-.31A3.74,3.74,0,0,0,2.44,9.75a4.1,4.1,0,0,0-.14.92,3.29,3.29,0,0,0,.07.94,4.49,4.49,0,0,0,.39,1.08,5.87,5.87,0,0,0,.37.59,9.28,9.28,0,0,0-1,.08,3.63,3.63,0,0,0-.84.2,1.12,1.12,0,0,0-.49.36,2.69,2.69,0,0,0-.41.85,9.85,9.85,0,0,0-.25,1.74,15.41,15.41,0,0,0,.15,4.37,6.92,6.92,0,0,0,1.13,2.75.35.35,0,0,0,.48.08.34.34,0,0,0,.08-.47,5.93,5.93,0,0,1-.84-2.5,14.06,14.06,0,0,1,.13-4.07c.06-.37.13-.88.25-1.34a2,2,0,0,1,.24-.59h0c-.09-.16.4-.08.55-.1a7.28,7.28,0,0,1,.93-.07l1.51,0c2.72-.1,2.47-.31,2.6.44a14.56,14.56,0,0,1,.12,1.75,14.18,14.18,0,0,0,.43,2.95,18.33,18.33,0,0,0,.92,2.8.38.38,0,1,0,.72-.27,19,19,0,0,1-.59-2.14,3.5,3.5,0,0,0,.39.38,3.93,3.93,0,0,0,1.24.63,4.09,4.09,0,0,0,1.35.2,4.36,4.36,0,0,0,2.9-1.12.38.38,0,0,0,.07-.54A.39.39,0,0,0,14.31,19.62Z"
          fill="#191919"
        ></path>
        <path
          d="M14.19,4.28a.39.39,0,0,0,.09-.54.41.41,0,0,0-.55-.09L11.18,4.87a6.9,6.9,0,0,0-1,.62A.75.75,0,0,0,9.93,6a.7.7,0,0,0,.18.57c.13.15.53.48.56.51l2.24,1.84a.34.34,0,0,0,.48,0,.34.34,0,0,0,0-.48L11.56,6.15l-.1-.06.37-.16Z"
          fill="#191919"
        ></path>
        <path
          d="M17.13,8.33a.38.38,0,0,0-.09.53.4.4,0,0,0,.54.09l2.55-1.22a6,6,0,0,0,1-.61.77.77,0,0,0,.25-.48.68.68,0,0,0-.18-.56,6.48,6.48,0,0,0-.56-.51L18.4,3.72a.34.34,0,1,0-.48.48l1.83,2.25.1.07a2.67,2.67,0,0,0-.37.16Z"
          fill="#191919"
        ></path>
        <path
          d="M17.29,2.36a.33.33,0,0,0-.44.19c-.38.65-.76,1.28-1.08,2-.14.29-.25.59-.36.89s-.2.6-.28.91c-.19.73-.32,1.45-.46,2.19a.38.38,0,0,0,.26.48.39.39,0,0,0,.48-.26c.28-.68.59-1.34.87-2,.12-.29.24-.58.35-.87a9.24,9.24,0,0,0,.29-.89c.21-.71.36-1.42.56-2.13A.35.35,0,0,0,17.29,2.36Z"
          fill="#191919"
        ></path>
        <path
          d="M23.9,5c-.59-5.38.81-3.65-4.16-4.36A40.17,40.17,0,0,0,15,.23c-.8,0-1.59,0-2.38.07S11,.47,10.23.59,8.77.76,8,.88C6.07,1.21,6,.56,5.93,3a4.7,4.7,0,0,0,0,.65A4.34,4.34,0,0,0,6,4.24c.1.5.28,1,.38,1.45A.34.34,0,0,0,6.77,6a.33.33,0,0,0,.32-.35,11,11,0,0,0,.18-1.74,5.67,5.67,0,0,0-.11-1C7.11,2.55,7,2.2,7,1.84a9,9,0,0,1,1.16-.15c.74-.05,1.48-.05,2.2-.08s1.55-.05,2.33-.06,1.55,0,2.32,0c1.55,0,3.09,0,4.65.12.85.06,1.75,0,2.62,0l.14,0c.06.47.13.94.16,1.42s0,1,.06,1.56.05,1,.08,1.56c.11,1.6.23,3.19.27,4.74a.33.33,0,0,0,.08.18,20.28,20.28,0,0,1-4.39.15l-1.82-.06c-.41,0-.82,0-1.23,0a25.39,25.39,0,0,0-3.34.29.34.34,0,1,0,.05.67c.75,0,1.49,0,2.22.12a6.33,6.33,0,0,0-.17.78,3.39,3.39,0,0,0,0,.55c0,.18,0,.37,0,.55,0,.44.11.85.15,1.28a.39.39,0,0,0,.35.42.37.37,0,0,0,.41-.35c.12-.44.23-.86.31-1.31a3.43,3.43,0,0,0,0-.56,2.63,2.63,0,0,0,0-.56,5.75,5.75,0,0,0-.15-.72l.77.07a25.88,25.88,0,0,0,2.64.09,15.91,15.91,0,0,0,4.45-.68.38.38,0,0,0,.29-.46c0-.06-.06-.09-.09-.13a.35.35,0,0,0,.23-.32c.11-1.17.24-2.38.26-3.6A17.8,17.8,0,0,0,23.9,5Z"
          fill="#ff4a8c"
        ></path>
        <path
          d="M22.55,17.85A1.4,1.4,0,0,0,22,17a1.52,1.52,0,0,0-.76-.23,9,9,0,0,0-1.21.1l-3.57.5a.33.33,0,0,0-.29.38.33.33,0,0,0,.38.29L20.17,18c.17,0,.46,0,.74,0,.87,0,.37-.12.47,1.29.09,1,.88,1-.94,1.06l-3.84.29a.39.39,0,0,0-.37.4.38.38,0,0,0,.4.37l3.79.06a6.06,6.06,0,0,0,1.35,0,1.55,1.55,0,0,0,.79-.39,1.15,1.15,0,0,0,.23-.71,10.69,10.69,0,0,0-.12-1.17A7.78,7.78,0,0,0,22.55,17.85Z"
          fill="#191919"
        ></path>
      </g>
    </svg>
  );
}
