// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
//icons
import EMonitorPencil from '../../icons/EClasroom';
import Student from '../../icons/Student';
import Teacher from '../../icons/Teacher';
// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Courses',
        path: PATH_DASHBOARD.admin.courses,
        icon: <EMonitorPencil />,
        info: <Label color="error">2</Label>
      },
      { title: 'Students', path: PATH_DASHBOARD.admin.students, icon: <Student /> },
      {
        title: 'Mentors',
        path: PATH_DASHBOARD.admin.mentors,
        icon: <Teacher />
      }
    ]
  }
];

export default sidebarConfig;
