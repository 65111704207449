import React from 'react';
import styled from 'styled-components';
import Task from './task';
import { Droppable } from 'react-beautiful-dnd';

const Container = styled.div`
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  margin: 0.1rem;
`;
const TaskList = styled.div`
  transition: background-color 0.2s;
  background-color: ${(props) => (props.isDraggingOver ? 'rgba(25, 104, 107, 0.12)' : 'white')};
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
`;

const Column = (props) => {
  const { column, tasks } = props;

  return (
    <Container>
      <Droppable droppableId={column.id} type="TASK" direction="horizontal">
        {(provided, snapshot) => (
          <TaskList innerRef={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
            {tasks &&
              tasks.map((task, index) => {
                return <Task key={task.id} task={task} index={index} />;
              })}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  );
};

export default Column;
