import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function EClasroom(props) {
  const theme = useTheme();
  const { size = 25, color = 'white' } = props;
  return (
    <svg height={size} width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <path
          d="M12.11,5.64c1,0,1.89,0,2.83.06.62,0,1.23.09,1.86.17a3.42,3.42,0,0,1,1,.29,1.22,1.22,0,0,1,.63.55,1.5,1.5,0,0,1,.15.48c.06.31.08.63.11.92l.3,3.05c.05.52.21,1.18.27,1.81a2.94,2.94,0,0,1-.07,1.08.31.31,0,1,0,.59.21,3.17,3.17,0,0,0,.19-1c0-.73-.12-1.53-.13-2.16L19.72,8a9.2,9.2,0,0,0-.1-1,3,3,0,0,0-.24-.77,2.12,2.12,0,0,0-.89-.89,3.94,3.94,0,0,0-1.61-.44c-.65,0-1.29,0-1.93,0-1,0-1.91.11-2.87.19a.26.26,0,0,0-.26.28A.27.27,0,0,0,12.11,5.64Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M4.56,12c0,.38,0,.77.05,1.15s.07.88.13,1.33a6,6,0,0,0,.12.61.44.44,0,0,0,.69.06,3.85,3.85,0,0,1,0-.48c0-.53.1-1.06.12-1.57s0-.79-.1-1.18c-.09-.56-.23-1.09-.35-1.64-.29-.25-.56-.07-.62.32C4.56,11.15,4.55,11.37,4.56,12Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M10.5,4.81l-.51-1c-.08-.13-.15-.26-.24-.39a3.18,3.18,0,0,0-.27-.37c-.23-.28-.48-.51-.73-.81h0C9.32.9,9.48.64,9.49.52A.32.32,0,0,0,9.18.2,5.61,5.61,0,0,0,8,.28c-.55.08-1.09.23-1.6.33L5,.89,4.2,1a2.91,2.91,0,0,0-1.13.49A4.64,4.64,0,0,0,2,2.89l-.31.58L1.06,4.7c-.22.48-.44,1-.65,1.45-.14.33-.28.66-.39,1a.27.27,0,0,0,.13.34c0,.57-.1,1.11-.09,1.67,0,.33.05.66.09,1,.07.49.17,1,.23,1.47a.32.32,0,0,0,.31.32A.32.32,0,0,0,1,11.62a14.94,14.94,0,0,0,.15-1.5,4.42,4.42,0,0,0,0-.63c0-.21,0-.43-.08-.63A8.67,8.67,0,0,0,.86,8c.33-.12.67-.23,1-.32l.42-.09c.1.21.22.42.34.62s.47.66.71,1c.08.11.22.36.35.52a.94.94,0,0,0,.24.19A3.87,3.87,0,0,0,6.3,9.81,6.48,6.48,0,0,0,8.9,7.87a5.56,5.56,0,0,0,1-2.42.32.32,0,0,0-.26-.37.31.31,0,0,0-.36.25A5.54,5.54,0,0,1,8.2,7.49,4.24,4.24,0,0,1,6.35,8.86a4,4,0,0,1-1,.26c-.7.09-.81.07-1.35-.48-.26-.26-.54-.54-.78-.83-.1-.12-.18-.25-.27-.37C3.59,7.29,4.28,7.15,5,7c.34-.12.85-.22,1.26-.4a2.1,2.1,0,0,0,.62-.38A3.93,3.93,0,0,0,7.65,5c.29-.6.47-1.26.7-1.82L8.41,3a4.79,4.79,0,0,0,.21.57A3.7,3.7,0,0,0,8.87,4c.1.13.2.26.31.38.25.28.53.51.82.78a.31.31,0,0,0,.44.06A.32.32,0,0,0,10.5,4.81Zm-2.92-2c-.27.52-.53,1.15-.84,1.71a3.27,3.27,0,0,1-.64.89,1.34,1.34,0,0,1-.46.25c-.36.13-.75.23-1,.34-1,.39-2,.65-2.93,1S.52,7.49,1,6.44A12.81,12.81,0,0,1,1.8,5.1L2.55,4l.35-.54a5.94,5.94,0,0,1,.67-.94,1.77,1.77,0,0,1,.88-.44l.77-.21,1.41-.4c.5-.15,1-.34,1.54-.47L8.58.9Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M18.89,14.53a4.09,4.09,0,0,0-.61,0c-.43,0-.85.13-1.28.16l-2.21,0c-.68,0-1.37.07-2,.13-1,.08-2,.2-3.05.28-.62.07-1.24.14-1.87.19-.41,0-.82.06-1.24.07s-1.63-.23-1.85.08c-1,1.43-1,1.83-2.36,3.82a11.77,11.77,0,0,0-.7,1,2.13,2.13,0,0,0-.25,1,2.09,2.09,0,0,0,1.25,1.95,7.94,7.94,0,0,0,3,.5c1.47,0,3,.08,4.43.09,1,0,2,0,3-.07s1.73-.16,2.6-.23c.36,0,.72,0,1.08,0a.32.32,0,0,0,0-.64H15.64c-.86,0-1.72.1-2.58.1s-1.47,0-2.2,0c-1.71-.06-3.43-.16-5.15-.24a7.09,7.09,0,0,1-2.59-.36,1.19,1.19,0,0,1-.76-1.05,1.2,1.2,0,0,1,.12-.56c.16-.35.4-.69.58-1,.51-.87,1.11-1.74,1.62-2.64a12.12,12.12,0,0,0,.57-1.14l.33.07c.33,0,.67.06,1,.08s.85.05,1.28.05c.64,0,1.27,0,1.91,0S11.13,16,11.82,16c1-.09,2-.23,3-.34l2.2-.13c.44-.06.86-.19,1.3-.27a2.76,2.76,0,0,1,.51-.05.32.32,0,0,0,0-.64Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M24,21.12a5,5,0,0,0-.67-1.67c-.3-.52-.67-1-.91-1.37l-.64-.9-1-1.12-1.1-1.2a.26.26,0,0,0-.38-.06.28.28,0,0,0-.07.39l1,1.34.83,1.14.52.9c.22.39.52.88.81,1.4a4.45,4.45,0,0,1,.53,1.33,1,1,0,0,1-.64,1.18,3.29,3.29,0,0,1-.44.16,3.43,3.43,0,0,1-.46.11,6.61,6.61,0,0,1-1,.08c-.83,0-1.66-.08-2.48,0a.32.32,0,1,0,0,.63c.81,0,1.62.15,2.44.2a7,7,0,0,0,1.12,0l.59-.09a3,3,0,0,0,.58-.18A1.89,1.89,0,0,0,24,21.12Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M13.64,19.69c-.49,0-1,0-1.47,0-.74.08-1.44.27-2.17.37a.28.28,0,0,0-.27.29.28.28,0,0,0,.28.27c.62.06,1.23.13,1.86.13.37,0,.73,0,1.1-.05l.72-.1,1.49-.37A.32.32,0,0,0,15.5,20a.31.31,0,0,0-.31-.32Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
