import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function UploadFolder(props) {
  const theme = useTheme();
  const { size = 24 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M22.67,9.52a3.3,3.3,0,0,0-1.2-.15c-.93,0-2.14.1-2.43.12a.3.3,0,0,0-.27.3.29.29,0,0,0,.29.27c.26,0,1.21,0,2.05,0a3.83,3.83,0,0,1,1.14.15.6.6,0,0,1,.24.46,3.54,3.54,0,0,1,0,1.17,22.11,22.11,0,0,1-.77,3.18,32,32,0,0,1-1.07,3.5c0-1.4-.13-2.81-.21-3.57,0-.27-.06-.47-.08-.57a.55.55,0,0,0-.17-.3,7.3,7.3,0,0,0-3.4-.68c-2.67-.12-6.18.07-7.52.15-.2-.9-.6-2.72-.85-3.55a3,3,0,0,0-.18-.48A.62.62,0,0,0,8,9.31a6.7,6.7,0,0,0-2.13-.13c-1.42.08-3.27.35-4.41.47a.33.33,0,0,0-.3.35.33.33,0,0,0,.34.3c1.32,0,3.59-.07,5-.05.39,0,.7,0,.88,0a.78.78,0,0,0,0,.14c.42,1.08,1.41,3.88,1.41,3.88a.29.29,0,0,0,.35.2.28.28,0,0,0,.19-.23c1.36-.05,4.78-.16,7.37,0a8.77,8.77,0,0,1,2.73.45c0,.09.06.23.07.38a45.35,45.35,0,0,1,.09,5.15,12,12,0,0,1-.18,1.64c0,.11-.05.24-.07.32a2.11,2.11,0,0,1-.38.1c-.52.09-1.35.16-2.36.24C13.1,22.8,7.38,23,4.56,23H3.24a46.2,46.2,0,0,1-1-4.7c-.51-2.89-1-6-1.14-7.67a.28.28,0,0,0-.32-.25.28.28,0,0,0-.25.31c.14,1.64.5,4.83.94,7.74a41.19,41.19,0,0,0,1,5.14.5.5,0,0,0,.43.34c.11,0,.34,0,.67,0C4.66,24,7,24,9.62,24c3.47-.08,7.34-.29,9.13-.53A3.31,3.31,0,0,0,20,23.09a1.41,1.41,0,0,0,.42-.71,6.8,6.8,0,0,0,.18-1.15.14.14,0,0,1,0-.07c.65-1.95,1.61-3.86,2.17-5.82a16.24,16.24,0,0,0,.74-3.95A2,2,0,0,0,22.67,9.52Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M12.06,11.29a1.49,1.49,0,0,0,.42.72c.61.51.86-.21,1-.95l.28-1.17A6,6,0,0,1,14.4,8.4a7.5,7.5,0,0,1,1-1.31c.21-.2.48-.49.77-.73l.2-.14c.09.13.22.26.24.29.23.43.46.87.71,1.3s.35.57.54.84a.34.34,0,0,0,.41.1l0,0a.31.31,0,0,0,.37-.27A17.9,17.9,0,0,1,19.2,6.7c.29-.91.65-1.79,1-2.67.09-.24.2-.49.28-.74s.1-.37.14-.56.05-.37,0-.5a.52.52,0,0,0-.21-.38A1.43,1.43,0,0,0,20,1.63c-.21-.08-.49-.13-.63-.18L16.47.73C16,.59,15.6.46,15.15.35S14.26.14,13.8.06h0a.28.28,0,0,0-.28,0,.28.28,0,0,0-.13.38l1,2.19a2.51,2.51,0,0,0,.22.51l-.24.1a9.28,9.28,0,0,0-.85.67,5.47,5.47,0,0,0-.51.56,6.27,6.27,0,0,0-1.12,2.69A9.49,9.49,0,0,0,11.83,10,7.56,7.56,0,0,0,12.06,11.29Zm.86-4A5.84,5.84,0,0,1,13.74,5a4.55,4.55,0,0,1,.49-.58,6.4,6.4,0,0,1,.62-.53c.06,0,.3-.19.42-.3a.6.6,0,0,0,.2-.3.6.6,0,0,0-.07-.41,6,6,0,0,0-.41-.6c-.16-.27-.32-.54-.47-.81S14.29,1,14.18.77c.26.08.52.15.77.25.41.15.82.32,1.22.5s.59.24.89.34c.65.22,1.33.37,2,.62l.49.14-.3,1.06c-.3.92-.58,1.85-.81,2.8q-.15.67-.27,1.35c-.07-.12-.15-.24-.21-.37-.24-.42-.44-.87-.66-1.3,0-.07-.22-.5-.32-.65a.44.44,0,0,0-.48-.21,2.4,2.4,0,0,0-.8.4,10.53,10.53,0,0,0-.88.76,7.78,7.78,0,0,0-1.21,1.42,7.08,7.08,0,0,0-.74,1.42A9.69,9.69,0,0,1,12.92,7.29Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
