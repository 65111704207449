import { Icon } from '@iconify/react';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import roundReceipt from '@iconify/icons-ic/round-receipt';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
// material
import { Container, Tab, Box, Tabs, Stack } from '@mui/material';
// components
import Page from '../../../components/Page';
import Account from '../Account';
import AccountGeneral from '../../../components/User/AccountGeneral';
import { useTheme } from '@mui/material/styles';
import useAuth from '../../../hooks/useAuth';
import { getProfileDetails } from '../../../services/student/student';

// ----------------------------------------------------------------------

export default function User() {
  const [currentTab, setCurrentTab] = useState('general');
  const theme = useTheme();
  const { user } = useAuth();
  const [profileDetails, setProfileDetails] = useState({});

  const getUserDetails = () => {
    getProfileDetails(user.email)
      .then((res) => {
        setProfileDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const ACCOUNT_TABS = [
    {
      value: 'general',
      icon: <Icon icon={roundAccountBox} width={20} height={20} />,
      component: <AccountGeneral profileDetails={profileDetails} getUserDetails={getUserDetails} />
    },
    {
      value: 'billing',
      icon: <Icon icon={roundReceipt} width={20} height={20} />,
      component: <Account profileDetails={profileDetails} getUserDetails={getUserDetails} />
    }
  ];

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Page title="User: Account Settings | Minimal-UI">
      <Container maxWidth={'lg'}>
        <Stack spacing={5}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.primary.main
              }
            }}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab key={tab.value} label={capitalCase(tab.value)} value={tab.value} />
            ))}
          </Tabs>

          {ACCOUNT_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Stack>
      </Container>
    </Page>
  );
}
