import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import CodeLayout from '../layouts/playground';
import MentorLayout from '../layouts/mentor';
// hooks
import useAuth from '../hooks/useAuth';
// guards
//import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
// pages
import Login from '../pages/authentication/Login';
import LoginSignup from '../pages/authentication/LoginSignup';
import ModulePanel from '../pages/module-panel/index';
import Signup from '../pages/authentication/Signup';
import ResetPassword from '../pages/authentication/ResetPassword';
import AdminLayout from '../layouts/admin/index';
import AdminCoursesPage from '../pages/admin/AdminCoursesPage';
import AddCoursesPage from '../pages/admin/AddCoursePage';
//analytics
import { analytics, analyticsPlatform } from '../analytics/index';
import AddTreeNodePage from '../pages/admin/AddTreeNodePage';
import Account from '../pages/dashboard/Account';
import User from '../pages/dashboard/User/User';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// Dashboard
const StudentDashboard = Loadable(lazy(() => import('../pages/dashboard/StudentDashboard')));
const CodingArena = Loadable(lazy(() => import('../pages/playground/playground')));
const MentorDashboard = Loadable(lazy(() => import('../pages/mentor/Dashboard')));
const DoubtDashboard = Loadable(lazy(() => import('../pages/mentor/DoubtDashboard')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

export default function Router() {
  let routes = [];
  const { user } = useAuth();

  useEffect(() => {
    //setting user for mixpanel
    if (user)
      analytics.setUser({
        type: analyticsPlatform.mixpanel,
        distinctKey: user.userId,
        userName: user.displayName,
        emailId: user.email
      });
  }, []);

  if (user && user.email !== 'none' && user.email !== '') {
    routes =
      // eslint-disable-next-line no-nested-ternary
      user.admin && user.redirectToAdmin
        ? [
            {
              path: '/admin',
              element: <AdminLayout />,
              children: [
                { path: '', element: <Navigate to="/admin/courses" replace /> },
                { path: 'courses', element: <AdminCoursesPage /> },
                { path: 'add-course', element: <AddCoursesPage /> },
                { path: 'add-treenode/:courseId', element: <AddTreeNodePage /> }
              ]
            },
            {
              path: '/',
              element: <LogoOnlyLayout />,
              children: [
                { path: '/login', element: <Navigate to="/admin" replace /> },
                { path: '404', element: <NotFound /> },
                { path: '', element: <Navigate to="/admin" /> },
                { path: '*', element: <Navigate to="/404" /> }
              ]
            }
          ]
        : user.mentor
        ? [
            {
              path: '/mentor',
              element: <MentorLayout />,
              children: [
                { path: '', element: <Navigate to="/mentor/dashboard" replace /> },
                { path: 'dashboard', element: <MentorDashboard /> },
                { path: ':_id', element: <DoubtDashboard /> }
              ]
            },
            {
              path: '/',
              element: <LogoOnlyLayout />,
              children: [
                { path: '/login', element: <Navigate to="/mentor" replace /> },
                { path: '404', element: <NotFound /> },
                { path: '/', element: <Navigate to="/mentor" /> },
                { path: '*', element: <Navigate to="/404" /> }
              ]
            },

            { path: '*', element: <Navigate to="/404" replace /> }
          ]
        : [
            {
              path: '/dashboard',
              element: <DashboardLayout />,
              children: [
                { path: '', element: <Navigate to="/dashboard/app" replace /> },
                { path: 'app', element: <StudentDashboard /> },
                { path: 'module/:courseId', element: <ModulePanel /> },
                { path: 'user', element: <User /> }
              ]
            },
            {
              path: '/playground',
              element: <CodeLayout />,
              children: [
                { path: '', element: <Navigate to="/dashboard" replace /> },
                { path: ':courseId/:moduleId/:lessonId', element: <CodingArena type="student" /> }
              ]
            },
            {
              path: '/',
              element: <LogoOnlyLayout />,
              children: [
                { path: '/login', element: <Navigate to="/dashboard" replace /> },
                { path: '404', element: <NotFound /> },
                { path: '', element: <Navigate to="/dashboard" /> },
                { path: '*', element: <Navigate to="/404" /> }
              ]
            },

            { path: '*', element: <Navigate to="/404" replace /> }
          ];
  } else {
    routes = [
      {
        path: '/',
        element: <LogoOnlyLayout />,
        children: [
          { path: '/', element: <Navigate to="/login" replace /> },
          { path: 'login', element: <LoginSignup /> }
        ]
      },

      { path: '*', element: <Navigate to="/login" replace /> }
    ];
  }

  return useRoutes(routes);
}
