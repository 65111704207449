import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import PaymentHistoryCard from '../../components/payment/PaymentHistoryCard';

const Account = ({ profileDetails }) => {
  const { themeStretch } = useSettings();
  return (
    <Page title="User Settings">
      <Container sx={{ marginTop: '2%' }} maxWidth={themeStretch ? false : 'xl'}>
        {profileDetails && profileDetails.purchaseDetails ? (
          <>
            <Grid container mb={5}>
              <Grid sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} item>
                <Typography variant="caption" color={'#1A676B'}>
                  Purchase
                </Typography>
              </Grid>

              <Grid sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} item>
                <Typography variant="caption" color={'#1A676B'}>
                  Amount
                </Typography>
              </Grid>

              <Grid sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} item>
                <Typography variant="caption" color={'#1A676B'}>
                  Purchase Date
                </Typography>
              </Grid>

              <Grid sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} item>
                <Typography variant="caption" color={'#1A676B'}>
                  Status
                </Typography>
              </Grid>
            </Grid>
            {profileDetails?.purchaseDetails?.map((purchases, index) => {
              return <PaymentHistoryCard key={index} paymentDetails={purchases} />;
            })}
          </>
        ) : (
          <Typography variant="h6">You havent purchased our courses yet!</Typography>
        )}
      </Container>
    </Page>
  );
};

export default Account;
