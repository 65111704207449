import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as dayjs from 'dayjs';
//material ui
import { Card, Tooltip, Stack, Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
//icons
import LockCircle from '../../../icons/lockCircle';
import UnlockCircle from '../../../icons/unlockCircle';

const BorderLinearProgress = withStyles(() => ({
  root: {
    // height: 10,
    // borderRadius: 5,
  },
  colorPrimary: {
    // backgroundColor: '#FA983F',
    background: '#95d3cd'
  },
  bar: {
    // borderRadius: 5,
    backgroundColor: '#19686b'
  }
}))(LinearProgress);

export default function ModuleCard(props) {
  const { module, courseId } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(`/playground/${courseId}/${module._id}/${module.initialLessonId}`);
  };

  return (
    <>
      <Card
        onClick={!module.isLocked && handleRedirect}
        sx={{
          paddingTop: 2,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          minWidth: '300px',
          cursor: 'pointer'
        }}
        style={{ boxShadow: `5px 10px 18px ${theme.palette.grey[300]}` }}
      >
        <Stack pr={3} pb={2} direction="row" justifyContent="space-between">
          <Box pl={3} width="80%">
            <Typography variant="subtitle2" sx={{ marginBottom: 1, whiteSpace: 'nowrap' }}>
              {module.name}
            </Typography>
            <Stack direction="column">
              <Typography mr={2} sx={{ fontSize: 12 }} mb={0.5} variant="caption">
                <span style={{ color: '#19686B' }}>{`Release: `}</span>
                {dayjs(module.releaseDate).format('ddd, MMMM D YYYY')}
              </Typography>
              <Typography sx={{ fontSize: 12 }} variant="caption">
                <span style={{ color: 'red' }}>{`Deadline: `}</span>
                {dayjs(module.deadline).format('ddd, MMMM D YYYY')}
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
            <div
              style={{
                backgroundColor: `${module.isLocked ? '#FFEEEE' : '#E6FFED'}`,
                borderRadius: '50%',
                padding: '11px',
                textAlign: 'center',
                display: 'flex'
              }}
            >
              {module.isLocked ? (
                <LockCircle size={30} color={theme.palette.primary.main} />
              ) : (
                <UnlockCircle size={30} />
              )}
            </div>
          </Box>
        </Stack>
        <Tooltip
          title={`progress ${Math.floor((module.studentScore / module.weightage) * 100)}%`}
          placement="top"
          arrow
        >
          <BorderLinearProgress
            variant="determinate"
            value={Math.floor((module.studentScore / module.weightage) * 100)}
          />
        </Tooltip>
      </Card>
    </>
  );
}
