import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function LessonFlipDuoTone(props) {
  const theme = useTheme();
  const { size = 20, color = theme.palette.primary.main } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M24,14.61a51.57,51.57,0,0,0-.45-8.73,4.09,4.09,0,0,0,0-.62c-.14-.67-2.15-1-3-1.08a.3.3,0,0,0-.33.26c-.05.66,1,0,2.61,1A9.86,9.86,0,0,0,23,7.56c.24,2.24.11,3.79,0,9.74A13.84,13.84,0,0,0,19.38,17a10.75,10.75,0,0,0-6.32,2c.68-2.64,2.34-3.35,4.68-4.19a2.44,2.44,0,0,1,.82-.11.57.57,0,0,0,1.09-.27c-.38-10.11,0-11.53-.16-12.47S18.65.91,17.7.77a6.57,6.57,0,0,0-6.07,2.77c-.08.17-.55,1.21-.59,1.32A5.87,5.87,0,0,0,8.73,3.79a8.71,8.71,0,0,0-6.31,1A3.23,3.23,0,0,0,1,6.93C.12,10.49-.41,11.54.41,19.11c0,.31,0,.79.3,1.08a.6.6,0,0,0,.49.13c0,.28,0,.77,0,.77.13,1.8,2.52.7,5.15.48,2.18-.17,2.42.3,4.35,1.42a4.66,4.66,0,0,0,2.49.22,4.26,4.26,0,0,0,1.89-.78c6.59-4.85,6.36.82,7.9-3.25a2.05,2.05,0,0,0,.13-.58.53.53,0,0,0,.82-.41A31.18,31.18,0,0,0,24,14.61ZM11.88,5.39c3-5.64,6.72-3.16,6.88-3,.08.3-.37,7.08-.39,7.7l.09,3.45a8,8,0,0,0-5.61,3.24c-.5.86-.33.95-.42.38C12.41,17.06,11.84,5.47,11.88,5.39ZM1.08,9.73c.29-1.11.65-3.93,1.61-4.25,2.8-.94,5.6-1.17,8.2.25a7.06,7.06,0,0,1,.41,2.68c0,2.05.06,8.24.3,10.1a11.19,11.19,0,0,0-5.08-1,12.36,12.36,0,0,0-4,1.05c-.12,0-.68.57-1.08.79,0-.14-.11-.3-.12-.38C1,16.25.53,12.67,1.08,9.73Zm20.56,9.83c-2.88-1.15-5.17.48-7.21,1.94a3.84,3.84,0,0,1-3,.55c-3.87-2.74-5.37-1.43-8.87-.9-.18,0-.32.09-.41,0s-.19-.67-.42-1c.43-.22,1-.57,1.14-.61C4.34,19.08,6,18,9.6,19c2.66.69,2.52,1.85,3.42,1.36,1.34-.72,3.27-2.88,9.31-2.15h0a.39.39,0,0,0-.09.26C21.65,19.53,21.68,19.46,21.64,19.56Z"
          fill="#191919"
        ></path>
        <path
          d="M3.65,9c1-.14.92-.63,2.24-.63a8.6,8.6,0,0,1,2.16.44c.19-.07.33-.15.33-.34,0-.43-.6-.87-1.51-1-2.63-.49-2.88.77-3.38,1A.3.3,0,0,0,3.65,9Z"
          fill="#ff4a8c"
        ></path>
        <path
          d="M3.65,12.53c1-.14.92-.63,2.24-.63a8.6,8.6,0,0,1,2.16.44c.19-.06.33-.15.33-.34,0-.43-.6-.86-1.51-1-2.63-.49-2.88.76-3.38,1A.3.3,0,1,0,3.65,12.53Z"
          fill="#ff4a8c"
        ></path>
        <path
          d="M3.65,16.1c1-.14.92-.63,2.24-.63a9.14,9.14,0,0,1,2.16.44c.19-.06.33-.15.33-.33,0-.44-.6-.87-1.51-1-2.63-.49-2.88.77-3.38,1A.3.3,0,1,0,3.65,16.1Z"
          fill="#ff4a8c"
        ></path>
      </g>
    </svg>
  );
}
