import { useState, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//components
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './Sidebar';
import LoadingScreen from '../../components/LoadingScreen';
import { ListItemIcon, Box } from '@mui/material';
import Home from '../../icons/Home';
import Module from '../../icons/Module';
//services
import { getPlaygroundSidebar } from '../../services/student/student';

// ----------------------------------------------------------------------

const getIcon = (name, icon) => (
  <ListItemIcon>
    <Box component="img" alt={name} src={icon} />
  </ListItemIcon>
);

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 40;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  //   overflow: 'auto', // we dont want a scroll in playground
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  //   paddingBottom: theme.spacing(10), // as there was a scroll in playground so commented it
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default function DashboardLayout() {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  const { user } = useAuth();

  const [sidebarConfig, setSidebarConfig] = useState([]);
  const [breadcrumData, setBreadcrumData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (user.email && params.courseId && params.moduleId) {
      setRefresh(true);
      return getPlaygroundSidebar({ courseId: params.courseId, moduleId: params.moduleId, emailId: user.email })
        .then((sidebarData) => {
          if (sidebarData.data.length === 0) {
            navigate('/dashboard/app');
            setRefresh(false);
          } else {
            const items = sidebarData.data.map((data) => {
              const obj = {};
              obj.title = data.title;
              obj.path = data.path;
              obj.moduleName = data.moduleName;
              obj.isOpened = data.isOpened;
              obj.icon =
                data.type === 'code'
                  ? getIcon('courses', '/static/icons/ic_code.svg')
                  : data.type === 'video'
                  ? getIcon('courses', '/static/icons/ic_video.svg')
                  : data.type === 'dragndrop'
                  ? getIcon('courses', '/static/icons/ic_dragndrop.svg')
                  : data.type === 'mcq'
                  ? getIcon('courses', '/static/icons/ic_mcq.svg')
                  : getIcon('courses', '/static/icons/ic_text.svg');
              return obj;
            });

            setSidebarConfig([
              {
                items: items
              }
            ]);
            //if we will remove this setRefresh then other components will not even load or render,
            //but now first sidebar loads then playground
            setRefresh(false);
          }
        })
        .catch((err) => {
          navigate('/dashboard/app');
          setRefresh(false);
        });
    }
  }, []);

  useEffect(() => {
    const isLessonExist =
      sidebarConfig &&
      sidebarConfig[0] &&
      sidebarConfig[0].items &&
      sidebarConfig[0].items.filter((item) => item.path.includes(params.lessonId));

    if (isLessonExist && isLessonExist[0]) {
      let data = [
        {
          //   icon: <Home size={16} />,
          title: 'Home',
          link: '/dashboard/app'
        },
        {
          //   icon: <Module size={16} />,
          title: isLessonExist[0].moduleName,
          link: `/dashboard/module/${params.courseId}`
        },
        {
          // icon: <Module size={16} />,
          title: isLessonExist[0].title,
          link: ''
        }
      ];

      setBreadcrumData(data);
      setRefresh(false);
    }
  }, [params, navigate, sidebarConfig]);

  return (
    <>
      {refresh ? (
        <LoadingScreen />
      ) : (
        <RootStyle>
          <DashboardNavbar breadcrumData={breadcrumData} onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar
            sidebarConfig={sidebarConfig}
            setSidebarConfig={setSidebarConfig}
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
          <MainStyle
            sx={{
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.complex
              }),
              ...(collapseClick && {
                ml: '102px'
              })
            }}
          >
            <Outlet />
          </MainStyle>
        </RootStyle>
      )}
    </>
  );
}
