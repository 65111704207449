import React from 'react';
import { TextField, MenuItem, Grid, Card, Button, Stack, Typography } from '@mui/material';
import UploadFolder from '../../icons/UploadFolder';
import GeneralFormFileUpload from './GeneralFormFileUpload';
import MultipleInput from './MultipleInput';
import GeneralDatePicker from './GeneralDatePicker';
import ArrayForm from './ArrayForm';
import AddCodeModal from './AddCodeModal';
import RadioComponent from './RadioComponent';
import SelectCountyState from './SelectCountyState';
import MultipleSelectCheckmarks from './MultiSelectField';
import ArrayText from './ArrayText';

const getFormElement = (configs, formDetails, setFormDetails, globalOptions, setGlobalOptions) => {
  const changeHandler = (e) => {
    e.preventDefault();
    const value = configs.value;
    const obj = {};
    obj[value] = e.target.value;
    if (configs.parentValue) {
      setFormDetails((prevState) => {
        return {
          ...prevState,
          [configs.parentValue]: {
            ...prevState[configs.parentValue],
            ...obj
          }
        };
      });
    } else {
      setFormDetails((prevState) => {
        return { ...prevState, ...obj };
      });
    }
  };

  const onFileAdd = (fileArray) => {
    const value = configs.value;
    const obj = {};
    obj[value] = fileArray;
    setFormDetails((prevState) => {
      return { ...prevState, ...obj };
    });
  };

  const setDateState = (dateValue) => {
    const newDateValue = new Date(dateValue).toISOString();
    const key = configs.value;
    const dateObj = {};
    dateObj[key] = newDateValue;
    setFormDetails((prev) => {
      return {
        ...prev,
        ...dateObj
      };
    });
  };

  switch (configs.type) {
    case 'textField':
      return (
        <TextField
          value={formDetails[configs.value]}
          label={configs.label}
          {...configs.fieldProps}
          onChange={changeHandler}
        />
      );
    case 'multiselect':
      return (
        <TextField
          sx={{
            width: '100%',
            '& .MuiInputLabel-root': {
              bottom: '25px',
              top: '-7px'
            },
            '& .MuiSelect-select': {
              padding: '9px 14px'
            }
          }}
          value={formDetails[configs.value]}
          onChange={changeHandler}
          select
          label={configs.label}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {configs.options.map((option) => (
            <MenuItem value={option.value}>{option.label}</MenuItem>
          ))}
        </TextField>
      );

    case 'fileUpload':
      return (
        <GeneralFormFileUpload
          buttonNode={
            <Button size="small">
              <UploadFolder />
              <Typography ml={2} variant="title">
                Upload {configs.label}
              </Typography>
            </Button>
          }
          onFileAdd={onFileAdd}
          multiple={configs.multiple || true}
          configs={configs}
        />
      );

    case 'multipleInput':
      return (
        <MultipleInput
          multipleInputType={configs.multipleInputType}
          title={configs.label}
          configs={configs}
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          multipleInputHeading={configs.multipleInputHeading}
          hasIcon={configs.hasIcon}
        />
      );

    case 'nestedArray':
      return (
        <ArrayForm
          configuration={configs.arrayConfigs}
          showActionButtons={false}
          accessValue={configs.value}
          parentAccessValue={configs.parentValue}
          heading={configs.heading}
          setFormDetails={setFormDetails}
          globalOptions={globalOptions}
          setGlobalOptions={setGlobalOptions}
        />
      );

    case 'codemodal':
      return <AddCodeModal formState={formDetails} setFormState={setFormDetails} config={configs} />;

    case 'multiSelectCheck':
      console.log('configs ', configs);
      return (
        <MultipleSelectCheckmarks
          options={configs.options}
          useGlobalOptions={configs.useGlobalOptions}
          formState={formDetails}
          setFormState={setFormDetails}
          configs={configs}
          globalOptions={globalOptions}
          setGlobalOptions={setGlobalOptions}
        />
      );

    case 'dateField':
      return <GeneralDatePicker setDateState={setDateState} configs={configs} />;

    case 'radio':
      return (
        <RadioComponent
          state={formDetails}
          setState={setFormDetails}
          radioOptions={configs.radioOptions}
          label={configs.label}
        />
      );

    case 'state_county':
      return <SelectCountyState state={formDetails} configs={configs} setState={setFormDetails} />;
    case 'Button':
      return <Button onClick={configs.onClickHandler}>{configs.label}</Button>;

    case 'arrayText':
      return (
        <ArrayText
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          value={configs.value}
          label={configs.label}
        />
      );

    default:
      return <TextField sx={{ minWidth: '70%' }} label={configs.label} {...configs.fieldProps} />;
  }
};

const GeneralForm = (props) => {
  const {
    fieldConfigs,
    formTitle,
    formDetails,
    setFormDetails,
    onSubmitForm,
    onResetForm,
    disableSubmitButton,
    showActionButtons = true,
    gridSize = {
      md: 4,
      lg: 3,
      sx: 12
    },
    removeBorder = false,
    globalOptions,
    setGlobalOptions
  } = props;

  const grids = gridSize;

  const cardSx = removeBorder
    ? {
        border: 'none',
        boxShadow: 'none'
      }
    : {};

  return (
    <Card sx={{ padding: '24px', ...cardSx, overflow: 'visible' }}>
      {formTitle ? (
        <Stack mb={4}>
          <Typography variant="overline">{formTitle}</Typography>
        </Stack>
      ) : (
        ''
      )}
      <Grid container spacing={2}>
        {fieldConfigs.map((configs) => {
          if (configs.type === 'multipleInput' || configs.type === 'nestedArray') {
            grids.md = 12;
            grids.lg = 12;
          }
          if (configs.isHidden) return '';
          return (
            <Grid key={configs.label} item {...grids}>
              {getFormElement(configs, formDetails, setFormDetails, globalOptions, setGlobalOptions)}
            </Grid>
          );
        })}
      </Grid>

      {showActionButtons ? (
        <Stack mt={4} justifyContent="flex-end" direction={'row'} spacing={3}>
          <Button sx={{ minWidth: '15%' }} variant="outlined" onClick={onResetForm}>
            Reset
          </Button>
          <Button sx={{ minWidth: '15%' }} disabled={disableSubmitButton} variant="contained" onClick={onSubmitForm}>
            Save
          </Button>
        </Stack>
      ) : (
        ''
      )}
    </Card>
  );
};

export default GeneralForm;
