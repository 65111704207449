import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from '../../hooks/useAuth';
// components
import { MHidden } from '../../components/@material-extend';
import AccountSettings from '../../components/AccountSettings';
import NotificationsPopover from '../../components/Notifications';
import Breadcrum from '../../components/Breadcrum';
import DoubtModal from '../../components/DoubtModal';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 1.5)
  }
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar, breadcrumData }) {
  const { isCollapse } = useCollapseDrawer();
  const params = useParams();
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {breadcrumData.length && <Breadcrum BreadcrumArray={breadcrumData} />}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <Button size="small">Hint</Button>
          <Button size="small">Solution</Button> */}

          {user.featureFlags && user.featureFlags.mentorSupport && (
            <Button size="small" onClick={handleOpen}>
              Raise Doubt
            </Button>
          )}

          {open ? (
            <DoubtModal
              coursePref={{
                courseId: params.courseId,
                moduleId: params.moduleId,
                emailId: user.email,
                lessonId: params.lessonId
              }}
              open={open}
              handleClose={handleClose}
            />
          ) : (
            ''
          )}
          {/* <NotificationsPopover /> */}
          <AccountSettings />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
