import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function EditContent(props) {
  const theme = useTheme();
  const { size = 24, color = theme.palette.primary.main } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <g>
          <path d="M14,20.21Z" fill="#191919"></path>
          <path
            d="M20,12.63a3.27,3.27,0,0,0-.76.58.32.32,0,0,0-.06.43c.25.33.52-.07,1-.25.9-.35,2.14.8,2.13,1.34a2.23,2.23,0,0,1-.56,1.56c-.64-.57-2.25-1.86-2.67-2.25a.35.35,0,0,0-.49.5c.4.4,1.89,1.71,2.54,2.34-.34.5-.24.34-4.08,4.31a3.94,3.94,0,0,0-2.19-2.07c3.14-3.89,3.68-4,3.28-4.32a.29.29,0,0,0-.44,0c-2.93,3-4.64,4.76-4.71,5.28-.25,2.22-.4,3.28-.09,3.62.62.67,3.12-.08,3.64-.41s4.9-5.31,5.45-6a.72.72,0,0,0,.16-.36C25.18,14.3,21.94,11.6,20,12.63Zm-4.08,9.7a6.4,6.4,0,0,1-2.06.54c0-.74.1-2.61.14-2.66.64-.74.07-.62,1.34.55.28.27.83.69,1.16,1C16.2,22.08,16,22.3,15.94,22.33Z"
            fill="#191919"
          ></path>
          <g>
            <path
              d="M5.48,5.19A7.73,7.73,0,0,0,7,5.56a10.71,10.71,0,0,0,3,0,.34.34,0,0,0,0-.68,10.54,10.54,0,0,0-3-.36,4.46,4.46,0,0,0-1.63.13C5.24,4.74,5.19,5,5.48,5.19Z"
              fill="#191919"
            ></path>
            <path
              d="M5.15,9.1a.3.3,0,0,0,.49.34,17.36,17.36,0,0,1,2.87.15,56.15,56.15,0,0,0,5.79-.34.34.34,0,0,0,0-.67,41.79,41.79,0,0,0-6.9,0C7,8.65,5.47,8.57,5.15,9.1Z"
              fill="#191919"
            ></path>
            <path
              d="M11.21,13.07a13.9,13.9,0,0,0-5,0c-.81.13-.92.13-1,.23a.3.3,0,0,0,.14.54,2.69,2.69,0,0,0,.39,0,19,19,0,0,0,5.48,0A.34.34,0,0,0,11.21,13.07Z"
              fill="#191919"
            ></path>
            <path
              d="M10.57,17.38c-2.83,0-5.91-.37-8.72-.69C3.31,13,3.16,7.83,3.6,2c0-.1.19-.42.28-.44,1-.24,5.74-.88,6.35-.91a3.58,3.58,0,0,1,1.33.12C12,.91,12.65,0,10.21,0,9.64,0,4.36.48,3.53.7c-1,.25-.86.77-1.12,3.05-.47,6.71-.5,8.45-1,10.83S.45,16.91.67,17.33a.49.49,0,0,0,.39.27c3,.23,6.53.52,9.51.45A.34.34,0,1,0,10.57,17.38Z"
              fill="#191919"
            ></path>
            <path
              d="M15.51,3.14c.36.34,1,.78,1.18,1.14l-.11,6.28a.3.3,0,0,0,.6,0c0-.54,1-5.75.52-6.78-.35-.68-.43-.45-3.89-3.35a.33.33,0,0,0-.42.52Z"
              fill="#191919"
            ></path>
            <path
              d="M12,5.09c.52,1,2.33.59,3.48.27,2-.54,1-1.53.6-1a6.08,6.08,0,0,1-3.09.2c-.2-.32,0-1.53.08-2.08.08-1.09.09-1.07-.1-1.13-.38-.16-.48.44-.63,1C12.1,3.09,11.5,4.18,12,5.09Z"
              fill="#191919"
            ></path>
          </g>
        </g>
        <path
          d="M17.9,2.12A8.65,8.65,0,0,1,19.29,3.5l-.59,7.32c0,.39.26.55.47.23.31-.89,1.52-7.35.87-8.28A13.75,13.75,0,0,0,17.2.43a.33.33,0,0,0-.42.52Z"
          fill="#ff4a8c"
        ></path>
      </g>
    </svg>
  );
}
