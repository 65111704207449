import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function ModuleDuoTone(props) {
  const theme = useTheme();
  const { size = 20, color = theme.palette.primary.main } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M11.77,13.43a.68.68,0,0,0,.62-.6,68.41,68.41,0,0,0-.11-7c-.12-.26,0-.26-.36-.48C11.2,4.9,6.34,3.39,5.77,4c-.5,1.29-.28,6.77-.21,7C5.82,11.83,10.69,13.61,11.77,13.43ZM6.21,4.55s.21,0,.35,0A24.8,24.8,0,0,1,11.33,6c0,.24.25.12,0,6.3A19.86,19.86,0,0,1,6.39,10.6C6.38,10.54,6.15,7.29,6.21,4.55Z"
          fill="#ff4a8c"
        ></path>
        <path
          d="M16.5,4.1c0,.08.45,5.64.4,6,0,0-1.64,1.07-1.93,1.22-.83.45-1.83.88-2.19,1-.2.24-.23.7.29.56a19.72,19.72,0,0,0,4.26-1.93,13.51,13.51,0,0,1,1.46.37c1.77.46,2.59.29,2.8.45.3.33,1.46-.35-1.22-1.22a8,8,0,0,0-2.48-.31,31.51,31.51,0,0,0-.43-6.59.72.72,0,0,0-.83-.59,13.82,13.82,0,0,0-4,1.79c-.24.28-.2.64.14.67A16.37,16.37,0,0,1,16.5,4.1Z"
          fill="#191919"
        ></path>
        <path
          d="M3.54,12.39a7.18,7.18,0,0,0,1.63-.87.3.3,0,0,0-.12-.58c-2,0-3.33,1.28-3.09,1.71S2.82,12.64,3.54,12.39Z"
          fill="#191919"
        ></path>
        <path
          d="M7.46,3.52c.69-.33,3.72-1.32,3.8-1.34a27.27,27.27,0,0,1,4.22.58.29.29,0,0,0,.42-.4A11.86,11.86,0,0,0,11,1.09a20.21,20.21,0,0,0-3.5,1.56c-.39.21-1.06.55-.9,1S7.33,3.58,7.46,3.52Z"
          fill="#191919"
        ></path>
        <path
          d="M18.52,21.4a55.81,55.81,0,0,0-.15-6.63c0-.27,0-.27-.36-.47-.73-.38-5.64-1.27-6.2-.66-.06.14-.18-.23-.24,2-.08,3.07,0,4.13.37,4.68.73.62,4.79,2.34,5.92,2.07C18.15,22.34,18.55,22.38,18.52,21.4Zm-1.12-.11A20.81,20.81,0,0,1,13.26,20a6.71,6.71,0,0,1-.73-.28,39.6,39.6,0,0,1-.28-5.55c.09-.36,4.68.63,5.18.92C17.57,16.06,17.42,20.92,17.4,21.29Z"
          fill="#ff4a8c"
        ></path>
        <path
          d="M10.16,20.42c-.45.2-.93.45-1.36.68-.66.35-.74.4-1,.51-.53.34-.8.88,0,.88A10,10,0,0,0,11.12,21c.63-.71.49-1.29,0-1Z"
          fill="#191919"
        ></path>
        <path
          d="M7.06,21.51C7.09,20,7,16,7,15.46a.93.93,0,0,0-.75-.73C5.47,14.6,1,12.94.37,13.59L.23,14c-.42.43-.16,6.27-.06,6.58.22.71,3.59,1.86,4.3,2.08a5.94,5.94,0,0,0,2,.24A.58.58,0,0,0,7,22.43C7,22.22,7.06,21.51,7.06,21.51Zm-1.14.37a20.89,20.89,0,0,1-4.14-1.32c-.37-.16-.89-.27-.78-.39A1.84,1.84,0,0,0,1,19.8S.46,14,1.17,14.11a25,25,0,0,1,4.77,1.47C5.94,15.82,6.18,15.72,5.92,21.88Z"
          fill="#ff4a8c"
        ></path>
        <path
          d="M10.59,14.12a.33.33,0,0,0-.25-.62,17.72,17.72,0,0,0-2.51.67,3.34,3.34,0,0,0-.64.42c-.33.41-.13.5.46.59A6.5,6.5,0,0,0,10.59,14.12Z"
          fill="#191919"
        ></path>
        <path
          d="M23.56,12.65a.72.72,0,0,0-.82-.6A15.7,15.7,0,0,0,18.54,14c-.42.27-.17.81.34.55A18,18,0,0,1,22.6,13.1c0,.56.06-.13.26,3.74.14,2.6.2,2.14-.06,2.35a17.21,17.21,0,0,1-3.64,2c-.55.4-.63.86,0,.8,1.73-.69,4.61-2,4.81-2.62A30.82,30.82,0,0,0,23.56,12.65Z"
          fill="#191919"
        ></path>
      </g>
    </svg>
  );
}
