import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { REACT_APP_SERVER_URL } from '../constants/constant';

// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'google',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios.get(`${REACT_APP_SERVER_URL}/auth/profile`, { withCredentials: true });

        const user = response.data;

        if (user && user.email !== 'none') {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: {
                userId: user._id,
                displayName: user.username,
                photoURL: user.thumbnail,
                isPublic: true,
                username: user.username,
                thumbnail: user.thumbnail,
                redirectToAdmin: user.redirectToAdmin,
                mentor: user.mentor,
                email: user.email,
                admin: user.admin,
                userType: user.userType,
                featureFlags: user.featureFlags
              }
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const loginOrRegistedGoogle = async () => {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/auth/profile`, { withCredentials: true });
    const user = response.data;

    if (user && user.email !== 'none') {
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    } else {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  const logout = async () => {
    window.location.href = '/auth/logout';
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'google',
        loginOrRegistedGoogle,
        logout,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
