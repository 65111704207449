import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function Accountant(props) {
  const theme = useTheme();
  const { size = 24, color = 'white' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M12.1,18.52a5.91,5.91,0,0,0-1.68-.88c3.95-1.84,3.3-8-.84-8.31a.33.33,0,1,0-.06.66A2.92,2.92,0,0,1,12.1,12.5C12.46,15.32,10.61,17.41,7,17a3.16,3.16,0,0,1-2.87-2.46c2.36.37,3.16-.48,4.55-1.44,0,0,.11-.16.18-.25a19.36,19.36,0,0,0,1.34,1.58.33.33,0,1,0,.48-.46c-.66-.8-1-1.95-1.57-2.18s-.88.29-1.07.42C6.36,13.38,6,13.94,4.06,14c-.2-2.24,1.36-4.2,4.24-4.34a.3.3,0,0,0,0-.59C2.47,9,1.56,15.7,5.33,17.68A8.8,8.8,0,0,0,.45,23.45a.3.3,0,0,0,.57.16,7.94,7.94,0,0,1,6.33-5.3,13.83,13.83,0,0,0,0,5.09c.1.76.7.3.67,0,0-.08,0,.05,0-.54A13,13,0,0,0,8,18.3a7.5,7.5,0,0,1,3.44,1,9.34,9.34,0,0,1,3,4.47.33.33,0,0,0,.64-.19A10.17,10.17,0,0,0,12.1,18.52Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M22.38,20.38a4,4,0,0,0-2.36-2,3.1,3.1,0,0,0-1.52-5.86c-4.55.26-4.55,5.09-2,6.09A6,6,0,0,0,14.84,20c-.28.26.13.69.41.42a4.22,4.22,0,0,1,3.07-1.14,5.49,5.49,0,0,1,2.08.51,5.83,5.83,0,0,1,2.18,2.63.34.34,0,0,0,.63-.23A7.38,7.38,0,0,0,22.38,20.38Zm-3.56-2.19c-.87-.07-1.57.14-2.31-.38-1.22-.86-1.43-3.89,2.06-4.12C21,13.53,21.81,17.26,18.82,18.19Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M23.47,12.21a14.25,14.25,0,0,0-3.64-3.88C23,5.34,21.52,1,17.6.11a5.4,5.4,0,0,0-6.51,5.37C11.18,8,13.71,10,15.5,9.83a.29.29,0,1,0,0-.58C14,9.32,12,7.51,12,5.47a4.53,4.53,0,0,1,5.37-4.26c3.25.63,4.5,4.23,1.68,6.82a5.4,5.4,0,0,1-1.79,1.12.34.34,0,0,0,.22.64,6.11,6.11,0,0,0,1.82-1A19.7,19.7,0,0,0,23,12.64.33.33,0,0,0,23.47,12.21Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
