import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { Link, Stack, Alert, Checkbox, TextField, IconButton, OutlinedInput, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { userLoginViaOTP } from '../../../services/user/user';
import VerifyOtp from '../verifyCode/VerifyCodeForm';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [enterOtp, setEnterOtp] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      remember: true
    },
    isInitialValid: false,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        userLoginViaOTP({
          emailId: values.email
        }).then((res) => {
          if (res.data !== 'Max OTP limit reached! Please try again later') setEnterOtp(true);
          enqueueSnackbar(res.data, {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        });
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, isValid, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      {enterOtp ? (
        <VerifyOtp setEnterOtp={setEnterOtp} email={values.email} />
      ) : (
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack>
            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

            <TextField
              width="50%"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              sx={{ marginBottom: 3 }}
              size="medium"
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            />
            <LoadingButton
              size="medium"
              sx={{ textAlign: 'center', width: '25%' }}
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isValid && true}
            >
              Continue
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </FormikProvider>
  );
}
