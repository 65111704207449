import PropTypes from 'prop-types';

//change-case converts strings between camelCase, PascalCase, snake_case, noCase and others
//noCase transforms into a lower cased string with spaces between words
import { noCase } from 'change-case';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

//iconify - It offers native components for several popular UI frameworks
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  //   ListItemAvatar,
  ListItemButton
} from '@mui/material';
// utils
//import mockData from '../utils/mock-data';
import { fToNow } from '../utils/formatTime';
// components
import Scrollbar from './Scrollbar';
import MenuPopover from './MenuPopover';
import { MIconButton } from './@material-extend';
import { fetchNotifications, readAllNotifications } from '../services/notifications/notifications';
import useAuth from '../hooks/useAuth';
import useSocket from '../hooks/useSocket';

// ----------------------------------------------------------------------

// const TITLES = [
//   'Your order is placed',
//   'Sylvan King',
//   'You have new message',
//   'You have new mail',
//   'Delivery processing'
// ];

// const DESCRIPTIONS = [
//   'waiting for shipping',
//   'answered to your comment on the Minimal',
//   '5 unread messages',
//   'sent from Guido Padberg',
//   'Your order is being shipped'
// ];

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.header}
      {notification.body && (
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          &nbsp; {noCase(notification.body)}
        </Typography>
      )}
    </Typography>
  );

  return {
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({ notification }) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      to="#"
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.status === 'UNREAD' && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.timeOfReceiving)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [numberOfShownMessages, setNumberOfShownMessages] = useState(5);
  const totalUnRead = notifications.filter((item) => item.status === 'UNREAD').length;
  const { user } = useAuth();
  const { socketChat } = useSocket();

  useEffect(() => {
    fetchNotifications(user.email).then((res) => {
      setNotifications(res.notifications);
    });
    socketChat.notify_on(handleNotification);
  }, [notifications.length]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    readAllNotifications(user.email).then(() => {
      setNotifications(
        notifications.map((notification) => ({
          ...notification,
          status: 'READ'
        }))
      );
    });
  };

  const handleNotification = (params) => {
    if (params.userEmail === user.email) {
      params.notifications.map((pnr) => {
        const notificationObject = {
          title: pnr.header,
          body: pnr.body,
          type: 'order_placed',
          timeOfReceiving: pnr.timeOfReceiving,
          isUnRead: pnr.status === 'READ' ? 'READ' : 'UNREAD',
          link: pnr.link
        };
        return setNotifications((prevState) => [notificationObject, ...prevState]);
      });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding style={{ overflowY: 'scroll', height: '22rem' }}>
            {notifications.slice(0, numberOfShownMessages).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button
            onClick={() => {
              numberOfShownMessages < notifications.length
                ? setNumberOfShownMessages(notifications.length)
                : setNumberOfShownMessages(5);
            }}
            fullWidth
            disableRipple
            component={RouterLink}
            to="#"
          >
            {numberOfShownMessages <= 5 ? 'View All' : 'View Less'}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
