import React from 'react';
import StudentCoding from '../icons/StudentCoding';
import Admin from '../icons/Admin';

const CustomIcon = ({ name }) => {
  switch (name) {
    case 'studentcoding':
      return <StudentCoding />;
    case 'admin':
      return <Admin />;
    default:
      return <>No Icon</>;
  }
};

export default CustomIcon;
