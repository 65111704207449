import { Tabs, Tab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getCourseBlueprint } from '../../services/admin/admin';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import AddSectionComponent from '../../components/admin/AddSectionComponent';
import AddModuleComponent from '../../components/admin/AddModuleComponent';
import AddLessonComponent from '../../components/admin/AddLessonComponent';
import NestedList from '../../components/treeComponent/NestedList';

const AddTreeNodePage = () => {
  const [treeBlueprint, setTreeBlueprint] = useState();
  const [fetchBlueprintValue, setFetchBlueprintValue] = useState(0);
  const [tabValue, setTabValue] = useState('section');
  const [eventData, setEventData] = useState({});
  const { courseId } = useParams();

  // SECTION FORM STATES
  const [sectionState, setSectionState] = useState({
    courseId: courseId,
    sectionName: '',
    sectionId: ''
  });

  const [moduleState, setModuleState] = useState({
    name: '',
    sequenceId: '',
    releaseWeek: '',
    weekDuration: '',
    weightage: ''
    // iconImage: ''
  });

  const theme = useTheme();
  useEffect(() => {
    const reqData = {
      courseId: courseId
    };
    getCourseBlueprint(reqData).then((data) => {
      setTreeBlueprint(data);
      setEventData({
        name: 'ADD_NODE',
        id: (data.data?.treeData || {})?.id,
        isParent: false
      });
    });
  }, [courseId, fetchBlueprintValue]);

  const addTreeListNode = (id, isParent, tabValue) => {
    setTabValue(tabValue);
    setEventData({
      name: 'ADD_NODE',
      id: id,
      isParent: isParent
    });
  };

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <div>
          <Typography mb={6} variant="h6">
            Course Tree Structure
          </Typography>
          <NestedList treeData={treeBlueprint && treeBlueprint.data.treeData} addNodeFunc={addTreeListNode} />
        </div>
        <div style={{ width: '48%' }}>
          <Tabs
            value={tabValue}
            sx={{ pb: 4 }}
            onChange={() => {}}
            //   textColor="secondary"
            //   indicatorColor="secondary"
            aria-label="secondary tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.primary.main
              }
            }}
          >
            <Tab value="section" label="Section" />
            <Tab value="module" label="Module" />
            <Tab value="lesson" label="Lesson" />
          </Tabs>
          {tabValue === 'section' && (
            <div>
              <AddSectionComponent
                sectionState={sectionState}
                setSectionState={setSectionState}
                treeBlueprint={treeBlueprint}
                courseId={courseId}
                eventData={eventData}
                setFetchBlueprintValue={setFetchBlueprintValue}
              />
            </div>
          )}
          {tabValue === 'module' && (
            <div>
              <AddModuleComponent
                moduleState={moduleState}
                setModuleState={setModuleState}
                treeBlueprint={treeBlueprint}
                courseId={courseId}
                eventData={eventData}
                setFetchBlueprintValue={setFetchBlueprintValue}
              />
            </div>
          )}
          {tabValue === 'lesson' && (
            <div>
              <AddLessonComponent
                treeBlueprint={treeBlueprint}
                courseId={courseId}
                eventData={eventData}
                setFetchBlueprintValue={setFetchBlueprintValue}
              />
            </div>
          )}
        </div>
      </Stack>
    </>
  );
};

export default AddTreeNodePage;
