import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function SearchBox(props) {
  const theme = useTheme();
  const { size = 24 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M23.89,6.91a40.29,40.29,0,0,0-.38-5.15A1.72,1.72,0,0,0,23.19,1,2.66,2.66,0,0,0,21.92.47,59.8,59.8,0,0,0,11.86,0a76.14,76.14,0,0,0-10,.68A2.45,2.45,0,0,0,.61,1.2l0,0C.2,1.79,0,5.58,0,9.84c0,5.69.37,12.21.72,12.9a1.7,1.7,0,0,0,1.09.58,49.75,49.75,0,0,0,7.87.62c3.74.09,7.85,0,10.46-.2a9,9,0,0,0,2.8-.5,1.83,1.83,0,0,0,.75-1A7.07,7.07,0,0,0,24,20.12C24,18.86,24.06,12,23.89,6.91ZM22.82,20.1a7,7,0,0,1-.09,1.33c-.06.33-.13.68-.37.82a7,7,0,0,1-1.89.32c-2.08.2-5.35.3-8.61.3a68.81,68.81,0,0,1-9.64-.45,4.84,4.84,0,0,1-.69-.19c-.35-.94-.7-7-.8-12.4-.08-4.07,0-7.7.39-8.24l0,0c.07-.1.38-.13.83-.21A76.38,76.38,0,0,1,11.87.86a72.77,72.77,0,0,1,8.8.37,5.22,5.22,0,0,1,1.8.4,7.26,7.26,0,0,1,.15.84c.12,1,.19,2.62.23,4.47C23,12,22.86,18.84,22.82,20.1Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M15.42,13.17h0a.3.3,0,0,0-.14-.22.34.34,0,0,0-.47.08A5.15,5.15,0,0,1,14,14a8,8,0,0,1-1,.74,5.19,5.19,0,0,1-5.23.38,4.63,4.63,0,0,1-2.37-4.46A5.31,5.31,0,0,1,7.59,6.73,5.15,5.15,0,0,1,12,5.94,3.93,3.93,0,0,1,13.88,7a6.24,6.24,0,0,1,1.26,1.84,5.42,5.42,0,0,1,.4,1.57,4.05,4.05,0,0,1-.19,1.6.3.3,0,1,0,.57.19,4.63,4.63,0,0,0,.28-1.83,5.87,5.87,0,0,0-.38-1.8,7.24,7.24,0,0,0-1.33-2.16A4.93,4.93,0,0,0,12.3,5a6.06,6.06,0,0,0-5.36.79,6.38,6.38,0,0,0-2.73,4.75,5.67,5.67,0,0,0,3.06,5.38,6,6,0,0,0,6-.58,11.81,11.81,0,0,0,2.53,2.79A1.69,1.69,0,0,0,18,18.2,1.7,1.7,0,0,0,18.15,16,13,13,0,0,0,15.42,13.17Zm1.78,4.19c-.11.11-.26.08-.42,0A3.27,3.27,0,0,1,16,17a14.93,14.93,0,0,1-2.19-2,6.67,6.67,0,0,0,.64-.53,6.77,6.77,0,0,0,.63-.69,14.79,14.79,0,0,1,1.9,2.3,2.56,2.56,0,0,1,.34.82A.42.42,0,0,1,17.2,17.36Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M11.5,7.53a3.9,3.9,0,0,0-.88-.22,2.89,2.89,0,0,0-1.73.56A1.13,1.13,0,0,0,8.71,8a.29.29,0,0,0,0,.45.24.24,0,0,0,.26,0,.28.28,0,0,0,.15-.08l.06,0a1.85,1.85,0,0,1,1.22,0,3.12,3.12,0,0,1,.75.26,2.1,2.1,0,0,1,.63.37,3.34,3.34,0,0,1,.61.92.34.34,0,1,0,.64-.22,3.13,3.13,0,0,0-.76-1.64A2.6,2.6,0,0,0,11.5,7.53Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
