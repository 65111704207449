import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function MenuVertical(props) {
  const theme = useTheme();
  const { size = 24 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M14.63,19.15A3.48,3.48,0,0,0,10.84,18c-1.07.41-1.93,1.48-1.73,3.39a.39.39,0,0,0,.41.35.38.38,0,0,0,.36-.4c0-1.35.53-2.1,1.3-2.34a2.33,2.33,0,0,1,2.44.93,1.72,1.72,0,0,1,.31,1.47A2.06,2.06,0,0,1,13,22.75a2.24,2.24,0,0,1-1.41.34,2.6,2.6,0,0,1-1.41-.55.34.34,0,0,0-.47-.05A.33.33,0,0,0,9.7,23a3.38,3.38,0,0,0,1.79,1,3.18,3.18,0,0,0,2.06-.26,3.13,3.13,0,0,0,1.6-2A2.87,2.87,0,0,0,14.63,19.15Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M9.46,12.14a.37.37,0,0,0,.37-.39A2,2,0,0,1,11.29,9.8a2.11,2.11,0,0,1,2.23.81,1.69,1.69,0,0,1,.31,1.46,2.07,2.07,0,0,1-.9,1.33,2.25,2.25,0,0,1-1.41.35,2.61,2.61,0,0,1-1.4-.54.33.33,0,0,0-.47-.05.33.33,0,0,0-.05.47,3.41,3.41,0,0,0,1.79,1,3.18,3.18,0,0,0,2.06-.26,3.11,3.11,0,0,0,1.61-2,2.88,2.88,0,0,0-.53-2.55A3.27,3.27,0,0,0,11,8.8a2.8,2.8,0,0,0-1.91,3A.38.38,0,0,0,9.46,12.14Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
        <path
          d="M9.35,4.15a.38.38,0,0,0,.28-.47,2,2,0,0,1,1.29-2.34,2.08,2.08,0,0,1,2.51.6,1.73,1.73,0,0,1,.32,1.46,2.12,2.12,0,0,1-.89,1.36,2.25,2.25,0,0,1-1.2.36,2.86,2.86,0,0,1-1.28-.33.34.34,0,0,0-.46.12.33.33,0,0,0,.11.46,3.67,3.67,0,0,0,1.59.57,3.11,3.11,0,0,0,1.72-.3A3.14,3.14,0,0,0,15,3.7a2.89,2.89,0,0,0-.51-2.56,3.23,3.23,0,0,0-4-.78A2.88,2.88,0,0,0,8.89,3.88.38.38,0,0,0,9.35,4.15Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
