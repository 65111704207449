import * as Yup from 'yup';
import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';

// material
import { OutlinedInput, FormHelperText, Stack, TextField, IconButton, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { MIconButton } from '../../@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import { userLoginViaOTP } from '../../../services/user/user';

// utils
import { verifyOtp } from '../../../services/user/user';

// ----------------------------------------------------------------------

// eslint-disable-next-line consistent-return
function maxLength(object) {
  if (object.target.value.length > object.target.maxLength) {
    return (object.target.value = object.target.value.slice(0, object.target.maxLength));
  }
}

export default function VerifyCodeForm({ setEnterOtp, email }) {
  const [showResendOtp, setshowResendOtp] = useState(false);
  const [counter, setCounter] = useState(60);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.number().required('Code is required'),
    code2: Yup.number().required('Code is required'),
    code3: Yup.number().required('Code is required'),
    code4: Yup.number().required('Code is required')
  });

  const resendOtp = () => {
    userLoginViaOTP({
      emailId: email
    }).then((res) => {
      if (res.data !== 'Max OTP limit reached! Please try again later') setshowResendOtp(true);
      else {
        setCounter(60);
        setshowResendOtp(false);
      }
      enqueueSnackbar(res.data, {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    });
  };

  const formik = useFormik({
    initialValues: {
      code1: '',
      code2: '',
      code3: '',
      code4: ''
    },
    isInitialValid: false,
    validationSchema: VerifyCodeSchema,
    onSubmit: async () => {
      const { code1, code2, code3, code4 } = formik.values;
      const formedOtp = String(code1) + String(code2) + String(code3) + String(code4);
      verifyOtp({ emailId: email, otp: formedOtp }).then((res) => {
        if (res && res.data && res.data.message) {
          enqueueSnackbar(res.data.message, {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          window.location.href = '/';
        }
      });
    }
  });

  const { values, errors, isValid, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  console.log('values ', values);
  console.log('touched ', touched);
  console.log('isValid ', isValid);
  console.log('errors ', errors);

  useEffect(() => {
    counter > 0 ? setTimeout(() => setCounter(counter - 1), 1000) : setshowResendOtp(true);
  }, [counter]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} justifyContent="center">
          {Object.keys(values).map(
            (item) =>
              !item.includes('password') && (
                <OutlinedInput
                  key={item}
                  {...getFieldProps(item)}
                  type="number"
                  placeholder="-"
                  onInput={maxLength}
                  error={Boolean(touched[item] && errors[item])}
                  inputProps={{
                    maxLength: 1,
                    sx: {
                      p: 0,
                      textAlign: 'center',
                      width: { xs: 36, sm: 56 },
                      height: { xs: 36, sm: 56 }
                    }
                  }}
                />
              )
          )}
        </Stack>

        <Stack direction="row" justifyContent="center" sx={{ my: 2 }}>
          <LoadingButton
            size="medium"
            sx={{ textAlign: 'center', width: '25%' }}
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid && true}
          >
            Submit
          </LoadingButton>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Link
            variant="caption"
            onClick={() => {
              setEnterOtp(false);
            }}
          >
            Go Back?
          </Link>
          {showResendOtp ? (
            <Link
              variant="caption"
              onClick={() => {
                resendOtp();
              }}
            >
              Resend OTP
            </Link>
          ) : (
            <Typography variant="caption" gutterBottom>
              {counter} seconds
            </Typography>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
