// import { getCode, getName } from "country-list";
import { Country, State, City } from 'country-state-city';
import Select from 'react-select';
import { useEffect, useState } from 'react';

export default function App({ setState, configs, state }) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    if (selectedCountry) {
      setState((prev) => {
        return { ...prev, [configs.value.country]: selectedCountry };
      });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      setState((prev) => {
        return { ...prev, [configs.value.state]: selectedState };
      });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      setState((prev) => {
        return { ...prev, [configs.value.city]: selectedCity };
      });
    }
  }, [selectedCity]);

  return (
    <div>
      <div style={{ marginTop: '10px' }}>
        <Select
          placeholder={'Select Country'}
          options={Country.getAllCountries()}
          getOptionLabel={(options) => {
            return options['name'];
          }}
          getOptionValue={(options) => {
            return options['name'];
          }}
          value={state.questions ? state.questions[configs.value.country] : selectedCountry}
          onChange={(item) => {
            setSelectedCountry(item);
          }}
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <Select
          placeholder={'Select State'}
          options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
          getOptionLabel={(options) => {
            return options['name'];
          }}
          getOptionValue={(options) => {
            return options['name'];
          }}
          value={state.questions ? state.questions[configs.value.state] : selectedState}
          onChange={(item) => {
            setSelectedState(item);
          }}
        />
      </div>
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Select
          placeholder={'Select City'}
          options={City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)}
          getOptionLabel={(options) => {
            return options['name'];
          }}
          getOptionValue={(options) => {
            return options['name'];
          }}
          value={state.questions ? state.questions[configs.value.city] : selectedCity}
          onChange={(item) => {
            setSelectedCity(item);
          }}
        />
      </div>
    </div>
  );
}
