import React from 'react';
import { useTheme } from '@mui/material/styles';

export default function AddSquare(props) {
  const theme = useTheme();
  const { size = 24, color = 'white' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <g>
        <path
          d="M24,8.68a47.71,47.71,0,0,0-.25-4.77A3.53,3.53,0,0,0,23,2.16a2.55,2.55,0,0,0-1.73-.94,84,84,0,0,0-11-.47A39.23,39.23,0,0,0,3,1.4,4.43,4.43,0,0,0,1.05,2.53,2.63,2.63,0,0,0,.3,3.93,41,41,0,0,0,0,10.75,81.27,81.27,0,0,0,.75,20.88a2.28,2.28,0,0,0,1,1.43A5.07,5.07,0,0,0,4,23.09a29.56,29.56,0,0,0,4.25.16c3.17,0,7-.3,9.78-.37a.32.32,0,0,0,.33-.34A.34.34,0,0,0,18,22.2c-2.8,0-6.61.23-9.78.23a29.52,29.52,0,0,1-4.11-.21,4.35,4.35,0,0,1-1.55-.49,1.55,1.55,0,0,1-.87-1,74.58,74.58,0,0,1-.55-8.31,49.24,49.24,0,0,1,.3-8.27,1.8,1.8,0,0,1,.64-1,3.39,3.39,0,0,1,1.17-.63,41,41,0,0,1,7.12-.61,86.64,86.64,0,0,1,10.82.35,1.61,1.61,0,0,1,1.09.55A2.5,2.5,0,0,1,22.75,4c.16,1.15.27,2.81.33,4.66.14,4,.11,8.72.14,11a3,3,0,0,1-.39,1.58,1.43,1.43,0,0,1-1.17.82l-2,.06a.3.3,0,1,0,0,.6l2,0A1.91,1.91,0,0,0,23.16,22a3.68,3.68,0,0,0,.76-2.26C23.93,17.41,24.05,12.64,24,8.68Z"
          fill="#ff4a8c"
          fill-rule="evenodd"
        ></path>
        <path
          d="M18.47,10.89a2,2,0,0,0-.19-.76.93.93,0,0,0-.46-.4,2.7,2.7,0,0,0-.71-.19A6.38,6.38,0,0,0,16,9.44a11.61,11.61,0,0,0-1.61.13c-.08,0-.29,0-.47,0a6.26,6.26,0,0,0-.11-.83L13.72,7a3.49,3.49,0,0,0-.14-1,1.15,1.15,0,0,0-.35-.48,1.18,1.18,0,0,0-.37-.19,3.67,3.67,0,0,0-.71-.13l-.94,0a4.14,4.14,0,0,0-1.15.31A1.84,1.84,0,0,0,9.33,6a.73.73,0,0,0-.15.35,2.25,2.25,0,0,0,0,.58l.08,1.87a.3.3,0,1,0,.6,0l0-1.84,0-.51a2.68,2.68,0,0,1,1.41-.51,3.7,3.7,0,0,1,1.46.23.35.35,0,0,1,.12.22,4.34,4.34,0,0,1,0,.72l0,1.68c0,.15-.09.75-.08,1.12a.85.85,0,0,0,.2.57.75.75,0,0,0,.49.21,5,5,0,0,0,1-.08c.45,0,.85-.08,1.25-.08a5.65,5.65,0,0,1,1.19.12c.08,0,.32.09.42.12a1.1,1.1,0,0,1,0,.27c0,.45-.06,1,0,1.34,0,.22.05.5.05.77a1.63,1.63,0,0,1-.05.33s0-.11.05-.08-.42.08-.58.1a7.47,7.47,0,0,1-.86.11c-.45,0-.92,0-1.34,0-.2,0-.54-.07-.8-.06a1.68,1.68,0,0,0-.36.06.65.65,0,0,0-.44.5,2.58,2.58,0,0,0,0,.57l0,1.7c0,.25-.06.57-.1.89-.06.52-.29.5-.55.48l-1.79,0c-.24,0-.33-.28-.41-.54A10.37,10.37,0,0,1,10,15.27c0-.2,0-.53,0-.79a.94.94,0,0,0-.37-.57,1.57,1.57,0,0,0-.49-.19,6.37,6.37,0,0,0-.89-.1c-.34,0-.7-.08-1-.14s-.7-.12-1-.2c-.51-.12-.6.42-.62-1.11a6.43,6.43,0,0,1,0-1,.76.76,0,0,1,.16-.41.41.41,0,0,1,.19-.11c.19,0,.4,0,.58-.09a8.25,8.25,0,0,1,1.25-.11,7.64,7.64,0,0,1,1.25.11A.34.34,0,0,0,9,9.91a9,9,0,0,0-1.36-.14,7.54,7.54,0,0,0-1.37.09,3.81,3.81,0,0,0-.8.13,1.24,1.24,0,0,0-.39.25,1.34,1.34,0,0,0-.35.63,5.44,5.44,0,0,0-.11,1.3,9.83,9.83,0,0,0-.06,1.09.84.84,0,0,0,.2.51.77.77,0,0,0,.33.19c.24.08.61.13.76.17.35.09.72.17,1.09.24s.76.13,1.12.17c.17,0,.43,0,.67.09l.18,0c-.16.11,0,.54,0,.71a8.41,8.41,0,0,0,.39,2.45,1.46,1.46,0,0,0,1.14.93l1.81.07a3,3,0,0,0,.69,0,1,1,0,0,0,.55-.26A1.91,1.91,0,0,0,14,17.9a6.16,6.16,0,0,0,.24-1.47l0-1.73.43,0c.45,0,.94,0,1.43-.06a7.14,7.14,0,0,0,1-.12,3.27,3.27,0,0,0,.88-.2,1,1,0,0,0,.5-.53,1.7,1.7,0,0,0,.12-.57,7,7,0,0,0-.06-1C18.43,11.84,18.51,11.33,18.47,10.89Z"
          fill="#191919"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
